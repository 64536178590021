const relativeStart = (timestamp) => {
  let ts = new Date(timestamp);
  let delta = new Date() - ts;
  let days = Math.floor(delta / 1000 / 60 / 60 / 24);
  delta -= days * 1000 * 60 * 60 * 24;
  let hours = Math.floor(delta / 1000 / 60 / 60);
  if (days > 0) {
    return `${ts.toLocaleDateString("default", {
      month: "long",
    })} ${ts.getDate()}`;
  } else {
    return `${hours} Hours ago`;
  }
};

const toLocalTime = (timestamp) => {
  let date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};
export { relativeStart, toLocalTime };
