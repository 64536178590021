import { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import "./App.css";
import { Header } from "./components/header";
import { SystemMessage } from "./components/system_message";
import { Home } from "./pages/Home";
import { Explore } from "./pages/Explore";
import { Onboarding } from "./components/onboarding";
import SignIn from "./components/sign_in";
import { Admin } from "./pages/Admin";
import { SetupCanvas } from "./pages/SetupCanvas";
import { AuthContext } from "./context/auth";
import { UiContext } from "./context/ui";
import { Archive } from "./pages/Archive";
import { ArchiveCanvas } from "./pages/ArchiveCanvas";
import { Wiki } from "./pages/Wiki";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  const { user } = useContext(AuthContext);
  const { bgColor } = useContext(UiContext);

  return user ? (
    <>
      <GlobalStyle {...{ bgColor }} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        pauseOnHover
      ></ToastContainer>
      <Header />
      <SystemMessage user={user} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/explore" component={Explore} />
        <Route path="/onboarding" component={Onboarding} />
        <Route exact path="/admin" component={Admin} />
        <Route path="/admin/setup" component={SetupCanvas} />
        <Route exact path="/admin/archive" component={Archive} />
        <Route path="/admin/archive/:id" component={ArchiveCanvas} />
        <Route path="/admin/wiki" component={Wiki} />
      </Switch>
    </>
  ) : (
    <SignIn />
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.bgColor};
  }
`;

export default App;
