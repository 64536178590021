import { useState } from "react";
import styled from "styled-components";
import { DeleteButton } from "../components/styled-admin_tools";
import { useTranslation } from "react-i18next";
import { colorGrayMedium, colorGrayWhite } from "../utils/constants";

const Notification = ({ n, deleteFn }) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const weekly = n.frequency === "weekly";

  const managedDelete = async (n) => {
    setDeleting(true);
    try {
      await deleteFn(n);
    } catch (e) {
      console.error(e);
      setDeleting(false);
    }
  };

  return (
    <SingleContainer>
      <NotificationDetails blocked={deleting}>
        <NotificationUsers>
          {n.users === "admins"
            ? t("admin_page.notifications.all_admins")
            : n.users.join(", ")}
        </NotificationUsers>
        <NotificationSchedule>
          {`${t(`admin_page.notifications.${n.frequency}`)}${
            weekly ? ": " : ""
          }`}
          {weekly
            ? n.days.map((d) => t(`weekdays.${d}.plural`)).join(", ")
            : ""}
          {` @${n.time}`}
        </NotificationSchedule>
      </NotificationDetails>
      <DeleteButton
        styled={{ width: "1.5rem", flexShrink: 0 }}
        onClick={() => (deleting ? null : managedDelete(n))}
      />
    </SingleContainer>
  );
};

const ActiveNotifications = ({ notifications, deleteNotification }) => {
  const { t } = useTranslation();

  return (
    <GroupContainer id="ActiveNotifications">
      {notifications.length ? (
        notifications.map((n) => (
          <Notification key={n.id} n={n} deleteFn={deleteNotification} />
        ))
      ) : (
        <strong>{t(`admin_page.notifications.none_active`)}</strong>
      )}
    </GroupContainer>
  );
};

const GroupContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const SingleContainer = styled.li`
  display: flex;
  background: ${colorGrayWhite};
  padding: 1em 0.75em 1em 1.5em;
  border-top: 1px solid ${colorGrayMedium};
  border-right: 1px solid ${colorGrayMedium};
  border-left: 1px solid ${colorGrayMedium};
  align-items: center;

  &:first-of-type {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }
  &:last-of-type {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    border-bottom: 1px solid ${colorGrayMedium};
  }
`;

const NotificationUsers = styled.div`
  min-height: 2.5em;
`;

const NotificationSchedule = styled.div`
  font-weight: 700;
`;

const NotificationDetails = styled.div`
  flex-grow: 1;
  opacity: ${(props) => (props.blocked ? 0.5 : 1)};
`;

export default ActiveNotifications;
