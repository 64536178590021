import React from "react";

const Zoom = ({ type, color }) => {
  if (type === "zoom-in") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="45"
        viewBox="0 0 17 45"
      >
        <text
          id="_"
          data-name="+"
          fill={color}
          fontSize="20"
          fontFamily="NetflixSansLight"
          fontWeight="700"
        >
          <tspan x="0" y="32">
            +
          </tspan>
        </text>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="45"
        viewBox="0 0 17 45"
      >
        <text
          id="_"
          data-name="–"
          fill={color}
          fontSize="20"
          fontFamily="NetflixSansLight"
          fontWeight="700"
        >
          <tspan x="0" y="32">
            –
          </tspan>
        </text>
      </svg>
    );
  }
};

export { Zoom };
