export const mobile = "only screen and (max-width: 780px)";
export const w1000 = "screen and (max-width: 62.5rem)";
export const tablet = "screen and (max-width: 1023px)";
export const smallDesktop = "screen and (max-width: 1280px)";
export const avgLaptop = "screen and (max-width: 1280px)";
export const w1400 = "screen and (max-width: 87.5rem)";
export const desktop = "screen and (max-width: 1920px)";
//export const mobileLandscape =
//"screen and (max-width: 900px) and (orientation: landscape)";
export const smooshedScreen = "screen and (max-height: 800px)";
export const mobileLandscape = "screen and (max-height: 420px)";
