import React from "react";
import { ToolButton } from "./tool_button";
import { ToolDoubleButton } from "./tool_double_button";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  mobile,
  mobileLandscape,
  smallDesktop,
  smooshedScreen,
  tablet,
} from "../styles/media";
import { Button } from "./button";
import Minimap from "./minimap";

const ToolDrawer = ({
  mode,
  view,
  isArtist,
  drawMode,
  openQuickView,
  panMode,
  toggleDebug,
  openScratchpad,
  openTips,
}) => {
  const { t } = useTranslation();
  return (
    <Drawer id="toolDrawer" title="Tool Drawer">
      {isArtist && (
        <FloatLeft>
          <TipsButton
            id="tipsButton"
            callback={openTips}
            bgColor={{ normal: "white", hover: "#616161" }}
            color={{ normal: "#616161", hover: "white" }}
            title="Open Tips"
            className={"hide-on-tablet"}
          >
            ?
          </TipsButton>
        </FloatLeft>
      )}

      <OuterGroup>
        <ToolGroup>
          {isArtist && (
            <ToolButton
              type={"draw"}
              size={62}
              callback={drawMode}
              isSelected={mode === "draw"}
              id="drawButton"
              ariaLabel="Pencil"
              title="Draw"
              className={"hide-on-mobile"}
            ></ToolButton>
          )}
          <TextDoubleButton
            size={62}
            textLeft={t("tools.quick_view")}
            leftId="quickViewBtn"
            textRight={t("scratchpad.heading")}
            rightId="scratchpadBtn"
            callbackLeft={openQuickView}
            callbackRight={openScratchpad}
            ariaLabelLeft="quick view"
            ariaLabelRight="scratchpad"
            titleLeft="Quick View"
            titleRight="Scratchpad"
            className={"hide-on-tablet"}
          ></TextDoubleButton>
          <ToolButton
            type={"pan"}
            size={62}
            callback={panMode}
            isSelected={mode === "pan"}
            id="panButton"
            title="Pan Tool"
            className={"hide-on-mobile"}
          ></ToolButton>
        </ToolGroup>
        <ToolDoubleButton
          id="zoomButtons"
          titleLeft="Zoom In"
          titleRight="Zoom Out"
          size={62}
        ></ToolDoubleButton>
        <Minimap />
      </OuterGroup>
      {/*isArtist && (
        <FloatRight>
          <ToolButton
            type={"text"}
            text={"Debug"}
            size={62}
            callback={toggleDebug}
            className={"hide-on-tablet"}
          ></ToolButton>
        </FloatRight>
      )*/}
    </Drawer>
  );
};

const Drawer = styled.div`
  display: flex;
  bottom: 0;
  width: 100%;
  position: fixed;
  bottom: 4rem;
  margin: 0 auto;
  gap: 8%;
  justify-content: center;
  user-select: none;
  @media ${smallDesktop} {
    gap: 2rem;
  }
  @media ${tablet} {
    gap: 0rem;
    justify-content: flex-start;
    .hide-on-tablet {
      display: none;
    }
    button {
      /*display: none;*/
    }
    #zoomButtons {
    }
  }

  @media ${mobile} {
    .hide-on-mobile {
      display: none;
    }
    bottom: 1rem;
    justify-content: flex-start;
  }

  @media ${smooshedScreen} {
    bottom: 1rem;
  }
  @media ${mobileLandscape} {
    bottom: 1rem;
  }
`;

const FloatLeft = styled.div``;

const TipsButton = styled(Button)`
  width: 62px;
  height: 62px;
  font-family: NetflixSansMedium;
  font-size: 1.25rem;
  border-radius: 50%;
  border: 0px;
  box-shadow: 0px 3px 6px #00000029;
  :hover {
    background-color: #dedede;
    color: #7b7676;
  }
  &.selected {
    background-color: #616161 !important;
    color: white !important;
  }
`;

const TextDoubleButton = styled(ToolDoubleButton)`
  background-color: white;
  button {
    font: normal normal 10px/13px NetflixSansMedium;
    color: #7b7676;
    text-align: center;
    padding: 0 5px;
  }
`;

const OuterGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 3.25rem;
  @media ${smallDesktop} {
    gap: 1.75rem;
  }
  @media ${tablet} {
    gap: 1rem;
  }
`;
const ToolGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  @media ${smallDesktop} {
    gap: 0.75rem;
  }
  @media ${tablet} {
    gap: 0.5rem;
  }
`;

//const FloatRight = styled.div`
//  position: absolute;
//  display: flex;
//  right: 1rem;
//`;

export { ToolDrawer };
