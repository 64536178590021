import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { toast } from "react-toastify";
import { AuthContext } from "../context/auth";
import { Button } from "../components/button";
import { DateField } from "../components/styled-admin_tools";
import { weekSequence, colorGrayLight, onGreen } from "../utils/constants";
import { mobile } from "../styles/media";

const LiveTime = ({ liveSchedule }) => {
  const { t } = useTranslation();
  const weekdayLabels = {
    Sun: t("weekdays.sunday.short"),
    Mon: t("weekdays.monday.short"),
    Tue: t("weekdays.tuesday.short"),
    Wed: t("weekdays.wednesday.short"),
    Thu: t("weekdays.thursday.short"),
    Fri: t("weekdays.friday.short"),
    Sat: t("weekdays.saturday.short"),
  };

  const {
    startTime: liveStartTime,
    endTime: liveEndTime,
    ...liveRest
  } = liveSchedule;
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("18:00");
  const [weekdays, setWeekdays] = useState(
    weekSequence.reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {}) // Init with all false
  );

  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const postLiveTime = async () => {
    try {
      if (!startTime) {
        throw t("admin_page.live_time.start_time_required");
      }
      if (!endTime) {
        throw t("admin_page.live_time.end_time_required");
      }
      if (!weekSequence.filter((d) => weekdays[d]).length) {
        throw t("admin_page.live_time.weekdays_required");
      }
    } catch (e) {
      toast.error(e);
      return;
    }

    setLoading(true);

    const values = { startTime, endTime, ...weekdays };
    const data = { live_schedule: values };

    const idToken = await user.getIdToken();
    try {
      const res = await fetch("/api/system", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": idToken,
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        toast.success("Live Time Updated", {
          toastId: "live-time-toast",
        });
        resetFields();
      } else {
        toast.error("Error updating live times");
      }
    } catch (e) {
      toast.error("Error updating ");
    } finally {
      setLoading(false);
    }
  };

  const resetFields = () => {
    setStartTime("");
    setEndTime("");
    setWeekdays(
      weekSequence.reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {})
    );
  };

  const currentFormatted = `
    ${weekSequence
      .filter((d) => liveRest[d])
      .join(",")} ${liveStartTime} - ${liveEndTime}
  `;

  return (
    <>
      <CurrentlyScheduled>
        {t("admin_page.live_time.currently_scheduled")}{" "}
        <LiveSchedule>{currentFormatted}</LiveSchedule>
      </CurrentlyScheduled>
      <LiveTimeDateField
        type="time"
        id="startTime"
        className="liveTime"
        value={startTime}
        disabled={loading}
        onChange={(e) => setStartTime(e.target.value)}
      />
      <LiveTimeDateField
        type="time"
        id="endTime"
        className="liveTime"
        value={endTime}
        disabled={loading}
        onChange={(e) => setEndTime(e.target.value)}
      />
      <CheckGroup>
        {weekSequence.map((v) => {
          return (
            <Weekday key={v}>
              <CheckInput
                type="checkbox"
                key={v}
                id={v}
                value={v}
                checked={weekdays[v]}
                className="liveTime"
                disabled={loading}
                onChange={(e) =>
                  setWeekdays((current) => ({
                    ...current,
                    [v]: e.target.checked,
                  }))
                }
              />
              <label htmlFor={v}>{weekdayLabels[v]}</label>
            </Weekday>
          );
        })}
      </CheckGroup>
      <ScheduleButton
        text={t("admin_page.live_time.schedule")}
        callback={postLiveTime}
      />
      <ResetButton
        callback={resetFields}
        bgColor={{ normal: colorGrayLight, hover: "#707070" }}
        color={{ normal: "white", hover: "white" }}
      >
        {t("reset")}
      </ResetButton>
    </>
  );
};

const LiveTimeDateField = styled(DateField)`
  margin-right: 1rem;
  input {
    min-width: 11rem;
    margin: 0;
  }
  @media ${mobile} {
    margin-right: 0.3rem;
  }
`;

const CheckGroup = styled.div`
  flex-flow: row wrap;
  clear: both;
  display: flex;
  padding: 1rem 0 0;
  label {
    vertical-align: middle;
    user-select: none;
    padding: 0 0.5rem 0 0;
  }
`;

const CheckInput = styled.input``;

const Weekday = styled.li`
  display: flex;
  align-items: center;
`;

const CurrentlyScheduled = styled.p`
  margin-bottom: 1rem;
`;

const LiveSchedule = styled.span`
  color: ${onGreen};
`;

const ScheduleButton = styled(Button)`
  margin-top: 1rem;
  width: 14em;
`;

const ResetButton = styled(Button)`
  border-color: ${colorGrayLight};
  :hover {
    border-color: #707070;
  }
  margin-left: 1rem;
  width: 14em;
`;

export default LiveTime;
