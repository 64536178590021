/**
 * Helper function to get the active canvas document.
 * @param {object} user - User object requried for authentication
 * @return {object} Canvas document object
 */
const activeCanvas = async (user) => {
  try {
    if (user === null || user === undefined) {
      throw new Error("User object required");
    }
    const idToken = await user.getIdToken();
    const res = await fetch(`/api/canvas?active=true`, {
      method: "GET",
      headers: {
        "X-Access-Token": idToken,
      },
    });
    const data = await res.json();
    return data.items[0];
  } catch (e) {
    return null;
  }
};

export { activeCanvas };
