import { forwardRef } from "react"
import styled from "styled-components"
import { relativeStart } from "../utils/timestamps";
import { Calendar } from "../icons/calendar";

const AdminCanvas = forwardRef(({canvasDoc}, ref) => {
  return (
    <Container>
      <CanvasLabel>
        <h2>{canvasDoc.title}</h2>
        <p>{`${canvasDoc.description.slice(0, 140)}${
          canvasDoc.description.length > 140 ? "..." : ""
        }`}</p>
      </CanvasLabel>
      <canvas ref={ref} id="adminCanvas"></canvas>
      <CanvasTimestamp>
        <Calendar size={"0.8rem"} />
        <StartDate>Started on: {relativeStart(canvasDoc.timestamp)}</StartDate>
      </CanvasTimestamp>
    </Container>
  );
})

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
`

const CanvasLabel = styled.div`
  margin-bottom: 1rem;

  * { display: inline-block; margin: 0; }
  h2 { font-size: 1rem; }
  p { font-size: 0.75rem; margin-left: 0.5rem; }
`

const CanvasTimestamp = styled.div`
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  align-self: flex-end;
  margin-top: 0.5rem;
`

const StartDate = styled.span`
  display: inline-block;
  margin-left: 0.25rem;
`

export default AdminCanvas