import React from "react";
import styled from "styled-components";
import { colorGrayLight } from "../utils/constants";

const Pill = ({ callback, children }) => {
  return (
    <Container data-user={children}>
      {children}
      <Close onClick={callback}>
        <span>x</span>
      </Close>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 1rem;
  background-color: ${colorGrayLight};
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Close = styled.button`
  float: right;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  right: 0.5rem;
  cursor: pointer;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorGrayLight};

  :hover {
    font-weight: bold;
  }

  span {
    box-sizing: border-box;
    color: black;
    pointer-events: none;
  }
`;

export { Pill };
