export const fullWidth = 2048; //2133; //2048
export const height = 376; //400; //384;
export const maxWidth = 800;
export const zoomFactor = 100;
export const throttleValue = 100;

export const colorGrayWhite = "#f7f7f7";
export const colorGrayLight = "#dedede";
export const colorGrayMedium = "#cccccc";
export const colorGrayDark = "#9f9f9f";
export const colorWhite = "white";
export const netflixRed = "#E40914";
export const onGreen = "#408B00";
export const offWhite = "#FAFAFA";
export const netflixBlack = "#141414";

export const weekSequence = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const weekdaySequence = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const canvasTitleMaxLength = 50;
export const canvasDescriptionPreviewLength = 400;