import React, { useState, useEffect, useCallback, useContext } from "react";
import styled from "styled-components";
import api from "../utils/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "../components/button";
import { toLocalTime } from "../utils/timestamps";
import { Link } from "react-router-dom";
import { mobile, smallDesktop, tablet } from "../styles/media";
import { NiceInput } from "../components/input";
import { useLocation } from "react-router";
import { colorGrayLight } from "../utils/constants";
import { Calendar } from "../icons/calendar";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth";

const Archive = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [hyperlinks, sethyperlinks] = useState({});
  const [items, setItems] = useState([]);
  const [titleSearch, setTitleSearch] = useState(params.get("title") || "");
  const [userSearch, setUserSearch] = useState(params.get("user") || "");
  const [startDate, setStartDate] = useState(params.get("start_time") || "");
  const [endDate, setEndDate] = useState(params.get("end_time") || "");
  const [hasMore, setHasMore] = useState(true);
  const perPage = 20;
  const defaultQuery = `active=false&per_page=${perPage}`;
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const searchDisabledMessage = t("archive_page.search_disabled_message");
  const titleSearchMessage = t("archive_page.title_search_placeholder");
  const userSearchMessage = t("archive_page.user_search_placeholder");
  const [titleSearchDisabled, setTitleSearchDisabled] = useState(false);
  const [titleSearchPlaceholder, setTitleSearchPlaceholder] =
    useState(titleSearchMessage);
  const [userSearchDisabled, setUserSearchDisabled] = useState(false);
  const [userSearchPlaceholder, setUserSearchPlaceholder] =
    useState(userSearchMessage);

  const load = async () => {
    parseUrlParams(params);
    searchCanvases(defaultQuery);
    document.addEventListener("keypress", enterSearch);
  };

  const enterSearch = (key) => {
    if (key.code === "Enter") {
      searchCanvases(makeSearchQuery());
    }
  };

  const parseUrlParams = (params) => {
    if (params.get("title")) {
      setTitleSearch(params.get("title"));
    }
    if (params.get("users")) {
      setUserSearch(params.get("user"));
    }
    if (params.get("start_time")) {
      setStartDate();
    }
    if (params.get("end_time")) {
      setEndDate();
    }
  };

  const updateApiData = useCallback(
    (json, concat = false) => {
      sethyperlinks(json._links);
      setItems(concat ? items.concat(json.items) : json.items);
      if (json.items.length < perPage) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    },
    [items]
  );

  const makeSearchQuery = useCallback(() => {
    const q = new URLSearchParams();
    if (titleSearch) {
      q.append("title", titleSearch.toLowerCase());
    }
    if (userSearch) {
      q.append("users", userSearch);
    }
    if (startDate) {
      q.append("start_time", startDate);
    }
    if (endDate) {
      q.append("end_time", endDate);
    }
    return q.toString() || defaultQuery;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleSearch, userSearch, startDate, endDate]);

  const searchCanvases = useCallback(
    async (query = defaultQuery) => {
      let url = `/api/canvas?${query}`;

      const tokenId = await user.getIdToken();
      const [err, res] = await api(url, {
        method: "GET",
        headers: {
          "X-Access-Token": tokenId,
        },
      });
      if (err !== null) {
        toast.error(`${t("archive_page.search_error")}`);
        return;
      }
      const json = await res.json();
      window.history.pushState(
        "",
        "Archive",
        window.location.href.split("?")[0] + "?" + url.split("?")[1]
      );
      updateApiData(json);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const clearInputs = useCallback(() => {
    setUserSearch("");
    setTitleSearch("");
    setTitleSearchDisabled(false);
    setUserSearchDisabled(false);
    setTitleSearchPlaceholder(titleSearchMessage);
    setUserSearchPlaceholder(userSearchMessage);

    setStartDate("");
    setEndDate("");
    searchCanvases(defaultQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCanvases, t]);

  const getMoreCanvases = useCallback(async () => {
    const tokenId = await user.getIdToken();
    const [err, res] = await api(hyperlinks.next, {
      headers: {
        "X-Access-Token": tokenId,
      },
    });
    if (err !== null) {
      toast.error(`${t("archive_page.loading_canvases_error")}`);
    }
    const json = await res.json();
    updateApiData(json, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hyperlinks, updateApiData, user]);

  useEffect(() => {
    load();
    return () => {
      document.removeEventListener("keypress", enterSearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (titleSearch !== "") {
      setUserSearchDisabled(true);
      setUserSearchPlaceholder(searchDisabledMessage);
      setUserSearch("");
    } else {
      setUserSearchDisabled(false);
      setUserSearchPlaceholder(userSearchMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleSearch]);

  useEffect(() => {
    if (userSearch !== "") {
      setTitleSearchDisabled(true);
      setTitleSearchPlaceholder(searchDisabledMessage);
      setTitleSearch("");
    } else {
      setTitleSearchDisabled(false);
      setTitleSearchPlaceholder(titleSearchMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearch]);

  return (
    <Container>
      <InnerContainer>
        <Title>{t("archive_page.page_title")}</Title>
        <SearchContainer>
          <SearchTitle>{t("archive_page.title_search_label")}</SearchTitle>
          <SearchInput
            id="titleSearchInput"
            type="text"
            placeholder={titleSearchPlaceholder}
            disabled={titleSearchDisabled}
            value={titleSearch}
            onChange={(e) => setTitleSearch(e.target.value)}
          />
          <SearchTitle>{t("archive_page.user_search_label")}</SearchTitle>
          <SearchInput
            id="userSearch"
            type="text"
            placeholder={userSearchPlaceholder}
            disabled={userSearchDisabled}
            value={userSearch}
            onChange={(e) => setUserSearch(e.target.value)}
          />
          <SearchTitle>{t("archive_page.date_range_label")}</SearchTitle>
          <Row>
            <Group>
              <DateInput
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
                type="date"
                id="startDate"
              />
              <DateInput
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                type="date"
                id="endDate"
              />
            </Group>
            <Group>
              <ClearButton
                id="clear"
                callback={clearInputs}
                text={t("archive_page.clear_all")}
                bgColor={{ normal: colorGrayLight, hover: "#707070" }}
                color={{ normal: "white", hover: "white" }}
              ></ClearButton>
              <SearchButton
                callback={() => searchCanvases(makeSearchQuery())}
                id="search"
                text={t("archive_page.apply")}
              />
            </Group>
          </Row>
        </SearchContainer>
        <Gallery>
          <InfiniteScroll
            dataLength={items.length}
            next={getMoreCanvases}
            hasMore={hasMore}
            style={{ overflow: "visible" }}
            loader={<h4>{t("archive_page.loading")}...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                {t("archive_page.no_more_canvases")}
              </p>
            }
          >
            {items.map((item, idx) => {
              return (
                !item.active && (
                  <Thumb key={item.id}>
                    <Description>
                      <ThumbTitle>
                        {item.title === ""
                          ? t("archive_page.untitled")
                          : item.title}
                      </ThumbTitle>
                    </Description>
                    <Link
                      to={{
                        pathname: `/admin/archive/${item.id}`,
                        canvas: { ...item },
                      }}
                    >
                      {item.image ? (
                        <img
                          alt={`Canvas ${item.id}`}
                          id={item.id}
                          src={item.image}
                        />
                      ) : (
                        <EmptyCanvasPlaceholder
                          data-message={t("archive_page.no_data")}
                        />
                      )}
                    </Link>
                    <Timestamp id="timestamp">
                      <Calendar size="0.75rem"></Calendar>
                      {toLocalTime(item.timestamp)}
                    </Timestamp>
                  </Thumb>
                )
              );
            })}
          </InfiniteScroll>
          {hasMore && (
            <Button callback={getMoreCanvases}>
              {t("archive_page.load_more_canvases")}
            </Button>
          )}
        </Gallery>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  @media ${mobile} {
    padding-top: 2rem;
  }
`;

const InnerContainer = styled.div`
  max-width: 1586px;
  margin: 2rem auto 0;
  padding: 0 4.5rem;
  @media ${smallDesktop} {
    padding: 0 2.5rem;
  }

  @media ${tablet} {
    padding: 0 1.5rem;
  }
`;

const Title = styled.h2`
  clear: both;
  margin: 0 4rem;
  padding: 1rem 0 1.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: NetflixSansBold;

  @media ${mobile} {
    margin: 0;
  }
`;

const SearchContainer = styled.div`
  padding: 1.25rem 4rem 2.75rem;
  margin-bottom: 2rem;
  max-width: 973px;
  background-color: white;
  border-radius: 0.413rem;
  box-shadow: 0px 3px 6px #00000029;
  box-sizing: border-box;
  input:disabled {
    background-color: ${colorGrayLight};
  }

  @media ${mobile} {
    padding: 1rem 1.5rem 2rem;
  }
`;

const SearchTitle = styled(Title)`
  margin: 0;
  padding: 1rem 0;
`;

const SearchInput = styled(NiceInput)`
  float: left;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  @media ${tablet} {
    display: block;
  }
`;

const DateInput = styled(NiceInput)`
  float: left;
  max-width: 12rem;
  margin-right: 2rem;
  @media ${tablet} {
    width: 50%;
    max-width: unset;
    &:nth-of-type(2) {
      margin-right: 0;
    }
  }
`;

const SearchButton = styled(Button)`
  border-radius: 0;
  box-shadow: unset;
  width: 12rem;
  margin-right: 1rem;
  height: 2rem;
  &:first-of-type {
    margin-left: 1rem;
  }

  @media ${tablet} {
    margin-left: 0 !important;
  }
`;

const ClearButton = styled(SearchButton)`
  border: none;
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: auto;
  clear: both;
`;

const Description = styled.div`
  margin: 0;
  padding: 0;
`;

const Thumb = styled.div`
  max-width: 100vw;
  padding: 0.25rem 0rem 2rem;
  position: relative;

  img {
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
  }
`;

const ThumbTitle = styled(Title)`
  padding-top: 1rem;
`;

const Timestamp = styled.div`
  float: right;
  font-family: "Nunito Sans", sans-serif;
  margin: 0rem 0;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  bottom: 0rem;
  right: 0rem;
  position: absolute;
  svg {
    margin-right: 0.2rem;
  }

  @media ${mobile} {
    font-size: 0.75rem;
    margin: 0.75rem 0;
    &.active {
      margin: 0.2rem 4rem !important;
      right: 2rem;
    }
  }
`;

const Group = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  @media ${tablet} {
    &:nth-of-type(2) {
      margin-top: 1.5rem;
    }
  }
`;

const EmptyCanvasPlaceholder = styled.div`
  width: 100%;
  height: 0px;
  padding-bottom: 18.77%;
  background: #0000010;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  &::after {
    content: attr(data-message);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
  }
`;

export { Archive };
