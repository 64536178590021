import api from "./api";
import { toast } from "react-toastify";

/** 
* Helper function to archive a canvas
* @summary This function provides a means to re-archive a canvas in case the archived image is corrupted or fails to generate.
* @param {string} userToken - Auth user token for api call 
* @param {string} id - Document ID for canvas to be archived 
* @param {?string} successMsg - Message to be displayed on successful archiving. 
*/

const archiveCanvas = async (userToken, id, successMsg) => {
  if (userToken === null || userToken === undefined) {
    throw new Error("User Token Required");
  } else if (id === null || id === undefined) {
    throw new Error("Canvas ID is Required");
  }
  let toastId = toast("Archiving In Progress, please stand by...", {
    autoClose: false,
  });
  const [errors, response] = await api(`/api/canvas/${id}/archive`, {
    method: "POST",
    headers: {
      "X-Access-Token": userToken,
    },
  });
  if (errors !== null) {
    toast.update(toastId, {
      type: toast.TYPE.ERROR,
      render: "Error while archiving",
    });
  } else if (response.status === 200) {
    toast.update(toastId, {
      type: toast.TYPE.SUCCESS,
      render: successMsg ? successMsg : "Archive Complete",
      autoClose: true,
    });
  }
};

export { archiveCanvas };
