import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

//import { config } from "./config";

const firebaseConfig = {
  apiKey: "AIzaSyAtybwOrKUDM4J4_4MrEJ5Y1XSIkfNCl80",
  authDomain: "animation-vt-drawn-together.firebaseapp.com",
  databaseURL:
    "https://animation-vt-drawn-together-default-rtdb.firebaseio.com",
  projectId: "animation-vt-drawn-together",
  storageBucket: "animation-vt-drawn-together.appspot.com",
  messagingSenderId: "792789554090",
  appId: "1:792789554090:web:9bc2c1ab87e0acdbeed1d3",
  measurementId: "G-7480EEGKKG",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// firebase.database.enableLogging(true);
const db = firebase.database();
const firestoreDb = firebase.firestore();
const storage = firebase.storage();

if (process.env.REACT_APP_TEST_DB) {
  db.useEmulator("localhost", 9000);
  firestoreDb.useEmulator("localhost", 9000);
}

export { app, db, firestoreDb, storage };
