import { useState, useContext } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { AuthContext } from "../context/auth";
import api from "../utils/api";
import AddItemsWidget from "./add_items_widget";
import { useTranslation } from "react-i18next";
import { Button } from "./button";
import { ResetButton } from "./styled-admin_tools";
import { colorGrayLight } from "../utils/constants";

const AddAdmin = ({ admins, hydrateAdmins }) => {
  const { t } = useTranslation();
  const [addAdminsValue, setAddAdminsValue] = useState("");
  const [tempAdminsList, setTempAdminsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  const onClickAdd = (val) => {
    if (!val) {
      toast.warn(`${t("admin_page.admin_panel.no_emails")}`);
      return false;
    }
    const emails = val.replaceAll(" ", "").split(",");
    const existing = admins
      .map((a) => a.email.toLowerCase())
      .concat(tempAdminsList.map((e) => e.toLowerCase()));
    const badEmails = [];
    const valids = emails.filter((e) => {
      if (!/^[^@]+@\w+(\.\w+)+\w$/.test(e)) {
        toast.warn(`${e} - ${t("add_user_list.invalid_email")}`);
        badEmails.push(e);
        return false;
      } else if (existing.find((ex) => ex === e)) {
        toast.warn(`${e} - ${t("add_user_list.duplicate")}`);
        badEmails.push(e);
        return false;
      } else {
        return true;
      }
    });
    setTempAdminsList((current) => current.concat(valids));
    setAddAdminsValue(badEmails.join(", "));
  };

  const managedCreate = async () => {
    if (!tempAdminsList.length) {
      return toast.warn(t(`admin_page.admin_panel.no_emails`));
    }

    setLoading(true);
    const idToken = await user.getIdToken();
    const fails = [];
    try {
      await Promise.all(
        tempAdminsList.map(
          (a) =>
            new Promise(async (resolve, reject) => {
              const [err, response] = await api("/api/admin", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "X-Access-Token": idToken,
                },
                body: JSON.stringify({ email: a }),
              });
              if (err !== null) {
                fails.push(a);
                reject(
                  `${t(`admin_page.admin_panel.error_creating_admin`)} ${
                    a.email
                  }`
                );
              } else {
                resolve(response);
              }
            })
        )
      );
      resetFields();
    } catch (e) {
      console.error(e);
      toast(e.message);
      setTempAdminsList((current) =>
        current.filter((a) => fails.find((f) => f.email === a.email))
      );
    } finally {
      await hydrateAdmins();
      setLoading(false);
    }
  };

  const resetFields = () => {
    setAddAdminsValue("");
    setTempAdminsList([]);
  };

  return (
    <>
      <Container>
        <Title>{t(`admin_page.admin_panel.add_admin`)}</Title>
        <WidgetContainer disabled={loading}>
          <AddItemsWidget
            list={tempAdminsList}
            value={addAdminsValue}
            setValue={setAddAdminsValue}
            onClickAdd={() => onClickAdd(addAdminsValue)}
            onClickDelete={(user) =>
              setTempAdminsList((current) => current.filter((u) => u !== user))
            }
            placeholder={t("add_user_list.placeholder")}
          />
          <CreateButton
            text={t("admin_page.admin_panel.create_admin")}
            callback={managedCreate}
          />
          <ResetButton
            callback={resetFields}
            bgColor={{ normal: colorGrayLight, hover: "#707070" }}
            color={{ normal: "white", hover: "white" }}
          >
            {t("reset")}
          </ResetButton>
        </WidgetContainer>
      </Container>
    </>
  );
};

const Container = styled.div``;

const WidgetContainer = styled.div`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const Title = styled.h4`
  margin: 1.125rem 0;
  font-family: NetflixSansBold;
  text-transform: uppercase;
`;

const CreateButton = styled(Button)`
  margin-top: 1rem;
  width: 14em;
`;

export default AddAdmin;
