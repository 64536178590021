import api from "./api";

/** 
* Helper function that checks if a user exists, if not it creates one
* @param {string} user - User object from Auth 
* @return {object} User document 
*/

const checkUser = async (user) => {
  let userDoc, idToken;
  try {
    idToken = await user.getIdToken();
  } catch (e) {
    console.error("User object is malformed", e);
    return false;
  }
  try {
    const [err, response] = await api(`/api/user/${user.uid}`, {
      method: "GET",
      headers: {
        "X-Access-Token": idToken,
      },
    });
    if (err) {
      let data = {
        email: user.email,
        id: user.uid,
      };
      const [createErr, createResponse] = await api(`/api/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": idToken,
        },
        body: JSON.stringify(data),
      });
      if (createErr === null) {
        userDoc = await createResponse.json();
      } else {
        throw new Error("Failed to create User");
      }
    } else {
      userDoc = await response.json();
    }
  } catch (e) {
    console.error("Failed to Get or Create User");
    return false;
  }
  return userDoc;
};

export { checkUser };
