import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ToggleButton } from "./toggle_button";
import { ControlTitle } from "../components/styled-admin_tools";
import { AuthContext } from "../context/auth";

const SystemControls = ({ systemDoc }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [systemOn, setSystemOn] = useState(null);

  const toggleSystemOn = async (value) => {
    // Api call to toggle system
    if (value === systemDoc.on) return;
    let data = {
      on: value,
    };
    const idToken = await user.getIdToken();
    try {
      const res = await fetch("/api/system", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": idToken,
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        toast.success(t("admin_page.system_toggle_success"));
      } else {
        toast.error(t("admin_page.system_toggle_server_error"));
      }
    } catch (e) {
      toast.error(t("admin_page.system_toggle_error"));
    }
  };

  useEffect(() => {
    if (systemDoc) {
      setSystemOn(systemDoc.on);
    }
  }, [systemDoc]);

  return (
    <>
      <ControlTitle>{t("admin_page.main_switch")}</ControlTitle>
      <ToggleButton
        inState={systemOn}
        idOff={"systemOff"}
        idOn={"systemOn"}
        callbackOff={toggleSystemOn}
        callbackOn={toggleSystemOn}
      ></ToggleButton>
    </>
  );
};

export default SystemControls;
