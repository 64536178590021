import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import spinner from "../imgs/site-spinner.png";

const Loading = ({ cover = true }) => {
  const spinTransition = {
    repeat: Infinity,
    duration: 1,
    ease: "linear",
  };
  return (
    <Container cover={cover}>
      <Circle animate={{ rotate: 360 }} transition={spinTransition}>
        <img src={spinner} alt="Loading" />
      </Circle>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  background-color: ${(props) =>
    props.cover === true ? "white" : "transparent"};
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: none;
`;

const Circle = styled(motion.span)`
  display: block;
  width: 3rem;
  height: 3rem;
  /*margin: 50vh auto;*/

  img {
    width: 100%;
  }
`;

export { Loading };
