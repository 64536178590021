import { fullWidth, height } from "./constants";

const hydratePreviews = (canvas, elements) => {
  return new Promise((resolve, reject) => {
    try {
      let vpt = canvas.viewportTransform;
      canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
      const data = canvas.toDataURL({
        width: fullWidth,
        height: height,
        multiplier: 1,
        left: 0,
        top: 0,
        format: "jpg",
      });
      canvas.viewportTransform = vpt;
      elements.forEach((el) => {
        let img = document.querySelector(`${el} img`);
        if (img !== null) {
          img.src = data;
        }
      });
      resolve(data);
    } catch (e) {
      console.log("error rendering minimap");
      reject(e);
    }
  });
};

export { hydratePreviews };
