import { stripPunctuation } from "./strip_punctuation";
import matchEmojis from "./match_emojis";

/**
 * Helper function that generates two values to save in the database to assist in canvas searching by title.
 * It generates a lowercase string version of the passed in value, as well as an Array of search terms, stripped of punctuation.
 * This allows us to use "array-contains" query in firebase since fuzzy search does not exist natively.
 * @summary Helper function to Generate Sanitized Search string and terms for database
 * @param {string} source - String to be converted into search data.
 * @returns Search title and terms
 */

const generateSearchData = (source) => {
  if (source === null || source === undefined) {
    throw new Error("Must provide search string");
  } else if (typeof source !== "string") {
    throw new Error("Input must be a string");
  }
  const map = new Map();
  const title = source.toLowerCase();
  const split = title.split(" ");
  let terms = [];
  for (let i = 0; i < split.length; i++) {
    let t = stripPunctuation(split[i]);
    if (!t || map.get(t)) {
      continue;
    } else {
      map.set(t, true);
      terms.push(t);
    }
  }
  terms = terms.concat(matchEmojis(title));
  return { title, terms };
};

export { generateSearchData };
