import React, { useRef, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const minTextAreaHeight = "1.6rem";

const EditableInput = ({
  value,
  callback,
  id,
  docKey,
  autogrow = false,
  maxLength,
  type,
}) => {
  const growRef = useRef();
  const growTmtRef = useRef();
  const [inputValue, setValue] = useState(value);
  const [disabled, setDisabled] = useState(true);
  const [height, setHeight] = useState(minTextAreaHeight);

  const onChange = useCallback(
    (e) => {
      if (typeof maxLength === "number" && e.target.value.length > maxLength) {
        return;
      }
      setValue(e.target.value);
    },
    [maxLength]
  );

  const onClick = useCallback((e) => {
    setDisabled(false);
  }, []);

  const onBlur = useCallback(() => {
    setDisabled(true);
    if (callback) {
      callback(docKey, inputValue);
    }
  }, [inputValue, callback, docKey]);

  useEffect(() => {
    if (disabled === false) {
      document
        .querySelector(`#${id} ${type === "text" ? "input" : "textarea"}`)
        ?.focus();
    }
  }, [disabled, id, type]);

  useEffect(() => {
    if (autogrow) {
      growTmtRef.current = setTimeout(
        () => setHeight(`${growRef.current.scrollHeight}px`),
        0
      );
    }
  }, [autogrow, inputValue]);

  useEffect(() => {
    return () => {
      clearTimeout(growTmtRef.current);
    };
  }, []);

  return (
    <Container
      id={id}
      onClick={onClick}
      style={{ position: autogrow ? "relative" : "initial" }}
    >
      {type === "text" ? (
        <InlineInput
          value={inputValue}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
        ></InlineInput>
      ) : (
        <InlineTextArea
          value={inputValue}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          style={{ height }}
        ></InlineTextArea>
      )}
      {autogrow ? <Autogrower ref={growRef}>{inputValue}</Autogrower> : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0.25rem 0 0.25rem 0.5rem;
  padding: 0;
  flex-grow: 1;
`;

const Inline = `
  padding: 0 0.25rem !important;
  border: 1px solid;
  outline: none;
  font-size: 1rem;
  font-family: NetflixSansLight;
  border-radius: 0.25rem;
  background-color: #fafafa;
  border-color: #dadada;
  flex-grow: 2;
  line-height: 1.5rem;
  width: 100%;
  :disabled {
    background-color: #eaeaea;
    border-color: #bababa;
  }
  :hover {
    background-color: #fafafa;
    cursor: text;
  }
  :active{

    border-color: #dadada;
  }
`;
const InlineInput = styled.input`
  ${Inline}
`;

const InlineTextArea = styled.textarea`
  ${Inline}
  max-height: 12.5rem;
`;

const Autogrower = styled.div`
  ${Inline}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  box-sizing: border-box;
  min-height: ${minTextAreaHeight};
`;

export { EditableInput };
