import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  mobile,
  smallDesktop,
  tablet,
  avgLaptop,
  w1000,
} from "../styles/media";
import { netflixRed } from "../utils/constants";
import { Button } from "./button";
import { Link } from "react-router-dom";

const DescriptionBlock = ({ descriptionLead, description }) => {
  const { t } = useTranslation();
  const learnMore = useCallback(() => {
    //document.getElementById("learnMoreCarat").style.transform = "rotate(90deg)";
    document.getElementById("learnMoreButton").classList.toggle("active");
    let faq = document.getElementById("faqContainer");
    if (!faq.classList.contains("show")) {
      faq.classList.toggle("show");
      document
        .getElementById("faq")
        .scrollIntoView({ block: "end", behavior: "smooth" });
    } else {
      faq.style.display = "hidden";
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        faq.style.display = "";
        faq.classList.toggle("show");
      }, 500);
    }
  }, []);

  return (
    <Block>
      <LeadBlock>
        <Lead>{descriptionLead ? descriptionLead : t("description_lead")}</Lead>
      </LeadBlock>
      <HighlightBlock>
        <p>{description ? description : t("description")}</p>
      </HighlightBlock>
      <CallToAction>
        <p>{t("call_to_action")}</p>
      </CallToAction>
      <ButtonBlock>
        <CTAButton
          color={{ normal: netflixRed, hover: "white" }}
          bgColor={{ normal: "white", hover: netflixRed }}
          animated={true}
          onClick={(e) => {
            e.target.querySelector("a")?.click();
          }}
        >
          <Link to="/explore">
            {t("explore_and_sketch")} <ButtonCarat>›</ButtonCarat>
          </Link>
        </CTAButton>
        <CTAButton
          color={{ normal: netflixRed, hover: "white" }}
          bgColor={{ normal: "white", hover: netflixRed }}
          callback={learnMore}
          animated={true}
          id={"learnMoreButton"}
          title="Learn More"
        >
          {t("home.learn_more")}{" "}
          <ButtonCarat id="learnMoreCarat">›</ButtonCarat>
        </CTAButton>
      </ButtonBlock>
    </Block>
  );
};

const Block = styled.div`
  margin: 0 auto 0 0;
  text-align: left;
  font-size: 1.25rem;
  line-height: 1.75rem;
  max-width: 50rem;
  float: left;
  padding: 0 0.5rem 0 5rem;
  position: relative;

  @media ${avgLaptop} {
    padding: 0 0.5rem 0 2rem;
  }

  @media ${w1000} {
    font-size: 1rem;
    line-height: 1.5rem;
    float: none;
    margin: 0 auto 2rem;
  }
  p {
    margin: 0;
  }
`;

const LeadBlock = styled.div``;

const Lead = styled.h1`
  font-family: NetflixSansBold;
  font-size: 3.9rem;
  line-height: 0.875em;
  margin: 0;
  white-space: nowrap;
  &:first-of-type {
    margin-right: 1rem;
  }
  @media ${avgLaptop} {
    font-size: 3.5rem;
  }
  @media ${tablet} {
    font-size: 3rem;
  }
  @media ${mobile} {
    font-size: 2.25rem;
  }
`;

const HighlightBlock = styled.div`
  font-family: NetflixSansLight;
  font-size: 1.5rem;
  clear: both;
  padding: 2rem 1rem 0 0;
  text-transform: uppercase;
  width: 30rem;
  p {
    border-left: 2px solid ${netflixRed};
    padding-left: 1rem;
    margin-left: -1rem;
    max-width: 30rem;
  }
  @media ${avgLaptop} {
    padding: 2rem 1rem 0 0;
    font-size: 1.1rem;
    line-height: 1.25em;
    width: 25rem;
  }
  @media ${w1000} {
    width: 100%;
    p {
      max-width: 40rem;
    }
  }
  @media ${mobile} {
    font-size: 1.25rem;
    padding-top: 1rem;
    width: 100%;
  }
`;

const CallToAction = styled.div`
  font-family: NetflixSansLight;
  font-size: 1rem;
  padding: 2rem 0;
  width: 32.5rem;
  line-height: 1.25em;
  @media ${avgLaptop} {
    padding: 1.75rem 0;
    font-size: 1rem;
  }
  @media ${smallDesktop} {
    width: 25rem;
  }
  @media ${w1000} {
    width: 100%;
  }
`;

const ButtonBlock = styled.div`
  width: 65%;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  @media ${smallDesktop} {
    width: 80%;
  }
  @media ${mobile} {
    width: 100%;
    justify-content: space-around;
  }
`;

const CTAButton = styled(Button)`
  min-width: 11rem;
  font-size: 0.6rem;
  a {
    text-decoration: none;
    color: inherit;
  }

  &.active {
    background-color: ${netflixRed};
    color: white;
  }
`;

const ButtonCarat = styled.span`
  margin-left: 0.5rem;
  font-family: NetflixSansBold;
  font-size: 0.9rem;
  line-height: 0;
  display: inline-block;
  transition: transform 0.25s;
  transform-origin: 50% 50%;
  width: 8px;
  height: 5px;

  .active & {
    transform: rotate(90deg);
  }
`;

export { DescriptionBlock };
