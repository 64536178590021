import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { AuthContext } from "./auth";
import { systemDocListener } from "../utils/rtdbListeners";
import api from "../utils/api";
import { toast } from "react-toastify";

const SystemContext = React.createContext();

const SystemProvider = ({ children }) => {
  const [systemDisplay, setSystemDisplay] = useState(false);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [confirmationRequired, setConfirmationRequired] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const { user, isAdmin } = useContext(AuthContext);
  const systemListener = useRef(null);

  const setData = useCallback((data) => {
    if (data === null || data === undefined) {
      setSystemDisplay(false);
      setStatus(null);
      setMessage(null);
      setConfirmationRequired(null);
      setConfirmationMessage(null);
    } else {
      setSystemDisplay(true);
      setStatus(data.system_message.status);
      setMessage(data.system_message.msg);
      setConfirmationRequired(data.system_message.confirmation_required);
      setConfirmationMessage(data.system_message.confirmation_msg);
    }
  }, []);

  const systemCallback = useCallback(
    (data) => {
      if (data.system_message !== undefined && data.system_message !== null) {
        setData(data);
      } else {
        setData(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [systemDisplay]
  );

  const load = async () => {
    const idToken = await user.getIdToken();
    const [err, response] = await api("/api/system", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": idToken,
      },
    });
    if (err !== null) {
      toast.error("Error loading system information");
    }
    if (response?.status === 200) {
      const json = await response.json();
      if (json.system_message) {
        setData(json);
      }
    }
  };

  useEffect(() => {
    if (isAdmin) {
      systemListener.current = systemDocListener(systemCallback);
      load();
    }
    return () => {
      if (isAdmin && systemListener.current) {
        systemListener.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, systemCallback]);

  return (
    <SystemContext.Provider
      value={{
        systemDisplay,
        status,
        message,
        confirmationRequired,
        confirmationMessage,
      }}
    >
      {children}
    </SystemContext.Provider>
  );
};

export { SystemContext, SystemProvider };
