/**
 * Helper function that forces canvas viewport to remain within bounds of canvas
 * @param {Object} canvas - FabricJS Canvas object
 * @param {int} width - Max width of Canvas
 * @param {int} height - Max height of Canvas
 * @param {float} zoom - Current zoom factor of canvas
 */

const correctViewport = (canvas, width, height, zoom) => {
  let vpt = canvas.viewportTransform;
  if (vpt[4] >= 0) {
    vpt[4] = 0;
  } else if (vpt[4] < canvas.getWidth() - width * zoom) {
    vpt[4] = canvas.getWidth() - width * zoom;
  }
  if (vpt[5] > 0) {
    vpt[5] = 0;
  } else if (vpt[5] < canvas.getHeight() - height * zoom) {
    vpt[5] = canvas.getHeight() - height * zoom;
  }
};

export { correctViewport };
