import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { WikiEntry } from "../components/wiki_entry";
import { WikiNav } from "../components/wiki_nav";
import canvasPage from "../docs/canvas.md";
import adminPage from "../docs/admin.md";
import mainPage from "../docs/main.md";
import setupPage from "../docs/setup_canvas.md";
import archivePage from "../docs/archive.md";
import remarkFrontmatter from "remark-frontmatter";
import { unified } from "unified";
import remarkParse from "remark-parse";
import YAML from "yaml";
import { useLocation } from "react-router";
import { mobile } from "../styles/media";

const Wiki = () => {
  const entryFiles = [mainPage, canvasPage, adminPage, setupPage, archivePage];
  const [entry, setEntry] = useState(null);
  const [entries, setEntries] = useState([]);
  const { hash } = useLocation();
  const pageRefs = useRef([]);
  const loadPage = useCallback(async (link) => {
    const pageEntries = {
      main: mainPage,
      canvas: canvasPage,
      admin: adminPage,
      setup_canvas: setupPage,
      archive: archivePage,
    };
    let page = pageEntries[link];
    return fetch(page)
      .then((response) => response.text())
      .then(async (text) => {
        const r = await unified()
          .use(remarkParse)
          .use(remarkFrontmatter, ["yaml"]);
        const md = YAML.parse(r.parse(text).children[0].value);
        const entry = { metadata: md, text: text };
        setEntry(entry);
      });
  }, []);

  const setPageRefs = (page) => {
    pageRefs.current.push(page);
    setEntries([...pageRefs.current]);
  };

  useEffect(() => {
    const parseFiles = async () => {
      await entryFiles.forEach(async (entry) => {
        let response = await fetch(entry);
        let text = await response.text();
        const r = await unified()
          .use(remarkParse)
          .use(remarkFrontmatter, ["yaml"]);
        const md = YAML.parse(r.parse(text).children[0].value);
        setPageRefs(md);
      });
    };
    parseFiles();
    if (hash) {
      loadPage(hash.replace("#", ""));
    } else {
      loadPage("main");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <WikiNav entries={entries} callback={loadPage} />
      <Container>
        {entry && <WikiEntry key={entry.metadata.title} entry={entry} />}
      </Container>
    </Page>
  );
};

const Page = styled.div`
  margin: 1rem 0;
  padding: 0;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  @media ${mobile} {
    padding: 0 2rem !important;
    display: block;
    margin: 0 !important;
  }
`;

const Container = styled.div`
  border-radius: 1rem;
  box-shadow: 0px 3px 6px #00000029;
  box-sizing: border-box;
  width: 80%;
  padding-bottom: 2rem;
  max-width: 75vw;
  @media ${mobile} {
    width: 90%;
    max-width: unset;
  }
`;

export { Wiki };
