import styled from "styled-components";
import { colorGrayMedium, colorGrayWhite } from "../utils/constants";
import { AddButton, DeleteButton } from "../components/styled-admin_tools";

const AddItemsWidget = ({
  list,
  value,
  setValue,
  placeholder,
  onClickAdd,
  onClickDelete,
}) => {
  return (
    <>
      <AddContainer withList={!!list.length}>
        <AddInput
          value={value}
          withList={!!list.length}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          autoComplete="off"
        />
        <AddButton
          styled={{
            width: "1.5rem",
            flexShrink: 0,
            position: "absolute",
            right: "0.75rem",
          }}
          onClick={() => onClickAdd(value)}
        />
      </AddContainer>
      <GroupContainer>
        {list.map((item, idx) => (
          <SingleContainer key={idx}>
            <SingleDetails>{item}</SingleDetails>
            <DeleteButton
              styled={{ width: "1.5rem" }}
              onClick={() => onClickDelete(item)}
            />
          </SingleContainer>
        ))}
      </GroupContainer>
    </>
  );
};

const AddContainer = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid ${colorGrayMedium};
  display: flex;
  align-items: center;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  border-bottom-left-radius: ${(props) => (props.withList ? "0" : "0.4rem")};
  border-bottom-right-radius: ${(props) => (props.withList ? "0" : "0.4rem")};
  box-sizing: border-box;
`;

const AddInput = styled.input`
  display: block;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 1.25rem;
  margin: 0;
  border: none;
  font-size: 0.9rem;
  line-height: 1.75em;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  border-bottom-left-radius: ${(props) => (props.withList ? "0" : "0.4rem")};
  border-bottom-right-radius: ${(props) => (props.withList ? "0" : "0.4rem")};
`;

const GroupContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const SingleContainer = styled.li`
  display: flex;
  background: ${colorGrayWhite};
  padding: 0.5em 0.75em 0.5em 1.25em;
  border-top: 1px solid ${colorGrayMedium};
  border-right: 1px solid ${colorGrayMedium};
  border-left: 1px solid ${colorGrayMedium};
  align-items: center;

  &:last-of-type {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    border-bottom: 1px solid ${colorGrayMedium};
  }
`;

const SingleDetails = styled.div`
  flex-grow: 1;
`;

export default AddItemsWidget;
