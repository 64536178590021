import React, { useCallback } from "react";
import styled from "styled-components";
import { colorGrayDark } from "../utils/constants";

const UserList = ({ userData, callback, id }) => {
  const selectUser = useCallback(
    (e) => {
      callback(e);
    },
    [callback]
  );

  return (
    <ListContainer id={id}>
      <ul>
        {userData &&
          userData.map((user) => {
            return (
              <ListItem key={user} data-value={user} onClick={selectUser}>
                {user}
              </ListItem>
            );
          })}
      </ul>
    </ListContainer>
  );
};

const ListContainer = styled.div`
  max-height: 20vh;
  overflow: auto;
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
  transition: 0ms;
  :hover {
    background-color: ${colorGrayDark};
    transition: 0ms;
  }
`;

export { UserList };
