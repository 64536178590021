import { correctViewport } from "./correct_viewport";

/**
 * Helper function to apply a delta based zoom to canvas. If a mouse event is passed, it will zoom on the given point, otherwise, it will find the center point.
 * @param {fabric.Canvas} canvas - FabricJS Canvas Object
 * @param {number} delta - The zoom value, a negative value zooms in, positive zooms out
 * @param {Object} opt - Mouse event from Canvas object
 * @param {boolean} limitCanvas - Flag to set whether the canvas is limited to it's max size.
 * @param {number} width - Canvas width
 * @param {number} height - Canvas height
 * @param {number} minZoom - The minimum zoom factor
 */

const handleZoom = (
  canvas,
  delta,
  opt,
  limitCanvas = true,
  width,
  height,
  minZoom = 1
) => {
  if (canvas === null || canvas === undefined) {
    throw new Error("Must provide canvas object");
  }
  let zoom = canvas.getZoom();
  if (delta !== null) {
    zoom *= 0.999 ** delta;
    if (zoom > 14) zoom = 14;
    if (zoom < minZoom) zoom = minZoom;
  }
  if (opt == null) {
    /* If not a canvas mouse event zoom from center */
    const coords = canvas.vptCoords;
    const _x = (coords.tr.x - coords.tl.x) / 2.0;
    const _y = (coords.br.y - coords.tr.y) / 2.0;
    canvas.zoomToPoint({ x: _x, y: _y }, zoom);
  } else {
    canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
  }
  /* If we limit canvas size constrict viewport to that canvas size */
  if (limitCanvas) {
    correctViewport(canvas, width, height, zoom);
  }
  canvas.requestRenderAll();
};

export { handleZoom };
