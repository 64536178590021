import { useContext, useState, useCallback } from "react";
import { SystemContext } from "../context/system_messages";
import styled from "styled-components";
import { netflixRed } from "../utils/constants";
import { Button } from "./button";
import api from "../utils/api";
import { toast } from "react-toastify";

const SystemMessage = ({ user }) => {
  const {
    systemDisplay,
    status,
    message,
    confirmationRequired,
    confirmationMessage,
  } = useContext(SystemContext);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleModal = useCallback(
    (e) => {
      setShowModal(!showModal);
    },
    [showModal]
  );

  const setConfirmed = async () => {
    const idToken = await user.getIdToken();
    const [err, response] = await api("/api/system", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": idToken,
      },
      body: JSON.stringify({ "system_message.confirmed": true }),
    });
    if (err !== null) {
      toast.warning("Confirmation failed");
    }
    if (response?.status === 200) {
      toast.success("Confirmation Sent to System");
    }
  };

  const confirmationCallback = useCallback(
    (e) => {
      e.stopPropagation();
      if (showConfirmation) {
        setShowConfirmation(false);
        setShowModal(false);
        setConfirmed();
      } else {
        setShowConfirmation(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmationMessage, showConfirmation, showModal]
  );

  return (
    systemDisplay && (
      <Container
        className={`${status ? status : ""} ${showModal ? "open" : ""}`}
        onClick={toggleModal}
      >
        <MessageBox
          id="systemMessage"
          className={`${status ? status : ""} ${showModal ? "open" : ""}`}
          onClick={toggleModal}
        >
          <Alert
            className={`${status ? status : ""} ${showModal ? "open" : ""}`}
          >
            !
          </Alert>
          <MessageTitle>System Message</MessageTitle>
          <Message>{message}</Message>
          {confirmationRequired ? (
            <Confirm>
              <Message className={`${showConfirmation ? "open" : "hide"}`}>
                {confirmationMessage}
              </Message>
              <ConfirmButton
                text={"Confirm"}
                className={`${status ? status : ""} ${showModal ? "open" : ""}`}
                onClick={confirmationCallback}
              ></ConfirmButton>
            </Confirm>
          ) : (
            <ConfirmButton
              text={"Close"}
              className={`${status ? status : ""} ${showModal ? "open" : ""}`}
              onClick={toggleModal}
            ></ConfirmButton>
          )}
        </MessageBox>
      </Container>
    )
  );
};

const Container = styled.div`
  &.open {
    position: absolute;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #221f1fcc;
    z-index: 1000;
    top: 0;
    display: flex;
  }
`;

const MessageBox = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  padding: 1.25rem 2rem 2rem;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.5rem;
  z-index: 1000;
  box-sizing: border-box;
  max-width: 450px;
  &.open {
    padding: 1.5rem 3rem 2rem;
    position: relative;
    bottom: unset;
    right: unset;
    max-width: 500px;
  }
`;

const Alert = styled.h2`
  color: ${netflixRed};
  font-family: NetflixSansBold;
  font-size: 2.5rem;
  text-align: center;
  display: block;
  margin: 0;
  &.open {
    display: none;
  }
`;

const MessageTitle = styled.h2`
  font-family: NetflixSansBold;
  text-transform: uppercase;
  font-size: 1.25rem;
  text-align: center;
  &.open {
    margin: 2rem auto;
  }
`;

const Message = styled.div`
  font-size: 0.9rem;
  &.open {
    display: block;
  }
  &.hide {
    display: none;
  }
`;

const Confirm = styled.div`
  &.open {
    font-weight: bold;
    margin: 1rem auto;
  }
`;

const ConfirmButton = styled(Button)`
  display: none;
  &.open {
    display: block;
    margin: 2rem auto 0;
  }
`;

export { SystemMessage };
