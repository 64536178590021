import emojiRegex from "emoji-regex";

const matchEmojis = (str, unique=true) => {
  const base = [...str.matchAll(emojiRegex())];
  let fin = [];
  if (unique) {
    const map = new Map();
    for (let i = 0; i < base.length; i++) {
      const emo = base[i][0]
      if (map.get(emo)) {
        continue;
      } else {
        map.set(emo, true);
        fin.push(emo);
      }
    }
  } else {
    fin = base.map(m => m[0]);
  }
  return fin;
}

export default matchEmojis;