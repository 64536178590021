import styled from "styled-components";
import { colorGrayLight, colorGrayMedium } from "../utils/constants";
import { Button } from "./button";

const LilButton = styled.span`
  width: ${({ style }) => style.width || style.height};
  height: ${({ style }) => style.height || style.width};
  background: ${colorGrayLight};
  border-radius: 0.4rem;
  border: 1px solid ${colorGrayMedium};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${colorGrayMedium};
  }

  svg {
    width: 50%;
  }
`;

export const AddButton = ({ onClick, styled }) => {
  const sw = 15;
  return (
    <LilButton onClick={onClick} style={{ ...styled }} className="btn-add">
      <svg viewBox="0 0 100 100">
        <line
          strokeLinecap="round"
          x1={sw / 2}
          x2={100 - sw / 2}
          y1="50"
          y2="50"
          stroke="black"
          strokeWidth={sw}
        />
        <line
          strokeLinecap="round"
          x1="50"
          x2="50"
          y1={sw / 2}
          y2={100 - sw / 2}
          stroke="black"
          strokeWidth={sw}
        />
      </svg>
    </LilButton>
  );
};

export const DeleteButton = ({ onClick, styled }) => {
  const sw = 15;
  return (
    <LilButton onClick={onClick} style={{ ...styled }} className="btn-delete">
      <svg viewBox="0 0 100 100">
        <line
          strokeLinecap="round"
          x1={sw / 2}
          x2={100 - sw / 2}
          y1="50"
          y2="50"
          stroke="black"
          strokeWidth={sw}
        />
      </svg>
    </LilButton>
  );
};

export const ControlTitle = styled.div`
  clear: both;
  font-family: NetflixSansRegular;
  font-size: 0.9rem;
  color: #141414;
  margin-top: 1.125rem;
  margin-bottom: 1rem;
`;

export const AddUserInner = styled.div`
  flex-grow: 2;
  position: relative;
  display: flex;
  width: 100%;
`;

export const AddUserInput = styled.input`
  margin-left: 0.5rem;
  padding: 0 1.75rem 0 0.25rem;
  border: 1px solid;
  outline: none;
  font-size: 1rem;
  font-family: NetflixSansLight;
  width: 100%;
  border-radius: 0.25rem;
  border-color: #dadada;
  background-color: #fafafa;
  flex-grow: 2;
  box-sizing: border-box;
  line-height: 1.5rem;
`;

export const AddUserButton = styled.button`
  position: absolute;
  right: 0.25rem;
  border-radius: 0.25rem;
  background-color: #fafafa;
  top: 0.275rem;
  border: none;
  :hover {
    cursor: pointer;
    background-color: ${colorGrayLight};
  }
`;

export const AddUser = styled.div`
  display: flex;
  align-items: center;
  font-family: NetflixSansMedium;
  margin-top: 0.25rem;
  b {
    font-weight: 400;
  }
`;

export const DateInput = styled.input`
  max-width: 12rem;
  margin: 0;
  padding: 0.5rem 1.25rem;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 0.43rem;
  font-family: NetflixSansLight;
  font-size: 0.9rem;
  position: relative;
  background: transparent;

  :focus {
    outline: none;
  }

  &::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

export const DateInputWrap = styled.div`
  display: inline-block;
  margin-right: 1rem;
  position: relative;
  background: white;
  &::before {
    content: "";
    position: absolute;
    right: 1rem;
    top: calc(50% - 1.66rem / 2);
    width: 1.66rem;
    height: 1.66rem;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzUycHQiIGhlaWdodD0iNzUycHQiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDc1MiA3NTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogIDxwYXRoIGQ9Im0xNzguNjggMzc2YzAtMTA4Ljk4IDg4LjM0OC0xOTcuMzIgMTk3LjMyLTE5Ny4zMiAxMDguOTggMCAxOTcuMzMgODguMzQ4IDE5Ny4zMyAxOTcuMzIgMCAxMDguOTgtODguMzQ4IDE5Ny4zMy0xOTcuMzMgMTk3LjMzLTEwOC45OCAwLTE5Ny4zMi04OC4zNDgtMTk3LjMyLTE5Ny4zM3ptMTk3LjMyLTE1Ny44NmMtODcuMTg0IDAtMTU3Ljg2IDcwLjY3Ni0xNTcuODYgMTU3Ljg2IDAgODcuMTg4IDcwLjY3NiAxNTcuODYgMTU3Ljg2IDE1Ny44NiA4Ny4xODggMCAxNTcuODYtNzAuNjc2IDE1Ny44Ni0xNTcuODYgMC04Ny4xODQtNzAuNjc2LTE1Ny44Ni0xNTcuODYtMTU3Ljg2eiIvPgogIDxwYXRoIGQ9Im0zNzYgMjU3LjYxYzEwLjg5OCAwIDE5LjczNCA4LjgzNTkgMTkuNzM0IDE5LjczNHY3Ny41MzljMCA1LjUzMTIgMC4wMTk1MzEgOC4zMzk4IDAuMjA3MDMgMTAuMzQ4bDAuMDA3ODEzIDAuMDk3NjU3IDAuMDc4MTI1IDAuMDY2NDA2YzEuNTY2NCAxLjI2NTYgMy44OTA2IDIuODM5OCA4LjQ5MjIgNS45MTAybDQxLjYyNSAyNy43NDZjOS4wNjY0IDYuMDQ2OSAxMS41MiAxOC4yOTcgNS40NzI3IDI3LjM2Ny02LjA0MyA5LjA2NjQtMTguMjk3IDExLjUxNi0yNy4zNjMgNS40NzI3bC00MS42MjUtMjcuNzVjLTAuMTc5NjktMC4xMjEwOS0wLjM1OTM4LTAuMjQyMTktMC41NDI5Ny0wLjM2MzI4LTMuODI4MS0yLjU1MDgtNy43MzA1LTUuMTUyMy0xMC45MDItNy43MjI3LTMuNjQwNi0yLjk0NTMtNy40MTAyLTYuNjg3NS0xMC4yNDItMTEuOTg4LTIuODM5OC01LjI5NjktMy44NjMzLTEwLjUxMi00LjI5NjktMTUuMTcyLTAuMzc4OTEtNC4wNjY0LTAuMzc1LTguNzU3OC0wLjM3NS0xMy4zNTl2LTAuNjUyMzQtNzcuNTM5YzAtMTAuODk4IDguODMyLTE5LjczNCAxOS43My0xOS43MzR6Ii8+CiA8L2c+Cjwvc3ZnPgo=);
    background-size: 100%;
  }
`;

export const DateField = ({ type, options, value, onChange, ...rest }) => {
  return (
    <DateInputWrap {...rest}>
      <DateInput {...{ type, options, value, onChange }} />
    </DateInputWrap>
  );
};

export const ResetButton = styled(Button)`
  border-color: ${colorGrayLight};
  :hover {
    border-color: #707070;
  }
  margin-left: 1rem;
  width: 14em;
`;
