import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import contentEn from "./locales/en/translations.json"
import contentEs from "./locales/es/translations.json"
import contentFr from "./locales/fr/translations.json"

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  resources: {
    en: { translation: contentEn },
    es: { translation: contentEs },
    fr: { translation: contentFr },
  },
});

let lang = "en"
if (navigator?.languages) {
  let navLangs = navigator.languages;
  for (let i = 0; i < navLangs.length; i++) {
    /* Use highest priority language available */
    let l = navLangs[i];
    if (i18n.store.data[l]) {
      lang = l;
      break;
    } else if (i18n.store.data[l.split("-")[0]]) {
      lang = l.split("-")[0];
      break;
    }
  }
}

i18n.changeLanguage(lang)

export default i18n;
