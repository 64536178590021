import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LiveStats = ({ state }) => {
  const { t } = useTranslation();
  const [privateState, setPrivateState] = useState(state);

  useEffect(() => {
    setPrivateState(state);
  }, [state]);

  const { systemOn, strokeData, numUsers } = privateState;

  return (
    <>
      <ul>
        <li>
          <b>{t("admin_page.system_on")}</b>: {systemOn ? t("on") : t("off")}
        </li>
        <li>
          <b>{t("admin_page.active_users")}</b>: {numUsers}
        </li>
        <li>
          <b>{t("admin_page.strokes")}</b>: {strokeData.total}
        </li>
        <li>
          <b>{t("admin_page.drawn")}</b>: {strokeData.drawn}
        </li>
        <li>
          <b>{t("admin_page.percent_drawn")}</b>:{" "}
          {strokeData.total > 0
            ? ((strokeData.drawn / strokeData.total) * 100).toFixed(0)
            : 0}
          %
        </li>
      </ul>
    </>
  );
};

export default LiveStats;
