import React, { useCallback } from "react";
import styled from "styled-components";
import { netflixRed } from "../utils/constants";
import { NavLink, useLocation } from "react-router-dom";
import { mobile } from "../styles/media";

const WikiNav = ({ entries, callback }) => {
  const location = useLocation();

  const openPage = useCallback(
    async (e) => {
      // Load page scroll to element
      let id = e.target.name;
      let parent = e.target.dataset["parent"];
      if (parent && parent !== location.pathname) {
        await callback(parent);
        let element = document.getElementById(id);
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else if (parent) {
        let element = document.getElementById(id);
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        callback(id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  return (
    <>
      <Container>
        {entries.map((entry) => {
          return (
            <Item key={entry.link}>
              <Entry
                to={`/admin/wiki#${entry.link}`}
                onClick={openPage}
                name={entry.link}
              >
                {entry.title}
              </Entry>
              <Sections
                id={`${entry.link}-sections`}
                isActive={entry.link === location.hash.replace("#", "")}
              >
                {entry.sections &&
                  entry.sections.map((section, idx) => {
                    return (
                      <Item>
                        <Section
                          to={`/admin/wiki#${entry.link}`}
                          onClick={openPage}
                          name={entry.section_links[idx]}
                          data-parent={entry.link}
                        >
                          {section}
                        </Section>
                      </Item>
                    );
                  })}
              </Sections>
            </Item>
          );
        })}
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 15%;
  padding: 4rem 2rem;
  @media ${mobile} {
    padding: 4rem 0rem 1rem;
    width: unset;
  }
`;

const Item = styled.li`
  list-style-type: none;
  @media ${mobile} {
    &:first-of-type {
      padding: 0;
    }
    padding: 0 1rem;
    display: inline !important;
  }
`;

const Entry = styled(NavLink)`
  text-decoration: none;
  font-family: NetflixSansBold;
  color: ${netflixRed};
`;

const Sections = styled.ul`
  margin: 0;
  padding: 0 0.5rem;
  display: ${(props) => (props.isActive ? "block" : "none")};
  @media ${mobile} {
    display: none;
  }
`;

const Section = styled(NavLink)`
  text-decoration: none;
  font-family: NetflixSansLight;
  color: ${netflixRed};
`;

export { WikiNav };
