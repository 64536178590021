import React from "react";

const Pencil = ({ color }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 62 62"
    >
      <g id="Draw_Button" data-name="Draw Button">
        <path
          id="Icon_metro-pencil"
          data-name="Icon metro-pencil"
          className="cls-1"
          d="M39.09,20.44a3.41,3.41,0,0,1,2.74,5.47l-1.36,1.37-4.79-4.79L37,21.13A3.42,3.42,0,0,1,39.09,20.44ZM22,36.17l-1.36,6.14L26.79,41,39.44,28.3l-4.79-4.79Zm13.92-8-9.57,9.57L25.18,36.6,34.75,27Z"
        />
      </g>
    </svg>
  );
};

export { Pencil };
