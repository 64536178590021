const Pan = ({ color }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 62 62"
    >
      <path
        id="Icon_ionic-ios-hand"
        data-name="Icon ionic-ios-hand"
        className="cls-1"
        d="M37.11,21.55a1.36,1.36,0,0,0-1.38,1.35v7.18a.59.59,0,0,1-.59.58.58.58,0,0,1-.58-.58V19.49a1.39,1.39,0,0,0-2.77,0v8.32a.59.59,0,0,1-1.17,0V18.35a1.39,1.39,0,0,0-2.77,0V28.94a.58.58,0,0,1-.58.58.59.59,0,0,1-.59-.58V20.62a1.38,1.38,0,0,0-2.76,0V34.94L22.09,33.4c-1.71-1.49-3.16-2.13-4.34-1-.79.78.45,2,1.76,3.49s4.59,6,7.43,7.71a5.2,5.2,0,0,0,2.66.74h3.76c3,0,5.14-2.48,5.14-6.11V22.9A1.38,1.38,0,0,0,37.11,21.55Z"
      />
    </svg>
  );
};

export { Pan };
