import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import remarkFrontmatter from "remark-frontmatter";
import remarkHeadingId from "remark-heading-id";
import { netflixBlack, netflixRed } from "../utils/constants";

const WikiEntry = ({ entry }) => {
  return (
    <Container>
      {entry && (
        <ReactMarkdown
          key={entry.metadata.title}
          children={entry.text}
          linkTarget={entry.metadata.link}
          remarkPlugins={[[remarkFrontmatter], [remarkHeadingId]]}
          components={{ h1: Title, h2: SectionTitle }}
          includeElementIndex={true}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 2rem 0rem;
  background-color: white;
  width: 80%;
  margin: 1rem auto;
`;

const Title = styled.h1`
  font-family: NetflixSansLight;
  font-size: 1.6rem;
  scroll-margin: 0;
  color: ${netflixRed};
`;

const SectionTitle = styled.h2`
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  color: ${netflixBlack};
  scroll-margin: 0;
`;

export { WikiEntry };
