import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import styled from "styled-components";
import { activeCanvas } from "../utils/active_canvas";
import { app, db, firestoreDb } from "../services/firebase";
import {
  adminCanvasListener,
  strokeListener,
  systemDocListener,
} from "../utils/rtdbListeners";
import { createCanvas } from "../utils/create_canvas";
import { fabric } from "fabric";
import { pathProps } from "../utils/path_props";
import { onResizeFull } from "../utils/resize";
import {
  canvasTitleMaxLength,
  fullWidth,
  height,
  netflixRed,
} from "../utils/constants";
import { UserList } from "../components/user_list";
import Placeholder from "../components/placeholder";
import ActiveNotifications from "../components/active_notifications";
import CreateNotification from "../components/create_notification";
import SystemControls from "../components/system_controls";
import AddAdmin from "../components/add_admin";
import ActiveAdmins from "../components/active_admins";
import LiveTime from "../components/live_time";
import LiveStats from "../components/live_stats";
import AdminCanvas from "../components/admin_canvas";
import "./Admin.css";
import {
  canvasUserClickCallback,
  userListClickCallback,
} from "../utils/user_list_click_callback";
import { mobile, smallDesktop, tablet } from "../styles/media";
import api from "../utils/api";
import { Link } from "react-router-dom";
import { EditableInput } from "../components/editable_input";
import { PillList } from "../components/pill_list";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";
import { AuthContext } from "../context/auth";
import { generateSearchData } from "../utils/generate_search_data";
import {
  AddUserInner,
  AddUserInput,
  AddUserButton,
  ControlTitle,
  AddUser,
} from "../components/styled-admin_tools";
import { Button } from "../components/button";

const Admin = () => {
  const [loaded, setLoaded] = useState(false);
  const canvasDocRef = useRef(null);
  const [canvasDoc, setCanvasDoc] = useState(null);
  const [numUsers, setNumUsers] = useState(0);
  const activeUsersRef = useRef(null);
  const canvasListenerRef = useRef(null);
  const [strokeData, setStrokeData] = useState({});
  const systemListenerRef = useRef(null);
  const [systemDoc, setSystemDoc] = useState(null);
  const canvasNodeRef = useRef(null);

  const [addUserInputValue, setAddUserInputValue] = useState("");

  const systemListener = useRef(null);
  const initialized = useRef(false);

  const canvasRef = useRef(null);
  const strokeListenerRef = useRef(null);
  const usersListRef = useRef(null);
  const [usersList, setUsersList] = useState([]);
  const maxWidth = 1586;
  const [containerHeight, setContainerHeight] = useState(0);
  const { t } = useTranslation();

  const [notifications, setNotifications] = useState([]);
  const { user } = useContext(AuthContext);

  const [admins, setAdmins] = useState([]);

  const canvasDataCallback = useCallback((data, once) => {
    // Callback for canvas meta data, update stroke data and active users
    let total = 0;
    let drawn = 0;
    let localUserList = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        total += 1;
        if (data[key].status === "done") {
          drawn += 1;
        }
        //if (once) {
        localUserList.push(data[key].username);
        //}
      });
    }
    setStrokeData({ total: total, drawn: drawn });
    //if (once) {
    let s = [...new Set(localUserList)];
    usersListRef.current = s;
    setUsersList(usersListRef.current);
    //}
  }, []);

  const resizeCallback = useCallback(() => {
    let padding = 48;
    if (window.innerWidth > 1586) {
      padding = 0;
    }
    let h = (height * maxWidth) / fullWidth;
    onResizeFull(maxWidth, h, canvasRef.current, initialized, padding);
    canvasRef.current.setZoom(canvasRef.current.width / fullWidth);
  }, []);

  const onDrawCallback = useCallback((data) => {
    // Add new drawn line to admin canvas - then if a new user, add them to user list
    let json = JSON.parse(data.path);
    let path = new fabric.Path(json.path, pathProps(data.username));
    canvasRef.current.add(path);
    if (
      usersListRef.current &&
      usersListRef.current.indexOf(data.username) === -1
    ) {
      usersListRef.current.push(data.username);
      setUsersList([...usersListRef.current]);
    }
  }, []);

  const userClickWrapper = useCallback((event) => {
    // Callback for user selection - highlight user and strokes on canvas
    userListClickCallback(event, "#userList li", canvasRef.current);
  }, []);

  const setupListeners = () => {
    // Listen for Users on Active Canvas
    activeUsersRef.current = db.ref(
      `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDocRef.current.id}/users`
    );
    activeUsersRef.current.on("value", (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setNumUsers(Object.keys(data).length);
      } else {
        setNumUsers(0);
      }
    });

    // Listen to Canvas
    canvasListenerRef.current = adminCanvasListener(
      canvasDocRef.current,
      canvasDataCallback
    );

    //  Set up System listener
    systemListener.current = firestoreDb
      .collection("system")
      .doc(systemDoc.id)
      .onSnapshot((doc) => {
        setSystemDoc(doc.data());
      });
  };

  const removeListeners = () => {
    // Remove active user, system and canvas data listener
    // NOTE: stroke listener is always on

    if (activeUsersRef.current) {
      activeUsersRef.current.off("value");
    }
    if (systemListener.current) {
      systemListener.current();
    }
    if (canvasListenerRef.current) {
      canvasListenerRef.current.off("value");
    }
  };

  const load = async () => {
    // Setup function to set initial data on page load

    // Get Active Canvas
    canvasDocRef.current = await activeCanvas(user);
    setCanvasDoc(canvasDocRef.current);
    canvasRef.current = createCanvas(canvasNodeRef.current);

    if (canvasDocRef.current) {
      // Get Active Canvas Data
      const ref = db.ref(
        `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDocRef.current.id}/users`
      );
      ref.once("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setNumUsers(Object.keys(data).length);
        } else {
          setNumUsers(0);
        }
      });

      // Get Canvas Data
      canvasListenerRef.current = adminCanvasListener(
        canvasDocRef.current,
        canvasDataCallback,
        true
      );

      strokeListenerRef.current = strokeListener(
        canvasDocRef.current,
        onDrawCallback,
        () => {},
        true
      );
      setContainerHeight(getContainerHeight());
    }

    // Get System Status Info
    const idToken = await user.getIdToken();
    try {
      const res = await fetch("/api/system", {
        method: "GET",
        headers: {
          "X-Access-Token": idToken,
        },
      });
      const sd = await res.json();
      setSystemDoc(sd);
    } catch (e) {
      console.log("Error getting  system doc", e);
    }

    setLoaded(true);
  };

  const onLoadComplete = () => {
    getNotifications();
    hydrateAdmins();
    if (canvasDocRef.current) {
      setupListeners();
    }

    window.addEventListener("resize", resizeCallback);

    // Highlight users when hovering the strokes on canvas
    canvasRef.current.on("mouse:over", (e) => {
      if (e.target && e.target.username) {
        document
          .querySelector(`li[data-value=${e.target.username}]`)
          .classList.add("highlight");
      }
    });

    canvasRef.current.on("mouse:out", (e) => {
      if (e.target && e.target.username) {
        let u = document.querySelector(`li[data-value=${e.target.username}]`);
        if (
          !u.getAttribute("selected") ||
          u.getAttribute("selected") === "false"
        ) {
          u.classList.remove("highlight");
        }
      }
    });

    // Click stroke to toggle highlight
    canvasRef.current.on("mouse:down", (e) => {
      canvasUserClickCallback(e, "#userList li", canvasRef.current);
    });

    canvasRef.current.set({ defaultCursor: "default", hoverCursor: "pointer" });
    resizeCallback();

    // Setup System Doc Listener
    systemListenerRef.current = systemDocListener((data) => {
      if (!systemDoc) {
        setSystemDoc(data);
      } else if (data && systemDoc.active_canvas !== data?.active_canvas) {
        toast.success(t("admin_page.new_canvas_created"));
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    });
  };

  const updateCanvasData = useCallback(
    // Api calls for updating canvas data
    async (key, value) => {
      if (canvasDoc[key] === value || canvasDoc === null) {
        return;
      }
      let data = {};
      data[key] = value;
      if (key === "title") {
        const { title: searchTitle, terms: searchTerms } =
          generateSearchData(value);
        data["search_title"] = searchTitle;
        data["search_terms"] = searchTerms;
      }
      // Get user token for auth
      const userToken = await app.auth().currentUser.getIdToken();
      const [errors, response] = await api(
        `/api/canvas/${canvasDocRef.current.id}`,
        {
          method: "PUT",
          headers: {
            "X-Access-Token": userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (errors !== null) {
        toast.error(t("admin_page.update_canvas_error"));
        return false;
      } else if (response.status === 200) {
        toast.success(t("admin_page.update_success"));
        const json = await response.json();
        setCanvasDoc(json);
        return true;
      }
    },
    [canvasDoc, t]
  );

  const verifyUsers = (users, userList) => {
    let badEmails = [];
    for (let i = 0; i < users.length; i++) {
      if (!/^[^@]+@\w+(\.\w+)+\w$/.test(users[i])) {
        toast.warn(`${t("admin_page.invalid_email")}: ${users[i]}`);
        badEmails.push(users[i]);
        users.splice(i, 1);
      } else if (userList.indexOf(users[i]) > -1) {
        toast.warn(`${t("admin_page.user_exists")}: ${users[i]}`);
        users.splice(i, 1);
      }
    }
    return [users, badEmails];
  };

  const addUserCallback = useCallback(async () => {
    // Verify and add users
    let u = addUserInputValue;
    let users = u.replaceAll(" ", "").split(",");
    let badEmails = [];
    [users, badEmails] = verifyUsers(users, canvasDoc.users);
    if (users.length > 0) {
      let updateUsers = [...users, ...canvasDoc.users];
      await updateCanvasData("users", updateUsers);
    }
    if (badEmails.length > 1) {
      setAddUserInputValue(badEmails.join(", "));
    } else {
      setAddUserInputValue(badEmails.join(""));
    }
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [canvasDoc, updateCanvasData, t, addUserInputValue]);

  const removeUserCallback = useCallback(
    async (u) => {
      let updatedUsers = [...canvasDoc.users];
      const idx = updatedUsers.indexOf(u);
      updatedUsers.splice(idx, 1);
      return updateCanvasData("users", updatedUsers);
    },
    [canvasDoc, updateCanvasData]
  );

  const getContainerHeight = () => {
    /* Calculate the inner height available for canvas container */
    let windowHeight = window.innerHeight; //document.documentElement.clientHeight; //window.innerHeight;
    let navbar = document.querySelector("#headerWrap");
    let navHeight = navbar.offsetHeight;

    // On mobile we need to set a value due to the hidden nav bar
    if (navHeight === 0) {
      navHeight = 64;
    }
    return windowHeight - navHeight;
  };

  // ADMINS

  const hydrateAdmins = async () => {
    const idToken = await user.getIdToken();
    const [err, response] = await api("/api/admin", {
      method: "GET",
      headers: {
        "X-Access-Token": idToken,
      },
    });
    if (err !== null) {
      toast.error("Error loading admins");
    }
    if (response !== null) {
      const json = await response.json();
      setAdmins(json.items);
    }
  };

  // NOTIFICATIONS

  const getNotifications = async () => {
    const idToken = await user.getIdToken();
    const [err, response] = await api("/api/notification", {
      method: "GET",
      headers: {
        "X-Access-Token": idToken,
      },
    });
    if (err !== null) {
      toast.error("Error loading Canvas Notifications");
    }
    if (response !== null) {
      const json = await response.json();
      setNotifications(json.items);
    }
  };

  const deleteNotification = useCallback(async (n) => {
    const id = n.id;
    const idToken = await user.getIdToken();
    const [err, response] = await api(`/api/notification/${id}`, {
      method: "DELETE",
      headers: {
        "X-Access-Token": idToken,
      },
    });
    if (err !== null) {
      toast.error("Error deleting notification");
    }
    if (response.status === 200) {
      toast.success("Notification deleted");
      await getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadSVG = useCallback(() => {
    const svg = canvasRef.current.toSVG();
    const file = new Blob([svg], { type: "image/svg+xml" });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = `${canvasDocRef.current.title
      .split(" ")
      .join("-")}-${Date.now()}.svg`;
    document.body.appendChild(element);
    element.click();
  }, [canvasRef]);

  useEffect(() => {
    usersListRef.current = [];
    load();
    return () => {
      // Remove Active User Listener
      removeListeners();
      if (canvasRef.current) {
        canvasRef.current.clear();
        canvasRef.current.dispose();
      }
      if (strokeListenerRef.current) {
        strokeListenerRef.current.off("child_added");
      }
      window.removeEventListener("resize", resizeCallback);
      if (systemListenerRef.current) {
        systemListenerRef.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loaded) {
      onLoadComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return (
    <Container containerHeight={containerHeight}>
      <ControlPanel className="admin-container">
        <Panel className="1-col" aria-label="current canvas information">
          <PanelTitle>{t("admin_page.current_canvas")}</PanelTitle>
          <PanelBody>
            {loaded ? (
              canvasDoc ? (
                <ul>
                  <li>
                    <b>{t("admin_page.canvas_id")}</b>: {canvasDoc.id}
                  </li>
                  <li>
                    <b>{t("admin_page.canvas_started")}</b>:{" "}
                    {new Date(canvasDoc.timestamp).toLocaleDateString()}{" "}
                    {new Date(canvasDoc.timestamp).toLocaleTimeString()}
                  </li>
                  {/*
                  <li>
                    <b>{t("admin_page.canvas_completed")}</b>:{" "}
                    {canvasDoc.completed ? t("yes") : t("no")}
                  </li>
              */}
                  <li>
                    <b>{t("admin_page.canvas_type")}</b>: {canvasDoc.type}
                  </li>
                  <li id="canvasTitle">
                    <Flex>
                      <b>{t("admin_page.canvas_title")}</b>:{" "}
                      <EditableInput
                        id="canvasTitle"
                        docKey="title"
                        value={canvasDoc.title}
                        callback={updateCanvasData}
                        maxLength={canvasTitleMaxLength}
                        type="text"
                      />
                    </Flex>
                  </li>
                  <li id="canvasDescription">
                    <Flex>
                      <DescriptionLabel>
                        <b>{t("admin_page.canvas_description")}</b>:{" "}
                      </DescriptionLabel>
                      <EditableInput
                        id="canvasDescription"
                        docKey="description"
                        value={canvasDoc.description}
                        callback={updateCanvasData}
                        type="textarea"
                        autogrow={true}
                      />
                    </Flex>
                  </li>
                  <AddUser>
                    <b>{t("admin_page.canvas_users")}</b>:{" "}
                    <AddUserInner>
                      <AddUserInput
                        id="addUserInput"
                        value={addUserInputValue}
                        onChange={(e) => setAddUserInputValue(e.target.value)}
                        placeholder={t("admin_page.canvas_user_placeholder")}
                      />
                      <AddUserButton onClick={addUserCallback}>+</AddUserButton>
                    </AddUserInner>
                  </AddUser>
                  <PillList
                    items={canvasDoc.users}
                    callback={removeUserCallback}
                  ></PillList>
                </ul>
              ) : (
                <p aria-label="missing canvas">
                  <Trans i18nKey="admin_page.no_active_canvas">
                    There is currently no active Canvas. Please{" "}
                    <Link to="/admin/setup">set up</Link>a canvas to activate
                    the installation.
                  </Trans>
                </p>
              )
            ) : (
              <CurrentCanvasPlaceholder>
                <ul>
                  <li></li>
                  <li style={{ width: "80%" }}></li>
                  <li style={{ width: "80%" }}></li>
                  <li style={{ width: "66%" }}></li>
                </ul>
              </CurrentCanvasPlaceholder>
            )}
          </PanelBody>
        </Panel>

        <Panel className="1-col">
          <PanelTitle>{t("admin_page.live_stats")}</PanelTitle>
          <PanelBody>
            {systemDoc && (
              <LiveStats
                state={{
                  systemOn: systemDoc.on,
                  strokeData,
                  numUsers,
                }}
              />
            )}
          </PanelBody>
        </Panel>

        <Panel className="1-col">
          <PanelTitle>{t("admin_page.system_controls")}</PanelTitle>
          <PanelBody>
            {systemDoc && <SystemControls systemDoc={systemDoc} />}
          </PanelBody>
        </Panel>

        <Panel className="1-col">
          <PanelTitle>{t("admin_page.live_time.title")}</PanelTitle>
          <PanelBody>
            {systemDoc && <LiveTime liveSchedule={systemDoc.live_schedule} />}
          </PanelBody>
        </Panel>

        <Panel style={{ gridColumn: "1 / span 2" }}>
          <PanelTitle>{t("admin_page.notifications.header")}</PanelTitle>
          <NotificationsPanelBody>
            <CreateNotification
              notifications={notifications}
              verifyUsers={verifyUsers}
              setNotifications={setNotifications}
            />

            <div>
              <ControlTitle>
                {t("admin_page.notifications.active_header")}
              </ControlTitle>
              <ActiveNotifications
                notifications={notifications}
                deleteNotification={deleteNotification}
              />
            </div>
          </NotificationsPanelBody>
        </Panel>

        <Panel style={{ gridColumn: "1 / span 2" }}>
          <PanelTitle>{t("admin_page.admin_panel.title")}</PanelTitle>
          <AdminPanelBody>
            <AddAdmin admins={admins} hydrateAdmins={hydrateAdmins} />
            <div>
              <ControlTitle>{t("admin_page.admin_panel.current")}</ControlTitle>
              <ActiveAdmins admins={admins} hydrateAdmins={hydrateAdmins} />
            </div>
          </AdminPanelBody>
        </Panel>

        {canvasDoc && (
          <div style={{ gridColumn: "1 / span 2" }}>
            <AdminCanvas canvasDoc={canvasDoc} ref={canvasNodeRef} />
            <Button text="Download SVG" callback={downloadSVG} />
          </div>
        )}

        <Panel style={{ gridColumn: "1 / span 2" }}>
          <PanelTitle>{t("admin_page.contributing_users")}</PanelTitle>
          <UserPanelBody>
            {usersList && (
              <UserList
                id="userList"
                userData={usersList}
                callback={userClickWrapper}
              ></UserList>
            )}
          </UserPanelBody>
        </Panel>
      </ControlPanel>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: ${(props) => props.containerHeight}px;
  position: relative;
  background-color: #fafafa;

  .highlight {
    background-color: ${netflixRed} !important;
    transition: 100ms;
    color: white;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
  }
`;

const ControlPanel = styled.div`
  margin: 0 auto;
  padding: 2rem 1.5rem 7rem;
  max-width: 99.125rem;
  display: grid;
  grid: auto / 1fr 1fr;
  grid-gap: 2.2rem;
  grid-auto-rows: min-content;

  @media ${tablet} {
    grid: auto / 1fr;
    > * {
      grid-column: 1 !important;
    }
  }

  @media ${mobile} {
    padding: 4rem 0;
  }
`;

const Panel = styled.div`
  background-color: white;
  padding: 2.18rem 2.6rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  justify-content: space-around;
  box-sizing: border-box;

  @media ${smallDesktop} {
    padding: 1.5rem 2rem;
  }
`;

const PanelTitle = styled.h2`
  margin: 0 auto;
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  text-transform: uppercase;
`;

const PanelBody = styled.div`
  text-align: left;
  font-size: 0.9rem;
  clear: both;
  li {
    line-height: 1.5rem;
    font-family: NetflixSansLight;
    b {
      font-family: NetflixSansMedium;
      font-weight: normal;
    }
  }
  ul {
    padding: 0;
    list-style: none;
  }
`;

const DescriptionLabel = styled.div`
  align-self: flex-start;
  margin-top: 0.25rem;
`;

const UserPanelBody = styled(PanelBody)``;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const CurrentCanvasPlaceholder = styled(Placeholder)`
  margin-top: 1rem;
`;

const NotificationsPanelBody = styled(PanelBody)`
  display: grid;
  grid-gap: 2rem;
  grid: auto / 1fr 1fr;
  @media ${tablet} {
    grid: auto / 1fr;
  }
`;

const AdminPanelBody = styled(PanelBody)`
  display: grid;
  grid-gap: 2rem;
  grid: auto / 1fr 1fr;
  @media ${tablet} {
    grid: auto / 1fr;
  }
`;

export { Admin };
