import React from "react";
import styled from "styled-components";
import { netflixBlack } from "../utils/constants";

const SelectInput = ({
  id,
  label,
  options,
  defaultOption,
  onChange,
  inputClassName,
  value,
  dataAttributes = {},
  ...styledProps
}) => {
  return (
    <GroupContainer {...styledProps}>
      {label && (
        <Label htmlFor={id} defaultValue={defaultOption}>
          {label}
        </Label>
      )}
      <InputContainer>
        <Select
          id={id}
          onChange={onChange}
          value={value}
          className={inputClassName}
          {...dataAttributes}
        >
          {defaultOption && (
            <option value={defaultOption.value}>{defaultOption.text}</option>
          )}
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            );
          })}
        </Select>
      </InputContainer>
    </GroupContainer>
  );
};

const GroupContainer = styled.div``;

const InputContainer = styled.div`
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    transform: translateY(-50%) rotate(180deg);
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPkV4dHJhIEV4dHJhIEJvbGQgQ2hldnJvbiBVcDwvdGl0bGU+PGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+PGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgZmlsbD0iIzAwMDAwMCI+PHBhdGggZD0iTTY3LjUyNTEyNjMsNjIuNDc0ODczNyBDNjguODkxOTYxMyw2My44NDE3MDg4IDcxLjEwODAzODcsNjMuODQxNzA4OCA3Mi40NzQ4NzM3LDYyLjQ3NDg3MzcgQzczLjg0MTcwODgsNjEuMTA4MDM4NyA3My44NDE3MDg4LDU4Ljg5MTk2MTMgNzIuNDc0ODczNyw1Ny41MjUxMjYzIEw1Mi40NzQ4NzM3LDM3LjUyNTEyNjMgQzUxLjEwODAzODcsMzYuMTU4MjkxMiA0OC44OTE5NjEzLDM2LjE1ODI5MTIgNDcuNTI1MTI2MywzNy41MjUxMjYzIEwyNy41MjUxMjYzLDU3LjUyNTEyNjMgQzI2LjE1ODI5MTIsNTguODkxOTYxMyAyNi4xNTgyOTEyLDYxLjEwODAzODcgMjcuNTI1MTI2Myw2Mi40NzQ4NzM3IEMyOC44OTE5NjEzLDYzLjg0MTcwODggMzEuMTA4MDM4Nyw2My44NDE3MDg4IDMyLjQ3NDg3MzcsNjIuNDc0ODczNyBMNTAuMDA5NTcyMiw0NC45NDAxNzUyIEw2Ny41MjUxMjYzLDYyLjQ3NDg3MzcgWiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+");
    background-size: contain;
    top: 50%;
    right: 0.75rem;
    position: absolute;
    z-index: 0;
  }
`;

const Label = styled.label`
  display: block;
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  color: ${netflixBlack};
  text-transform: uppercase;
  margin: 1rem 0;
`;

const Select = styled.select`
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
  margin: 0;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 0.43rem;
  font-family: NetflixSansLight;
  font-size: 0.9rem;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  background: none;
  z-index: 1;
  position: relative;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

export { SelectInput };
