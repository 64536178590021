/**
 * API Fetch  helper function
 * @param {string} path - URL/API path to fetch
 * @param {object} options - Fetch options object
 * @return {array} Returns an array [error, response] - error is null when the fetch was successful
 */
const api = async (path, options) => {
  try {
    const response = await fetch(`${path}`, options);
    if (response.status >= 400) {
      const msg = await response.text();
      throw msg;
    }
    return [null, response];
  } catch (e) {
    return [e, null];
  }
};

export default api;
