import styled from "styled-components";
import { mobile, tablet } from "../styles/media";
import { netflixRed } from "../utils/constants";

const Minimap = () => {
  return (
    <Container id="minimap">
      <Viewport id="viewport"></Viewport>
      <img
        alt="Viewport"
        src=""
        style={{ height: "100%", width: "auto", pointerEvents: "none" }}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 4rem;
  width: auto;
  margin: 0 0.5rem;
  position: relative;
  cursor: default;
  img {
    user-select: none;
  }
  touch-action: none;
  @media ${tablet} {
    height: 4rem;
  }
  @media ${mobile} {
    height: 2.5rem;
    margin-top: 0.5rem;
  }
`;

const Viewport = styled.div`
  border: 2px black solid;
  width: 0;
  height: 0;
  position: absolute;
  cursor: grab;
  &.active {
    border-color: ${netflixRed};
  }
`;

export default Minimap;
