import styled, { keyframes } from "styled-components"

const Placeholder = ({children, ...relegated}) => {
  return(
    <Container {...relegated}>
    { children }
    </Container>
  )
}

const loadingAnimation = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`

const Container = styled.div`
  display: block;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    display: block;
    width: 100%;
    height: 2rem;
    margin-bottom: 1rem;
    background: rgba(235,235,235,1);
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(220,220,220,1);
      animation: ${loadingAnimation} 1s ease infinite;
    }
  }
`

export default Placeholder