const getRandomInt = (max) => {
  max = Math.floor(max);
  return Math.floor(Math.random() * max);
};

const getRandomRGB = () => {
  return `rgb(${getRandomInt(60) + 20}%, ${getRandomInt(60) + 20}%, ${
    getRandomInt(60) + 20
  }%)`;
};

export { getRandomInt, getRandomRGB };
