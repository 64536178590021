import React from "react";
import styled from "styled-components";
import { netflixBlack } from "../utils/constants";

const Input = ({
  type = "text",
  id,
  label = null,
  style,
  placeholder = "",
  disabled = false,
  value,
  onChange,
  autogrow = false,
  ...delegated
}) => {
  
  return (
    <Group style={style} {...delegated}>
      {label && <Label htmFor={id}>{label}</Label>}
      {type === "textarea" ? (
        <NiceTextArea onChange={onChange} value={value} disabled={disabled} id={id} placeholder={placeholder}></NiceTextArea>
      ) : (
        <NiceInput onChange={onChange} value={value} disabled={disabled} id={id} type={type} placeholder={placeholder}></NiceInput>
      )}
    </Group>
  );
};

const Group = styled.div``;

const NiceInput = styled.input`
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 0.43rem;
  font-family: NetflixSansLight;
  font-size: 0.9rem;

  [type="checkbox"] {
    width: inherit;
  }
  :focus {
    outline: none;
  }
`;

const NiceTextArea = styled.textarea`
  padding: 0.5rem;
  width: 100%;
  height: 80%;
  vertical-align: top;
  display: block;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 0.43rem;
  font-family: NetflixSansLight;
  font-size: 0.9rem;

  :focus {
    outline: none;
  }
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  color: ${netflixBlack};
  text-transform: uppercase;
  margin: 1rem 0;
`;

export { Input, NiceInput };
