import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import { AuthContext } from "../context/auth";
import api from "../utils/api";
import { SelectInput } from "../components/select";
import { Button } from "../components/button";
import {
  weekdaySequence,
  colorGrayMedium,
  colorGrayWhite,
  colorGrayLight,
} from "../utils/constants";
import {
  ControlTitle,
  DateField,
  AddButton,
  DeleteButton,
  ResetButton,
} from "../components/styled-admin_tools";

const CreateNotification = ({
  verifyUsers,
  notifications,
  setNotifications,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [customNotificationUsers, setCustomNotificationUsers] = useState([]);
  const [days, setDays] = useState(
    weekdaySequence.reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {})
  );
  const [time, setTime] = useState("09:00");
  const [frequency, setFrequency] = useState("");
  const [usersType, setUsersType] = useState("admins");
  const [addUsersInput, setAddUsersInput] = useState("");

  const frequencyOptions = [
    { value: "", text: t("admin_page.notifications.select_frequency") },
    { value: "daily", text: t("admin_page.notifications.daily") },
    { value: "weekly", text: t("admin_page.notifications.weekly") },
  ];

  const dayOptions = [
    { value: "sunday", text: t("weekdays.sunday.short") },
    { value: "monday", text: t("weekdays.monday.short") },
    { value: "tuesday", text: t("weekdays.tuesday.short") },
    { value: "wednesday", text: t("weekdays.wednesday.short") },
    { value: "thursday", text: t("weekdays.thursday.short") },
    { value: "friday", text: t("weekdays.friday.short") },
    { value: "saturday", text: t("weekdays.saturday.short") },
  ];

  const notifyOptions = [
    { value: "admins", text: t("admin_page.notifications.all_admins") },
    { value: "custom", text: t("admin_page.notifications.custom") },
    { value: "self", text: t("admin_page.notifications.self") },
  ];

  const addUsers = () => {
    let u = addUsersInput;
    let usersToAdd = u.replaceAll(" ", "").split(",");
    const [users, badEmails] = verifyUsers(usersToAdd, customNotificationUsers);
    setCustomNotificationUsers(customNotificationUsers.concat(users));
    if (badEmails.length) {
      setAddUsersInput(badEmails.join(badEmails.length > 1 ? ", " : ""));
    } else {
      setAddUsersInput("");
    }
  };

  const removeUser = (u) => {
    const idx = customNotificationUsers.indexOf(u);
    customNotificationUsers.splice(idx, 1);
    setCustomNotificationUsers([...customNotificationUsers]);
  };

  const clearNotificationValues = () => {
    setTime("");
    setDays(
      weekdaySequence.reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {})
    );
    setFrequency("");
    setUsersType("admins");
  };

  const getNotificationValues = () => {
    let values = {};
    let errors = [];

    if (time) {
      values["time"] = time;
    } else {
      errors.push(t("admin_page.notifications.time_required"));
    }
    switch (usersType) {
      case "admins":
        values["users"] = "admins";
        break;
      case "custom":
        if (customNotificationUsers.length) {
          values["users"] = customNotificationUsers;
        } else {
          errors.push(t("admin_page.notifications.no_custom_users"));
        }
        break;
      case "self":
        values["users"] = [user.email];
        break;
      default:
        errors.push(t("admin_page.notifications.no_users"));
    }
    switch (frequency) {
      case "daily":
        values["frequency"] = frequency;
        break;
      case "weekly":
        values["frequency"] = frequency;
        const selectedDays = weekdaySequence.filter((d) => days[d]);
        if (selectedDays.length) {
          values["days"] = selectedDays;
        } else {
          errors.push(t("admin_page.notifications.day_required"));
        }
        break;
      default:
        errors.push(t("admin_page.notifications.frequency_required"));
    }
    values["link"] = window.location.href;
    return [errors, values];
  };

  const createNotification = async () => {
    const [errors, values] = getNotificationValues();
    if (errors.length > 0) {
      toast.error(t("admin_page.notifications.all_fields_required"));
      return;
    }
    const idToken = await user.getIdToken();
    const [err, response] = await api("/api/notification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": idToken,
      },
      body: JSON.stringify(values),
    });
    if (err !== null) {
      toast.error(t("admin_page.notifications.error"));
    }
    if (response?.status === 200) {
      toast.success(t("admin_page.notifications.success"));
      const json = await response.json();
      setNotifications([...notifications, json]);
      setCustomNotificationUsers([]);
      clearNotificationValues();
    }
  };

  const resetFields = () => {
    setCustomNotificationUsers([]);
    clearNotificationValues();
  };

  return (
    <div id="CreateNotification">
      <ControlTitle>
        {t("admin_page.notifications.create_notification")}
      </ControlTitle>
      <NotificationDateField
        type="time"
        options={dayOptions}
        value={time}
        onChange={(e) => setTime(e.target.value)}
      />
      <FillSelect
        options={frequencyOptions}
        value={frequency}
        onChange={(e) => setFrequency(e.target.value)}
      />
      {frequency === "weekly" && (
        <div>
          {dayOptions.map((d) => (
            <DayOption key={d.value}>
              <input
                id={`notif-select-day-${d.value}`}
                name="day"
                type="checkbox"
                checked={days[d.value]}
                onChange={(e) =>
                  setDays((current) => ({
                    ...current,
                    [d.value]: e.target.checked,
                  }))
                }
              />
              <DayOptionLabel htmlFor={`notif-select-day-${d.value}`}>
                {d.text}
              </DayOptionLabel>
            </DayOption>
          ))}
        </div>
      )}
      <Subheading>{t(`admin_page.notifications.create_title`)}</Subheading>
      <FillSelect
        options={notifyOptions}
        value={usersType}
        onChange={(e) => setUsersType(e.target.value)}
      >
        {notifyOptions.map((o) => (
          <option key={o.value} value={o.value}>
            {o.text}
          </option>
        ))}
      </FillSelect>
      {usersType === "custom" && (
        <>
          <AddUser withList={!!customNotificationUsers.length}>
            <AddUserInput
              id="addNotifyUserInput"
              value={addUsersInput}
              withList={!!customNotificationUsers.length}
              onChange={(e) => setAddUsersInput(e.target.value)}
              placeholder={t("add_user_list.placeholder")}
              autoComplete="off"
            />
            <AddButton
              styled={{
                width: "1.5rem",
                flexShrink: 0,
                position: "absolute",
                right: "0.75rem",
              }}
              onClick={addUsers}
            />
          </AddUser>
          <UserGroupContainer>
            {customNotificationUsers.map((u) => (
              <UserSingleContainer key={u}>
                <UserDetails>{u}</UserDetails>
                <DeleteButton
                  styled={{ width: "1.5rem" }}
                  onClick={() => removeUser(u)}
                />
              </UserSingleContainer>
            ))}
          </UserGroupContainer>
        </>
      )}

      <CreateButton
        text={t("admin_page.notifications.create")}
        callback={createNotification}
      />
      <ResetButton
        callback={resetFields}
        bgColor={{ normal: colorGrayLight, hover: "#707070" }}
        color={{ normal: "white", hover: "white" }}
      >
        {t("reset")}
      </ResetButton>
    </div>
  );
};

const NotificationDateField = styled(DateField)`
  margin-bottom: 1rem;

  input {
    min-width: 11rem;
    margin: 0;
  }
`;

const AddUser = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid ${colorGrayMedium};
  display: flex;
  align-items: center;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  border-bottom-left-radius: ${(props) => (props.withList ? "0" : "0.4rem")};
  border-bottom-right-radius: ${(props) => (props.withList ? "0" : "0.4rem")};
  box-sizing: border-box;
`;

const AddUserInput = styled.input`
  display: block;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 1.25rem;
  margin: 0;
  border: none;
  font-size: 0.9rem;
  line-height: 1.75em;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  border-bottom-left-radius: ${(props) => (props.withList ? "0" : "0.4rem")};
  border-bottom-right-radius: ${(props) => (props.withList ? "0" : "0.4rem")};
`;

const FillSelect = styled(SelectInput)`
  width: 100%;
  margin: 0 0 1rem 0;
`;

const CreateButton = styled(Button)`
  margin-top: 1rem;
  width: 14em;
`;

const UserGroupContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const DayOption = styled.span`
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  height: 1rem;
`;

const DayOptionLabel = styled.label`
  margin: 0 0 0 0.15rem;
`;

const UserSingleContainer = styled.li`
  display: flex;
  background: ${colorGrayWhite};
  padding: 0.5em 0.75rem 0.5rem 1.25em;
  border-top: 1px solid ${colorGrayMedium};
  border-right: 1px solid ${colorGrayMedium};
  border-left: 1px solid ${colorGrayMedium};
  align-items: center;

  &:last-of-type {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    border-bottom: 1px solid ${colorGrayMedium};
  }
`;

const UserDetails = styled.div`
  flex-grow: 1;
`;

const Subheading = styled.h4`
  margin: 1.25rem 0 1rem;
  font-family: NetflixSansBold;
  text-transform: uppercase;
`;

export default CreateNotification;
