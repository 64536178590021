import { AuthProvider } from "./auth";
import { UiProvider } from "./ui";
import { SystemProvider } from "./system_messages";

const ContextProvider = ({ children }) => {
  return (
    <>
      <AuthProvider>
        <UiProvider>
          <SystemProvider>{children}</SystemProvider>
        </UiProvider>
      </AuthProvider>
    </>
  );
};

export default ContextProvider;
