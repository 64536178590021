const getMouseOrTouchPosition = (evt) => {
  let x = 0,
      y = 0,
      scale = 1;
  if (evt.pageX) {
    x = evt.pageX;
    y = evt.pageY;
    if (
      typeof TouchEvent !== "undefined" &&
      typeof evt === TouchEvent &&
      evt.scale
    ) {
      scale = evt.scale;
    }
  } else if (evt.touches?.length > 0) {
    x = evt.touches[0].pageX;
    y = evt.touches[0].pageY;
    if (evt.scale) {
      scale = evt.scale;
    }
  }
  return [x, y, scale];
};

export { getMouseOrTouchPosition };
