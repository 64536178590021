import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { colorWhite, offWhite } from "../utils/constants";
import { toast } from "react-toastify";

const UiContext = createContext({
  headerHeight: "5rem",
  showHeaderLinks: true,
});

const routeMap = {
  default: {
    bgColor: offWhite,
    headerHeight: "6.1875rem",
    showHeaderLinks: true,
    showAdminLinks: true,
  },
  "/": {
    showAdminLinks: true,
  },
  "/onboarding": {
    showHeaderLinks: true,
    showAdminLinks: true,
  },
  "/explore": {
    headerHeight: "6.1875rem",
    showHeaderLinks: true,
    showAdminLinks: true,
  },
  "/admin": {},
  "/admin/setup": {
    bgColor: offWhite,
  },
  "/admin/archive": {},
  "/admin/wiki": {
    bgColor: colorWhite,
  },
};

const UiProvider = ({ children }) => {
  const [bgColor, setBgColor] = useState(colorWhite);
  const [showHeaderLinks, setShowHeaderLinks] = useState(true);
  const [showAdminLinks, setShowAdminLinks] = useState(true);
  const [headerHeight, setHeaderHeight] = useState("5rem");
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
    const params = Object.assign(
      routeMap["default"],
      routeMap[location.pathname] || {}
    );
    setBgColor(params.bgColor);
    setShowHeaderLinks(params.showHeaderLinks);
    setHeaderHeight(params.headerHeight);
    setShowAdminLinks(params.showAdminLinks);
    if (window.innerWidth <= 780 || window.innerHeight <= 420) {
      toast.warning(
        "It looks like you may be using a mobile device. We reccommend a tablet or larger device for the best experience"
      );
    }
  }, [location.pathname]);

  return (
    <UiContext.Provider
      value={{
        bgColor,
        setBgColor,
        showHeaderLinks,
        setShowHeaderLinks,
        showAdminLinks,
        setShowAdminLinks,
        headerHeight,
        setHeaderHeight,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

export { UiContext, UiProvider };
