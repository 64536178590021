import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import { ToolButton } from "./tool_button";
import { ToolDoubleButton } from "./tool_double_button";
import { Button } from "./button";
import { createCanvas } from "../utils/create_canvas";
import { useTranslation } from "react-i18next";
import { handleZoom } from "../utils/handle_zoom";
import { fabric } from "fabric";
import { onResizeFull } from "../utils/resize";
import { netflixRed } from "../utils/constants";
import { mobile, mobileLandscape } from "../styles/media";

const Scratchpad = ({ onboarding = true, callback }) => {
  let canvas = useRef(null);
  let [mode, setMode] = useState("pan");
  const { t } = useTranslation();
  const zoomFactor = 100;
  const moving = useRef(false);
  const isDragging = useRef(false);
  const draggedOff = useRef(false);
  const lastX = useRef(0);
  const lastY = useRef(0);
  const drawing = useRef(false);
  const tip = useRef(null);
  const width = useRef(800);
  const height = useRef(400);
  const initialized = useRef(false);
  let mouseIsDown = false;

  const drawMode = useCallback(() => {
    setMode("draw");
    canvas.current.isDrawingMode = true;
    moving.current = false;
    drawing.current = true;
  }, []);

  const panMode = useCallback(() => {
    setMode("pan");
    canvas.current.isDrawingMode = false;
    moving.current = true;
    drawing.current = false;
  }, []);

  const buttonZoom = useCallback((zoom) => {
    if (canvas.current) handleZoom(canvas.current, zoom, null, false);
  }, []);

  const onMouseDown = (opt) => {
    draggedOff.current = false;
    mouseIsDown = true;
    let evt = opt.e;
    if (moving.current) {
      isDragging.current = true;
      if (evt.pageX) {
        lastX.current = evt.pageX;
        lastY.current = evt.pageY;
      } else if (evt.touches?.length > 0) {
        lastX.current = evt.touches[0].pageX;
        lastY.current = evt.touches[0].pageY;
      }
    } else {
      tip.current.set({ opacity: 0 });
      canvas.current.requestRenderAll();
    }
  };

  const onMouseUp = (opt) => {
    mouseIsDown = false;
    if (opt.e === undefined) return;
    if (moving.current) {
      canvas.current.setViewportTransform(canvas.current.viewportTransform);
      isDragging.current = false;
      canvas.current.selection = false;
    } else {
      tip.current.set({ opacity: 1 });
      canvas.current.requestRenderAll();
    }
  };

  const onMouseMove = (opt) => {
    let evt = opt.e;
    if (moving.current && isDragging.current) {
      canvas.current.isDrawingMode = false;
      let vpt = canvas.current.viewportTransform;
      if (evt.pageX) {
        vpt[4] += evt.pageX - lastX.current;
        vpt[5] += evt.pageY - lastY.current;
        lastX.current = evt.pageX;
        lastY.current = evt.pageY;
      } else if (evt.touches?.length > 0) {
        vpt[4] += evt.touches[0].pageX - lastX.current;
        vpt[5] += evt.touches[0].pageY - lastY.current;
        lastX.current = evt.touches[0].pageX;
        lastY.current = evt.touches[0].pageY;
      }
      canvas.current.requestRenderAll();
    }
    tip.current.set({
      left: opt.absolutePointer.x - 0.25,
      top: opt.absolutePointer.y - 0.25,
    });
    if (!mouseIsDown) {
      canvas.current.requestRenderAll();
    }
  };

  const onMouseOver = (opt) => {
    if (drawing.current) {
      tip.current.set({
        opacity: 1,
      });
      canvas.current.requestRenderAll();
    }
  };

  const onMouseOut = (opt) => {
    if (isDragging.current) return;
    if (opt.target === null || opt.e !== undefined) {
      opt = opt.e;
    }
    canvas.current.fire("mouse:up", opt);
    let me = new MouseEvent("mouseup", { ...opt, type: "mouseup" });
    document.dispatchEvent(me);
    draggedOff.current = true;
    tip.current.set({
      opacity: 0,
    });
    canvas.current.requestRenderAll();
  };

  const hotKeys = (e) => {
    switch (e.key) {
      case " ":
        e.preventDefault();
        panMode();
        break;
      case "d":
        e.preventDefault();
        drawMode();
        break;
      default:
        break;
    }
  };

  const resizeCallback = useCallback(() => {
    onResizeFull(width.current, height.current, canvas.current, initialized);
  }, []);

  useEffect(() => {
    canvas.current = createCanvas("scratchCanvas");
    if (
      window.orientation !== 0 &&
      window.orientation !== undefined &&
      window.innerWidth < 900
    ) {
      width.current = window.innerWidth - 100;
      height.current = 300;
    }
    canvas.current.setDimensions({
      width: width.current,
      height: height.current,
    });
    canvas.current.isDrawingMode = false;
    canvas.current.set({
      freeDrawingCursor: `url('red_pencil.png') 0 24, auto`,
      hoverCursor: "grab",
    });
    canvas.current.setZoom(2.5);

    // Add Zoom on Wheel Event
    canvas.current.on("mouse:wheel", (opt) => {
      const delta = opt.e.deltaY;
      handleZoom(canvas.current, delta, opt, false, 0, 0, 2.5);
    });

    canvas.current.on("mouse:down", onMouseDown);
    canvas.current.on("mouse:up", onMouseUp);
    canvas.current.on("mouse:move", onMouseMove);
    canvas.current.on("mouse:over", onMouseOver);
    canvas.current.on("mouse:out", onMouseOut);
    canvas.current.on("object:added", (p) => {
      p.target.stroke = "black";
    });

    // Handle Resize
    window.addEventListener("resize", resizeCallback);
    onResizeFull(width.current, height.current, canvas.current, initialized);

    // Button Zooms
    let zoomButtons = document.getElementById("scratchZoom");
    zoomButtons.querySelector(".left-button").addEventListener("click", () => {
      buttonZoom(-zoomFactor);
    });
    zoomButtons.querySelector(".right-button").addEventListener("click", () => {
      buttonZoom(zoomFactor);
    });

    // Handle draw off canvas mouse up trigger
    document.addEventListener("mouseup", (e) => {
      if (draggedOff.current) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
    panMode();
    tip.current = new fabric.Circle({
      radius: 0.25,
      fill: "black",
      stroke: "black",
      x: 0,
      y: 0,
      top: 0,
      left: 0,
      strokeWidth: 0,
    });
    canvas.current.add(tip.current);

    document.addEventListener("keyup", hotKeys);

    return () => {
      window.removeEventListener("resize", resizeCallback);
      canvas.current.clear();
      canvas.current.dispose();
      document.removeEventListener("keyup", hotKeys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      paddingBottom={onboarding === true ? "2rem" : "0"}
      onboarding={onboarding}
    >
      {onboarding === true ? (
        <Description>{t("onboarding.scratch_description")}</Description>
      ) : (
        <h3> {t("scratchpad.heading")} </h3>
      )}
      <CanvasContainer id="scratchCanvasContainer">
        <canvas id="scratchCanvas"></canvas>
      </CanvasContainer>
      {onboarding === true && (
        <ScratchReminder>{t("onboarding.scratch_reminder")}</ScratchReminder>
      )}
      <ToolDrawer>
        <ScratchToolButton
          type="draw"
          isSelected={mode === "draw"}
          callback={drawMode}
        ></ScratchToolButton>
        <ScratchToolButton
          type="pan"
          isSelected={mode === "pan"}
          callback={panMode}
        ></ScratchToolButton>
        <ToolDoubleButton id="scratchZoom"></ToolDoubleButton>
      </ToolDrawer>
      {onboarding === true ? (
        <ReadyButton
          text={"I'm Ready to Sketch"}
          callback={callback}
          animated={true}
          bgColor={{ normal: "white", hover: netflixRed }}
          color={{ normal: netflixRed, hover: "white" }}
        ></ReadyButton>
      ) : (
        <CloseButton
          callback={callback}
          bgColor={{ normal: "#9F9F9F", hover: netflixRed }}
          color={{ normal: "white", hover: "white" }}
          aria-label="close"
        >
          {t("explore_page.close")}
        </CloseButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  text-align: center;
  padding-bottom: ${(props) => props.paddingbottom};
  ${(props) =>
    props.onboarding
      ? ""
      : `
  @media ${mobileLandscape} {
    width: 100vw;
  }`}
`;

const Description = styled.div`
  font-family: NetflixSansLight;
  font-size: 0.9rem;
  color: #141414;
  max-width: 500px;
  margin: 0 auto;
  line-height: 22px;
`;

const CanvasContainer = styled.div`
  max-width: 800px;
  width: 100%;
  text-align: center;
  margin: 1rem auto 1.5rem;
  .canvas-container {
    box-shadow: none !important;
    border: solid 1px #979797;
    margin: 0 auto;
  }
  @media ${mobileLandscape} {
    margin: 0 auto;
    padding: 0;
  }
`;

const ToolDrawer = styled.div`
  display: flex;
  margin: 1.5rem auto 2rem;
  width: 80%;
  justify-content: center;
  gap: 1.5rem;
  @media ${mobile} {
    gap: 0.25rem;
    width: 100%;
  }
`;

const ScratchToolButton = styled(ToolButton)`
  padding: 0;
`;

const ScratchReminder = styled.div`
  max-width: 500px;
  margin: 0 auto;
  font-family: NetflixSansLight;
  font-size: 0.9rem;
  line-height: 22px;
  color: #141414;
  p {
    margin: 3rem auto;
  }
`;

const ReadyButton = styled(Button)`
  margin-bottom: 2rem;
`;

const CloseButton = styled(Button)`
  width: 193px;
  border-color: #9f9f9f;
  &:hover {
    border-color: ${netflixRed};
  }
`;

export { Scratchpad };
