import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import styled from "styled-components";
import { DescriptionBlock } from "../components/descriptionblock";
import {
  mobile,
  smallDesktop,
  tablet,
  avgLaptop,
  w1000,
  w1400,
} from "../styles/media";
import { activeCanvas } from "../utils/active_canvas";
import { Loading } from "../components/loading";
import { createCanvas } from "../utils/create_canvas";
import { fabric } from "fabric";
import { onResizeFull } from "../utils/resize";
import { relativeStart } from "../utils/timestamps";
import { strokeListener, systemDocListener } from "../utils/rtdbListeners";
import { pathProps } from "../utils/path_props";
import {
  canvasDescriptionPreviewLength,
  fullWidth,
  height,
  netflixRed,
} from "../utils/constants";
import fsIcon from "../imgs/FullScreenIconx2.png";
import playIcon from "../imgs/Icon-play-circle.png";
import { Calendar } from "../icons/calendar";
import logoSymbol from "../imgs/Netflix_Symbol_PMS.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth";

const closeFullScreen = () => {
  /* Callback to close fullscreen */
  if (document.fullscreenElement && document.exitFullscreen) {
    document.exitFullscreen();
  }
};

const Home = () => {
  const [loaded, setLoaded] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(null);
  const [ready, setReady] = useState(false);
  const resizeWidth = useRef(fullWidth);
  const [buttonPad, setButtonPad] = useState();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [descExpanded, setDescExpanded] = useState(false);
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const unmountTasks = useRef([]);

  const canvas = useRef(null);
  const canvasDoc = useRef(null);
  const initialized = useRef(false);
  const listenerRef = useRef(null);
  const systemDocRef = useRef(null);
  const systemListenerRef = useRef(null);
  const placeholderImg = useRef(null);
  const placeholderImgLrg = useRef(null);

  /* Page load setup */
  const load = async () => {
    // Get Active Canvas Data
    canvasDoc.current = await activeCanvas(user);
    setLoaded(true);
  };

  const onLoaded = () => {
    unmountTasks.current.push(removeListeners);

    // If there is an active canvas
    if (canvasDoc.current) {
      // Set title and description or load defaults
      if (canvasDoc.current.title !== null && canvasDoc.current.title !== "") {
        setTitle(canvasDoc.current.title);
      } else if (canvasDoc.current.type === "global_mural") {
        setTitle(t("global_mural"));
      } else if (canvasDoc.current.type === "sketch_battle") {
        setTitle(t("sketch_battle"));
      }
      if (
        canvasDoc.current.description !== null &&
        canvasDoc.current.description !== ""
      ) {
        setDescription(canvasDoc.current.description);
      }
      if (canvasDoc.current.rendered_placeholder) {
        fabric.Image.fromURL(
          canvasDoc.current.rendered_placeholder,
          (placeImg) => {
            placeholderImg.current = placeImg;
            canvas.current.add(placeholderImg.current);
            canvas.current.requestRenderAll();
          },
          { crossOrigin: "anonymous" }
        );
        fabric.Image.fromURL(
          canvasDoc.current.rendered_placeholder_lrg,
          (placeImgLrg) => {
            placeholderImgLrg.current = placeImgLrg;
            placeholderImgLrg.current.set({
              opacity: 0,
            });
            placeholderImgLrg.current.scale(0.25);
            canvas.current.add(placeholderImgLrg.current);
            canvas.current.requestRenderAll();
          },
          {
            crossOrigin: "anonymous",
          }
        );
      }

      listenerRef.current = strokeListener(
        canvasDoc.current,
        onDrawingEvent,
        () => {
          setReady(true);
        }
      );

      // Setup System Doc Listener - Listens for New Canvases
      systemListenerRef.current = systemDocListener((data) => {
        if (systemDocRef.current === null) {
          systemDocRef.current = data;
        } else if (systemDocRef.current.active_canvas !== data.active_canvas) {
          toast.success(t("home.new_canvas"));
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      });
    } else {
      setTitle(t("home.no_canvas_title"));
      setDescription(t("home.no_canvas_description"));
      setReady(true);
    }

    // Create FabricJS Canvas
    canvas.current = createCanvas("viewCanvas");
    canvas.current.defaultCursor = "arrow";
    canvas.current.hoverCursor = "arrow";
    // Window Resize Listener/Callback
    onResizeFull(fullWidth, height, canvas.current, initialized);
    calculateResizeWidth();
    resizeCallback();
    setHeaderHeight(document.getElementById("headerWrap")?.offsetHeight);
  };

  const onDrawingEvent = (data) => {
    if (!canvas.current) {
      return null;
    }
    /* Callback for when new strokes added to canvas 
    @param data - json entry from realtime database stroke document
    */
    let json = JSON.parse(data.path);
    // Create path adding username
    let path = new fabric.Path(json.path, pathProps(data.username));
    // Draw on Canvas
    canvas.current.add(path);
  };

  const fullScreen = () => {
    /* Function to toggle the canvas to a fullscreen element. 
    Checks that fullscreen is available (not available on mobile)
    */
    let canvasContainer = document.querySelector("#canvasContainer");
    if (
      document.fullscreenElement !== undefined &&
      !document.fullscreenElement &&
      canvasContainer.requestFullscreen
    ) {
      canvasContainer.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (
      document.webkitFullscreenElement !== undefined &&
      !document.webkitFullscreenElement &&
      canvasContainer.webkitRequestFullscreen
    ) {
      canvasContainer.webkitRequestFullscreen();
    }
    resizeCallback();
  };

  const fullScreenChange = (event) => {
    if (document.fullscreenElement !== null && window.innerWidth > 2000) {
      placeholderImg.current.set({ opacity: 0 });
      placeholderImgLrg.current.set({ opacity: 1 });
      placeholderImgLrg.current.scale(0.2);
      canvas.current.renderAll();
    } else {
      placeholderImg.current.set({ opacity: 1 });
      placeholderImgLrg.current.set({ opacity: 0 });
      canvas.current.renderAll();
    }
  };

  const resizeCallback = useCallback(() => {
    /* Hook Callback for resize - use hook so we can remove on unmount */
    onResizeFull(
      fullWidth,
      height,
      canvas.current,
      initialized,
      resizeWidth.current
    );
    setHeaderHeight(document.getElementById("headerWrap")?.offsetHeight);
  }, []);

  const calculateResizeWidth = useCallback(() => {
    if (window.innerWidth > fullWidth) {
      resizeWidth.current = 1;
      setButtonPad(16);
    } else if (window.innerWidth > 1260) {
      resizeWidth.current = 128;
      setButtonPad(80);
    } else if (window.innerWidth > 1023) {
      resizeWidth.current = 64;
      setButtonPad(48);
    } else if (window.innerWidth > 780) {
      resizeWidth.current = 32;
      setButtonPad(26);
    } else {
      resizeWidth.current = 17;
      setButtonPad(16);
    }
  }, []);

  const removeListeners = () => {
    /*Remove database listeners */
    if (listenerRef.current) {
      listenerRef.current.off("child_added");
    }
    // Remove Firebase Listener
    if (systemListenerRef.current) {
      systemListenerRef.current();
    }
  };

  const loadVideo = useCallback(() => {
    if (!videoLoaded) {
      document.getElementById("mp4Src").src =
        "https://storage.googleapis.com/animation-vt-drawn-together.appspot.com/assets/robodraw_b.mp4";
      document.getElementById("heroVideo").load();
      document.getElementById("heroVideo").play();
      document.getElementById("heroVideo").setAttribute("controls", true);
      setVideoLoaded(true);
    }
  }, [videoLoaded]);

  useEffect(() => {
    /* On page load run load function to setup all the things */
    load();
    window.addEventListener("resize", resizeCallback);
    window.addEventListener("resize", calculateResizeWidth);
    document
      .getElementById("canvasContainer")
      .addEventListener("click", closeFullScreen);
    document.addEventListener("fullscreenchange", fullScreenChange);
    document.addEventListener("onwebkitfullscreenchange", fullScreenChange);
    return () => {
      window.removeEventListener("resize", resizeCallback);
      window.removeEventListener("resize", calculateResizeWidth);
      document.removeEventListener("fullscreenchange", fullScreenChange);
      document.removeEventListener(
        "onwebkitfullscreenchange",
        fullScreenChange
      );
      //eslint-disable-next-line react-hooks/exhaustive-deps
      unmountTasks.current.forEach((t) => t());
      if (canvas.current !== null) {
        canvas.current.clear();
        canvas.current.dispose();
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loaded) {
      onLoaded();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return (
    <Container {...{ headerHeight }}>
      {!ready && <Loading></Loading>}
      <Main {...{ headerHeight }}>
        <Preview id="preview">
          <div id="canvasContainer">
            <Logo id="fsLogo">
              <img src={logoSymbol} alt="logo"></img>
              <Branding>{t("drawn_together")}</Branding>
            </Logo>
            <canvas id="viewCanvas"></canvas>
            {(document.exitFullscreen || document.webkitExitFullscreen) && (
              <CanvasButton
                id="fsButton"
                onClick={fullScreen}
                src={fsIcon}
                title="Toggle Fullscreen"
              ></CanvasButton>
            )}
          </div>
          <CanvasInfo>
            <TitleDescription id="titleDescription" padding={buttonPad}>
              <Title data-testid="canvas-title">{title}</Title>
              {description && (
                <Description data-testid="canvas-description">
                  {description.slice(
                    0,
                    descExpanded
                      ? description.length
                      : canvasDescriptionPreviewLength
                  )}
                  {description.length > canvasDescriptionPreviewLength ? (
                    <>
                      &nbsp;
                      <ReadMore
                        onClick={() => setDescExpanded((current) => !current)}
                      >
                        Read {descExpanded ? "Less" : "More"}
                      </ReadMore>
                    </>
                  ) : null}
                </Description>
              )}
            </TitleDescription>
            {canvasDoc.current && (
              <Timestamp id="timestamp" title="Timestamp" padding={buttonPad}>
                <Calendar size="0.75rem"></Calendar>
                {t("home.started_on")}:{" "}
                {relativeStart(canvasDoc.current.timestamp)}
              </Timestamp>
            )}
          </CanvasInfo>
        </Preview>
        <InfoBlock>
          <DescriptionBlock descriptionLead={""}></DescriptionBlock>
          <VideoBlock>
            <video
              id="heroVideo"
              poster="https://storage.googleapis.com/animation-vt-drawn-together.appspot.com/assets/poster.png"
              onClick={loadVideo}
            >
              <source id="mp4Src" type="video/mp4"></source>
            </video>
            {!videoLoaded && (
              <VideoPlayButton onClick={loadVideo} src={playIcon} />
            )}
          </VideoBlock>
        </InfoBlock>
      </Main>
      <FaqContainer id="faqContainer" title="FAQ">
        <FaqBlock id="faq">
          <Column>
            <Faq>
              <Question> {t("faq.q_1")} </Question>
              <Answer> {t("faq.a_1")} </Answer>
            </Faq>
            <Faq>
              <Question> {t("faq.q_2")} </Question>
              <Answer> {t("faq.a_2")} </Answer>
            </Faq>
            <Faq>
              <Question> {t("faq.q_3")} </Question>
              <Answer>
                <a href="mailto:djennings@netflix.com">djennings@netflix.com</a>
              </Answer>
            </Faq>
          </Column>
          <Column>
            <Faq>
              <Question> {t("faq.q_4")} </Question>
              <Answer> {t("faq.a_4")} </Answer>
            </Faq>
            <Faq>
              <Question> {t("faq.q_5")} </Question>
              <Answer> {t("faq.a_5")} </Answer>
            </Faq>
            <Faq>
              <Question> {t("faq.q_6")} </Question>
              <Answer> {t("faq.a_6")} </Answer>
            </Faq>
          </Column>
        </FaqBlock>
      </FaqContainer>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  margin: 0;
  background-color: #fafafa;
  min-height: calc(100vh - ${(props) => props.headerHeight}px);
  height: 100%;
  scroll-behavior: smooth;

  #viewCanvas {
    box-shadow: 0px 3px 6px #00000029;
  }
`;

const Main = styled.main`
  /*min-height: calc(100vh - ${(props) => props.headerHeight}px);*/
  display: flex;
  flex-flow: column;
  padding-bottom: 9rem;
  box-sizing: border-box;

    &::before {
      content: '';
      display: block;
      max-height: 4rem;
      min-height: 1rem;
      flex-grow: 1;
    }
  }

  @media ${mobile} {
    padding-bottom: 5rem;
    &::before {
      display: none;
    }
  }
`;

const CanvasInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1.5rem auto 0;
  padding: 0 0.5rem 0 5rem;
  box-sizing: border-box;
  max-width: 1620px;

  @media ${avgLaptop} {
    padding: 0 0.5rem 0 0.5rem;
  }

  @media ${mobile} {
    flex-flow: column;
  }
`;

const TitleDescription = styled.div`
  position: relative;
  margin: 0;
  display: block;
  text-align: left;
  max-width: 25rem;

  @media ${mobile} {
    font-size: 0.75rem;
    display: block;
    left: 0.75rem;
    order: 2;
    margin-top: 1rem;
  }
`;

const Title = styled.div`
  color: black;
  font-family: NetflixSansBold;
  font-size: 0.9rem;
  padding: 0;
  color: #141414;
  font-weight: normal;
  margin: 0 0 0.75em 0;
  line-height: 1em;
  @media ${mobile} {
    text-align: left;
  }
`;

const Description = styled.div`
  color: #221f1f;
  font-size: 0.75rem;
  font-family: "Nunito Sans", sans-serif;
  text-align: left;
  line-height: 1.8em;
  @media ${mobile} {
    margin: 0.25rem 0;
    padding: 0;
  }
`;

const Preview = styled.div`
  margin: 1rem auto 3rem;
  @media ${mobile} {
    margin: 4rem auto;
  }
`;

const CanvasButton = styled.img`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  border: none;
  display: block;
  padding: 0;
  width: 2rem;
  height: 2rem;
  :hover {
    cursor: pointer;
  }

  @media ${tablet} {
    width: 1.5rem;
    height: 1.5rem;
    bottom: 0.5rem;
  }
`;

const Logo = styled.div`
  float: left;
  margin: 3rem 4rem;
  width: 15%;
  max-width: 15rem;
  position: relative;
  display: none;
  img {
    display: block;
    max-width: 2.25rem;
    float: left;
  }
`;

const Branding = styled.div`
  font-family: NetflixSansBold;
  font-size: 19px;
  color: #141414;
  text-transform: uppercase;
  float: left;
  letter-spacing; -0.5px;
  max-width: 105px;
  line-height: 20px;
  margin-top: 0.75rem;
  text-align: left;
`;

const Timestamp = styled.div`
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.2rem;
  }
  @media ${mobile} {
    font-size: 0.75rem;
    margin-left: auto;
  }
  @media ${tablet} {
    right: ${(props) => `${props.padding - 8}px`};
  }
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: calc(100% - 128px);
  max-width: 1620px;
  box-sizing: border-box;

  @media ${avgLaptop} {
    width: 100%;
    margin: 0 auto;
    padding: 0 0.5rem 0 0.5rem;
  }
  @media ${smallDesktop} {
    justify-content: space-around;
  }
  @media ${w1000} {
    text-align: center;
    display: block;
    width: 100%;
  }
`;

const VideoBlock = styled.div`
  width: 40rem;
  position: relative;
  margin: 0 5rem auto 1rem;
  video {
    width: 100%;
  }
  @media ${w1400} {
    margin: 0 1rem auto 1rem;
  }
  @media ${w1000} {
    margin: 0 auto;
    width: calc(100% - 2rem);
    max-width: 52.5rem;
  }
  @media ${mobile} {
    width: calc(100% - 2rem);
    max-width: none;
  }
`;

const VideoPlayButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const FaqContainer = styled.div`
  background-color: white;
  width: 100%;
  @media ${w1000} {
    padding-top: 2rem;
  }
  display: none;
  &.show {
    display: block !important;
  }
`;

const FaqBlock = styled.div`
  padding: 8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  gap: 3rem;
  max-width: 1620px;
  @media ${tablet} {
    padding: 4rem;
  }
  @media ${w1000} {
    display: block;
    padding: 0;
    width: 100%;
  }
`;

const Column = styled.div`
  text-align: left;
  float: left;
  width: 50%;
  @media ${mobile} {
    float: unset;
    width: 80%;
    margin: 0 auto;
  }
`;

const Faq = styled.div`
  margin-bottom: 4rem;
`;

const Question = styled.p`
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  color: #141414;
  text-transform: uppercase;
  padding: 0;
`;

const Answer = styled.p`
  font-family: NetflixSansLight;
  font-family: 1rem;
  color: #141414;
  a {
    color: ${netflixRed};
    text-decoration: none;
  }
`;

const ReadMore = styled.span`
  display: inline-block;
  color: ${netflixRed};
  cursor: pointer;
  text-decoration: underline;
`;

export { Home };
