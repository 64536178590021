import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import styled from "styled-components";
import { activeCanvas } from "../utils/active_canvas";
import { createCanvas } from "../utils/create_canvas";
import { Loading } from "../components/loading";
import { onResizeFull, onResizeDraw } from "../utils/resize";
import {
  strokeListener,
  userListener,
  systemDocListener,
  connectedListener,
} from "../utils/rtdbListeners";
import { fabric } from "fabric";
import { pathProps } from "../utils/path_props";
import { Scratchpad } from "../components/scratchpad";
import { ToolsAndTips } from "../components/tools_and_tips";
import { handleZoom } from "../utils/handle_zoom";
import { hydratePreviews } from "../utils/hydrate_previews";
import {
  fullWidth,
  height,
  zoomFactor,
  throttleValue,
  netflixRed,
} from "../utils/constants";
import { updateViewport } from "../utils/updateViewport";
import { correctViewport } from "../utils/correct_viewport";
import { db } from "../services/firebase";
import { getRandomRGB } from "../utils/utils";
import { useTranslation } from "react-i18next";
import { UiContext } from "../context/ui";
import { colorGrayDark } from "../utils/constants";
import { AuthContext } from "../context/auth";
import { useHistory } from "react-router-dom";
import { ToolDrawer } from "../components/tool_drawer";
import "./Explore.css";
import { getMouseOrTouchPosition } from "../utils/mouse_or_touch";
import {
  mobile,
  mobileLandscape,
  smooshedScreen,
  tablet,
} from "../styles/media";
import { Action, Fab } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { InlineIcon } from "@iconify/react";
import eyeIcon from "@iconify-icons/fa-regular/eye";
import windowMaximize from "@iconify-icons/fa-regular/window-maximize";
import editIcon from "@iconify-icons/fa-regular/edit";
import handIcon from "@iconify-icons/fa-regular/hand-paper";
import { checkUser } from "../utils/check_user";
import { Calendar } from "../icons/calendar";
import { relativeStart } from "../utils/timestamps";
import { Button } from "../components/button";
import { toast } from "react-toastify";

const Explore = () => {
  const canvasDoc = useRef(null);
  const canvas = useRef(null);
  const initialized = useRef(null);
  const view = useRef("full");
  const [ready, setReady] = useState(false);
  const drawNew = useRef(false);
  const username = useRef("");
  const [showScratchpad, setShowScratchpad] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const { bgColor, setBgColor } = useContext(UiContext);
  const [showQuickView, setShowQuickView] = useState(false);
  const quickViewRef = useRef(false);
  const { user, isArtist } = useContext(AuthContext);
  const [showDebug, setShowDebug] = useState(false);
  //const [renderTime, setRenderTime] = useState(0);
  const startRender = useRef(0);
  //const [avgRender, setAvgRender] = useState(0);
  const avgRenderRef = useRef(0);
  const totalRenders = useRef(0);

  const panning = useRef(false);
  const drawing = useRef(false);
  const [mode, setMode] = useState(null);
  const draggedOff = useRef(false);
  const dragging = useRef(false);
  const lastX = useRef(0);
  const lastY = useRef(0);
  const tip = useRef(null);
  const minZoom = useRef(1);
  const drawHeight = useRef(800);
  const users = useRef({});
  const throttleTime = useRef(new Date().getTime());
  const listenerRef = useRef(null);
  const userListenerRef = useRef(null);
  const systemDocRef = useRef(null);
  const systemListenerRef = useRef(null);
  const lastInteraction = useRef(0);
  const intervalId = useRef(null);
  const history = useHistory();
  const viewportPan = useRef(false);
  const viewportLastX = useRef(0);
  const viewportLastY = useRef(0);
  const minimapIsStale = useRef(false);
  const [canvasExists, setCanvasExists] = useState(false);
  const connectedListenerRef = useRef(false);
  const connected = useRef(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const mouseIsDown = useRef(false);
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const placeholderImg = useRef(null);
  const placeholderImgLrg = useRef(null);
  const drawingStart = useRef(null);

  const load = async () => {
    // Get the active canvas if none, alert the user
    canvasDoc.current = await activeCanvas(user);
    if (canvasDoc.current === null || canvasDoc.current === undefined) {
      toast.warning(t("explore_page.no_canvas"));
      setReady(true);
      setCanvasExists(false);
      return;
    } else {
      setCanvasExists(true);
    }

    // Get user doc (or create if missing) to check for onboarding
    let userDoc = await checkUser(user);
    if (!userDoc.onboarded) {
      history.push("/onboarding");
      return;
    }

    setLoaded(true);
  };

  const onLoadComplete = () => {
    /* Load callback initializes page elements and listeners
       - Call from effect to prevent binding to unmounted nodes */

    // Special listener to check if user is connected to database
    // to prevent offline writes to database
    connectedListenerRef.current = connectedListener((value) => {
      if (value === connected.current) return;
      if (value === false && connected.current === true) {
        // If they are disconnected - provide message push them to home page
        // Provide toastId to prevent duplicate messages.
        toast.error(t("explore_page.network_error"), {
          toastId: "network-error",
        });
        history.push("/");
      }
      connected.current = value;
    });

    // Create Canvas
    canvas.current = createCanvas("liveCanvas");
    canvas.current.set({
      freeDrawingCursor: `url('red_pencil.png') 0 25, crosshair`,
      hoverCursor: "grab",
    });
    canvas.current.renderOnAddRemove = false;

    // Set username for attribution and positional updates
    setUsername(user);

    // Full view window resize callback
    onResizeFull(fullWidth, height, canvas.current, initialized, 96);
    if (canvasDoc.current.rendered_placeholder) {
      fabric.Image.fromURL(
        canvasDoc.current.rendered_placeholder,
        (placeImg) => {
          placeholderImg.current = placeImg;
          canvas.current.add(placeholderImg.current);
          canvas.current.requestRenderAll();
          hydratePreviews(canvas.current, ["#minimap", "#quickView"]);
        },
        { crossOrigin: "anonymous" }
      );
      fabric.Image.fromURL(
        canvasDoc.current.rendered_placeholder_lrg,
        (placeImgLrg) => {
          placeholderImgLrg.current = placeImgLrg;
          placeholderImgLrg.current.set({
            opacity: 0,
          });
          placeholderImgLrg.current.scale(0.2);
          canvas.current.add(placeholderImgLrg.current);
          canvas.current.requestRenderAll();
        },
        {
          crossOrigin: "anonymous",
        }
      );
    }

    // Create listener for canvas strokes
    listenerRef.current = strokeListener(
      canvasDoc.current,
      onDrawCallback,
      () => {
        /* Initialized callback */

        // Flag new lines to be drawn
        drawNew.current = true;
        setReady(true);

        // User cursor/pen tip circle
        tip.current = new fabric.Circle({
          fill: "black",
          opacity: 0,
          radius: 0.25,
          x: 0,
          y: 0,
          top: 0,
          left: 0,
          strokeWidth: 0,
        });
        canvas.current.add(tip.current);
        canvas.current.renderAll();

        // User Listener - for other user positional markers
        userListenerRef.current = userListener(
          canvasDoc.current,
          userMarkerUpdate,
          users.current,
          user.uid,
          canvas.current
        );
      }
    );

    // Set Mouse callbacks
    canvas.current.on("mouse:over", onMouseOver);
    canvas.current.on("mouse:out", onMouseOut);
    canvas.current.on("mouse:down", onMouseDown);
    canvas.current.on("mouse:up", onMouseUp);
    canvas.current.on("mouse:move", onMouseMove);
    canvas.current.on("mouse:wheel", onMouseWheel);

    // Set render callbacks
    canvas.current.on("before:render", () => {
      // TODO: Remove on final production
      if (drawNew.current) {
        // Used to calculate render times for performance debugging
        startRender.current = +new Date();
      }
    });

    canvas.current.on("after:render", () => {
      /* After render update viewport in minimap */
      if (drawNew.current && !viewportPan.current) {
        updateViewport(canvas.current, "#viewport", "#minimap");
        // TODO: remove render stuff on final prod push
        totalRenders.current += 1;
        const t = +new Date() - startRender.current;
        avgRenderRef.current += t;
        let rt = document.getElementById("renderTime");
        if (rt) rt.innerText = `Render Time ${t}ms`;
        let ar = document.getElementById("avgRender");
        if (ar) {
          ar.innerText = `Average Render ${(
            avgRenderRef.current / totalRenders.current
          ).toFixed(2)}ms`;
        }
      }
    });

    // Path and object creation callbacks
    canvas.current.on("path:created", pathCreated);
    canvas.current.on("object:added", (e) => {
      e.target.set("selectable", false);
    });

    canvas.current.skipOffscreen = true;

    // Button Zoom Click Listeners
    let zoomButtons = document.getElementById("zoomButtons");
    zoomButtons.querySelector(".left-button").addEventListener("click", () => {
      buttonZoom(-zoomFactor);
    });
    zoomButtons.querySelector(".right-button").addEventListener("click", () => {
      buttonZoom(zoomFactor);
    });

    // On blur - go into pan mode
    window.addEventListener("blur", onBlur);

    // If they close window or tab remove user from user list
    window.addEventListener("beforeunload", () => {
      db.ref(
        `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.current.id}/users/${user.uid}`
      ).remove();
    });

    // Setup System Listener - listen for new canvases
    systemListenerRef.current = systemDocListener((data) => {
      if (systemDocRef.current === null) {
        systemDocRef.current = data;
      } else if (systemDocRef.current.active_canvas !== data.active_canvas) {
        toast.success(t("explore_page.new_canvas"));
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    });

    // Viewport/Minimap listeners
    let viewportEl = document.getElementById("viewport");
    viewportEl.addEventListener("mousedown", viewportClick);
    viewportEl.addEventListener("touchstart", viewportClick);
    document.addEventListener("mousemove", viewportMove);
    document.addEventListener("touchmove", viewportMove);
    document.addEventListener("mouseup", viewportUp);
    document.addEventListener("touchend", viewportUp);
    //minimapEl.addEventListener("mouseleave", viewportUp);
    //minimapEl.addEventListener("touchleave", viewportUp);

    // Set header height
    setHeaderHeight(document.getElementById("headerWrap").offsetHeight);
    fullResizeCallback();
  };

  // Viewport on Minimap Interaction Callbacks

  const viewportClick = useCallback((e) => {
    /* On click or touch of viewport start grab and move process */
    viewportPan.current = true;
    let [x, y] = getMouseOrTouchPosition(e);
    viewportLastX.current = x;
    viewportLastY.current = y;
    e.target.style.cursor = "grabbing";
    document.documentElement.style.cursor = "grabbing";
  }, []);

  const viewportMove = useCallback((e) => {
    /* On Move callback for viewport - position div on minimap and limit to the size of image*/
    e.preventDefault();
    e.stopPropagation();
    if (view.current === "active" && viewportPan.current === true) {
      let [x, y] = getMouseOrTouchPosition(e);
      let vp = document.getElementById("viewport");
      let vpt = canvas.current.viewportTransform;
      let mm = document.getElementById("minimap");
      let left = parseFloat(vp.style.left.split("px")[0]);
      let top = parseFloat(vp.style.top.split("px")[0]);
      let dx = viewportLastX.current - x;
      let dy = viewportLastY.current - y;
      let zoom = canvas.current.getZoom();
      let vpw = parseFloat(vp.style.width.split("px")[0]);
      let vph = parseFloat(vp.style.height.split("px")[0]);
      let scaleX = canvas.current.getWidth() / vpw;
      let scaleY = canvas.current.getHeight() / vph;
      let border = 0;
      left -= dx;
      top -= dy;
      if (left < 0) {
        left = 0;
      }
      if (top < 0) {
        top = 0;
      }
      if (left + vpw + border > mm.offsetWidth) {
        left = mm.offsetWidth - vpw - border;
      }
      if (top + vph + border > mm.offsetHeight) {
        top = mm.offsetHeight - vph - border;
      }

      vpt[4] = -left * scaleX;
      vpt[5] = -top * scaleY;
      vp.style.left = `${left}px`;
      vp.style.top = `${top}px`;
      viewportLastX.current = x;
      viewportLastY.current = y;
      correctViewport(canvas.current, fullWidth, height, zoom);
      canvas.current.requestRenderAll();
    }
  }, []);

  const viewportUp = useCallback((e) => {
    /* On up callbackf or viewport - reset default values and turn off viewport panning */
    //e.preventDefault();
    if (viewportPan.current) {
      let [x, y] = getMouseOrTouchPosition(e);
      viewportPan.current = false;
      viewportLastX.current = x;
      viewportLastY.current = y;
      document.getElementById("viewport").style.cursor = "grab";
      document.documentElement.style.cursor = "auto";
    }
  }, []);

  const setUsername = useCallback((user) => {
    /* Set current user's info and set default document in the RTDB 
    This uses the user id from auth to secure writing access to the user themself.
     */
    username.current = `${user.email.replaceAll(/[@.#,$[\]]/g, "-")}`;
    let data = {
      timestamp: { ".sv": "timestamp" },
      top: -5,
      left: -5,
    };
    db.ref(
      `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.current.id}/users/${user.uid}`
    ).set(data);
  }, []);

  const resetUserMarker = useCallback(() => {
    /* DEPRECATED: Currently moving the user marker off canvas causes issues with rendering so for now 
    we don't do anything here.
    */
    if (isArtist === false) return;
    //let data = {
    //  top: -5,
    //  left: -5,
    //};
    //db.ref(
    //  `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.current.id}/users/${username.current}`
    //).set(data);
  }, [isArtist]);

  const userMarkerUpdate = useCallback(
    (_user, data) => {
      /* Update incoming user positional data */

      // TODO: Remove debug panel stuff - or make it conditional to dev/staging builds
      // This first section is for debug panel
      let userList = document.getElementById("userList");
      if (userList) {
        userList.innerHTML = "";
        Object.keys(users.current).forEach((u) => {
          if (users.current[u]["object"]) {
            let li = document.createElement("li");
            li.innerText = `${u} : (${users.current[u]["object"].left.toFixed(
              4
            )}, ${users.current[u]["object"].top.toFixed(4)})`;
            userList.appendChild(li);
          }
        });
      }

      // If this is the current user - return
      if (_user === user.uid) {
        return;
      }
      // Check if user's marker is in current viewport - if not do not render
      const vptBoundaries = canvas.current.calcViewportBoundaries();
      let inView = false;
      if (
        data.top >= vptBoundaries.tl.y &&
        data.top <= vptBoundaries.bl.y &&
        data.left >= vptBoundaries.bl.x &&
        data.left <= vptBoundaries.br.x
      ) {
        inView = true;
      }

      // If user doesn't exist in our local list, create new canvas object
      // and store data/object. Otherwise, update the location.
      if (users.current[_user]["object"] === undefined && !inView) {
        return;
      } else if (users.current[_user]["object"] === undefined && inView) {
        let color = getRandomRGB();
        let marker = new fabric.Circle({
          radius: 2,
          fill: color,
          strokeWidth: 0,
          zoom: canvas.current.getZoom(),
        });
        if (canvas.current.contextTop) {
          users.current[_user]["object"] = marker;
          canvas.current.add(marker);
        }
      } else if (users.current[_user]["object"] && !inView) {
        users.current[_user]["object"].set({ opacity: 0 });
        return;
      } else {
        /** TODO: Determine if we want to only set position when marker is in view
         *  or if rendering is the only issue.
         */
        users.current[_user]["object"].set({
          top: data.top,
          left: data.left,
          zoom: canvas.current.getZoom(),
        });
        // Weird hack to make user marker update and show
        const z = canvas.current.getZoom();
        canvas.current.setZoom(z);
      }
      // Check if user's marker is in current viewport - if not do not render
      if (inView) {
        users.current[_user]["object"].set({ opacity: 1 });
      }
      if (drawing.current === false && inView) {
        canvas.current.requestRenderAll();
      } else if (
        drawing.current === true &&
        mouseIsDown.current === false &&
        inView
      ) {
        canvas.current.requestRenderAll();
      }
      canvas.current.requestRenderAll();
    },
    [user.uid]
  );

  // View Change Functions
  const enterActiveView = useCallback(() => {
    /* Enter Active View - active view is the initial zoomed in state where you can pan and draw
    around canvas. The background is tinted and the resize callback is replaced
    */
    view.current = "active";
    swapPlaceholder(view.current);
    window.removeEventListener("resize", fullResizeCallback);
    window.addEventListener("resize", activeResizeCallback);
    activeResizeCallback();
    document
      .getElementById("canvasContainer")
      .classList.remove("full-container");
    document
      .getElementById("canvasContainer")
      .classList.add("active-container");
    setBgColor(colorGrayDark);
    document.getElementById("canvasContainer").classList.add("active");
    document.getElementById("timestamp").classList.toggle("active");
    document.getElementById("viewport").classList.add("active");
    //hydratePreviews(canvas.current, minimapRef.current, ["#minimap", "#quickView"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBgColor]);

  const exitActiveView = () => {
    /* Exit Active View - puts user in  "Full" view where you can no longer interact with canvas. 
    Replace resize callback, reset flags, and update minimap. */
    lastInteraction.current = +new Date();
    view.current = "full";
    swapPlaceholder(view.current);
    window.removeEventListener("resize", activeResizeCallback);
    window.addEventListener("resize", fullResizeCallback);
    minZoom.current = 1;
    setMode(null);
    canvas.current.isDrawingMode = false;
    drawing.current = false;
    panning.current = false;
    initialized.current = false;
    canvas.current.setZoom(1);
    fullResizeCallback();
    tip.current.set({
      opacity: 0,
    });
    resetUserMarker();
    canvas.current.renderAll();
    document.getElementById("canvasContainer").classList.add("full-container");
    document
      .getElementById("canvasContainer")
      .classList.remove("active-container");
    if (minimapIsStale.current) {
      hydratePreviews(canvas.current, ["#minimap", "#quickView"]);
      minimapIsStale.current = false;
    }
    setBgColor("#FAFAFA");
    document.getElementById("canvasContainer").classList.remove("active");
    document.getElementById("timestamp").classList.toggle("active");
    document.getElementById("viewport").classList.remove("active");
    document.getElementById("drawButton").classList.remove("selected");
    document.getElementById("panButton").classList.remove("selected");
  };

  const swapPlaceholder = (view) => {
    if (view === "active" && placeholderImg.current) {
      placeholderImg.current.set({ opacity: 0 });
      placeholderImgLrg.current.set({ opacity: 1 });
    } else if (placeholderImg.current) {
      placeholderImg.current.set({ opacity: 1 });
      placeholderImgLrg.current.set({ opacity: 0 });
    }
  };

  // Modal Callbacks
  const openScratchpad = useCallback(() => {
    /* Scratchpad Open Modal Callback */
    setShowScratchpad(true);
    document.getElementById("scratchpadBtn").classList.add("selected");
    // Close other Modals if open
    closeQuickView();
    closeTips();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openTips = useCallback(() => {
    /* Tips Open Modal Callback */
    setShowTips(true);
    document.getElementById("tipsButton").classList.add("selected");
    closeQuickView();
    closeScratchpad();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openQuickView = useCallback(async () => {
    /* Quick View Open Modal */
    setShowQuickView(true);
    quickViewRef.current = true;
    closeScratchpad();
    closeTips();
    document.getElementById("quickViewBtn").classList.add("selected");
    // If minimapIsStale - generate minimap img (which is same as quickview)
    if (minimapIsStale.current) {
      hydratePreviews(canvas.current, ["#minimap", "#quickView"]);
      minimapIsStale.current = false;
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeQuickView = useCallback((evt) => {
    /* Quick View Close Modal */
    if (evt !== undefined) {
      evt.stopPropagation();
    }
    setShowQuickView(false);
    document.getElementById("quickViewBtn").classList.remove("selected");
    quickViewRef.current = false;
  }, []);

  const closeScratchpad = useCallback((evt) => {
    /* Scratchpad Close Modal Callback */
    document.getElementById("scratchpadBtn").classList.remove("selected");
    if (evt !== undefined) {
      evt.stopPropagation();
    }
    setShowScratchpad(false);
  }, []);

  const closeTips = useCallback((evt) => {
    /* Tips Close Modal Callback */
    if (evt !== undefined) {
      evt.stopPropagation();
    }
    setShowTips(false);
    document.getElementById("tipsButton").classList.remove("selected");
  }, []);

  // Tool Callbacks
  const drawMode = useCallback(() => {
    /* Enter Draw Mode */
    // Update interaction time for timeout interval
    lastInteraction.current = +new Date();
    // Close modals if open
    closeQuickView();
    closeScratchpad();
    closeTips();
    setMode("draw");
    // If in full view enter active view
    if (view.current === "full") {
      enterActiveView();
    }
    // Flip flags
    canvas.current.isDrawingMode = true;
    panning.current = false;
    drawing.current = true;
    // Show cursor tip
    tip.current.set({
      opacity: 1,
    });
    canvas.current.requestRenderAll();
  }, [closeQuickView, closeScratchpad, closeTips, enterActiveView]);

  const panMode = useCallback(() => {
    /* Enter Pan Mode */
    lastInteraction.current = +new Date();
    // Close any modals if open
    closeQuickView();
    closeScratchpad();
    closeTips();
    setMode("pan");
    // Generate minimap
    if (minimapIsStale.current) {
      hydratePreviews(canvas.current, ["#minimap", "#quickView"]);
      minimapIsStale.current = false;
    }
    // If in full view enter active view
    if (view.current === "full") {
      enterActiveView();
    }
    // Hide cursor tip
    tip.current.set({
      opacity: 0,
    });
    // Flip flags
    canvas.current.isDrawingMode = false;
    panning.current = true;
    drawing.current = false;
    canvas.current.requestRenderAll();
  }, [closeQuickView, closeScratchpad, closeTips, enterActiveView]);

  const onBlur = useCallback(() => {
    /* Blur Callback - currently just sets panmode to ensure no accidental drawing
  when window is clicked on.
  */
    if (view.current === "active") {
      panMode();
    }
  }, [panMode]);

  const buttonZoom = useCallback(
    (delta) => {
      /* Callback for zoom buttons */
      // If in full view and zoom In enter active view.
      // If in active view exit active view.
      // Else handle zoom as usual
      if (view.current === "full" && delta < 0) {
        panMode();
      } else if (
        delta > 0 &&
        view.current === "active" &&
        canvas.current.getZoom() <= minZoom.current
      ) {
        exitActiveView();
      } else if (view.current === "active") {
        minZoom.current = drawHeight.current / height;
        handleZoom(
          canvas.current,
          delta,
          null,
          true,
          fullWidth,
          height,
          minZoom.current
        );
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [enterActiveView, panMode]
  );

  const hotKeys = (e) => {
    /* Hot key function callback */
    // TODO: Figure out keycodes for other locales
    switch (e.key) {
      case " ":
        e.preventDefault();
        panMode();
        break;
      case "d":
        e.preventDefault();
        if (isArtist) {
          drawMode();
        }
        break;
      case "s":
        e.preventDefault();
        if (quickViewRef.current) {
          closeQuickView();
        } else {
          openQuickView();
        }
        break;
      case "a":
        e.preventDefault();
        if (view.current === "active") {
          panMode();
        }
        openTips();
        break;
      default:
        break;
    }
  };

  // Mouse Callbacks
  const onMouseWheel = (opt) => {
    /* Mouse Wheel Callback - for zooming */
    const delta = opt.e.deltaY;
    // If in full view and zooming set set pan mode (enter active view side effect), and center on mouse
    if (delta < 0 && view.current === "full") {
      panMode();
      centerOnMouse(opt);
    } else if (view.current === "active") {
      // If in active and zooming out and zoom is smaller than minimum value, exit active view
      if (canvas.current.getZoom() <= minZoom.current && delta > 0) {
        exitActiveView();
      } else {
        // Otherwise handle zoom normally
        minZoom.current = drawHeight.current / height;
        handleZoom(
          canvas.current,
          delta,
          opt,
          true,
          fullWidth,
          height,
          minZoom.current
        );
      }
    }
    // TODO: Debug info - remove?
    setDebugCanvasSize();
  };

  const onMouseDown = (opt) => {
    /* Mouse Down Callback - for panning mostly. Drawing is handled by canvas library. */
    // Reset flag for "dragging off canvas"
    draggedOff.current = false;
    mouseIsDown.current = true;
    // If Panning - start dragging
    if (panning.current) {
      dragging.current = true;
      canvas.current.setCursor("grabbing");
      // Handle mouse or touch event and set "last" values
      if (opt.e.pageX) {
        lastX.current = opt.e.pageX;
        lastY.current = opt.e.pageY;
      } else if (opt.e.touches?.length > 0) {
        lastX.current = opt.e.touches[0].pageX;
        lastY.current = opt.e.touches[0].pageY;
      }
    } else if (drawing.current) {
      drawingStart.current = +new Date();
    }
  };

  const onMouseUp = (opt) => {
    /* Mouse Up Callback - Reset dragging flags and set viewport */
    mouseIsDown.current = false;
    if (opt.e === undefined) return;
    if (panning.current) {
      canvas.current.setViewportTransform(canvas.current.viewportTransform);
      dragging.current = false;
      canvas.current.selection = false;
      canvas.current.setCursor("grab");
    } else if (drawing.current) {
      drawingStart.current = null;
    }
  };

  const onMouseMove = useCallback(
    (opt) => {
      /* Mouse Move Callback */
      lastInteraction.current = +new Date();
      if (opt === null) return;
      // Get position from mouse or touch event
      let [x, y, scale] = getMouseOrTouchPosition(opt.e);
      if (scale !== 1 && view.current === "active") {
        // If scale value exists from windows touch screen use it for pinch zoom
        minZoom.current = drawHeight.current / height;
        if (scale > 1) {
          scale = -50;
        } else {
          scale = 50;
        }
        return handleZoom(
          canvas.current,
          scale,
          null,
          true,
          fullWidth,
          height,
          minZoom.current
        );
      }
      // TODO: Remove debug info stuff
      let selfPos = document.getElementById("selfPosition");
      if (selfPos && opt.e.touches?.length > 0) {
        selfPos.innerHTML = `Your Mouse: ${x}, ${y}`;
      }

      // If pan mode and dragging - adjust viewport
      if (panning.current && dragging.current) {
        canvas.current.setCursor("grabbing");
        canvas.current.isDrawingMode = false;
        let zoom = canvas.current.getZoom();
        let vpt = canvas.current.viewportTransform;
        vpt[4] += x - lastX.current;
        vpt[5] += y - lastY.current;
        lastX.current = x;
        lastY.current = y;
        correctViewport(canvas.current, fullWidth, height, zoom);
      }

      // If drawing and you leave the visible canvas area
      // fire a "up" event so we don't draw outside canvas.
      if (drawing.current) {
        let cOffset = canvas.current._offset;
        if (x < cOffset.left || x > canvas.current.getWidth() + cOffset.left) {
          fireTouchEnd(opt.e);
        }
        if (y < cOffset.top || y > canvas.current.getHeight() + cOffset.top) {
          fireTouchEnd(opt.e);
        }
        if (tip.current.opacity === 0) {
          tip.current.set({ opacity: 1.0 });
        }
        if (drawingStart.current) {
          const now = +new Date();
          const delta = now - drawingStart.current;
          if (delta > 30000) {
            fireMouseReset(opt.e);
          }
        }
      }

      // Set Cursor tip position and update user position
      if (view.current === "active") {
        tip.current.set({
          left: opt.absolutePointer.x - 0.25,
          top: opt.absolutePointer.y - 0.25,
        });
        let data = {
          left: opt.absolutePointer.x - 2,
          top: opt.absolutePointer.y - 2,
        };
        // Throttle user update and only update if  another user is present
        let t = new Date().getTime();
        if (
          t - throttleTime.current >= throttleValue &&
          Object.keys(users.current).length > 0 &&
          isArtist &&
          isNaN(data.left) === false &&
          isNaN(data.top) === false
        ) {
          db.ref(
            `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.current.id}/users/${user.uid}`
          ).update(data);
          throttleTime.current = t;
        }
      }
      if (drawing.current && mouseIsDown.current === false) {
        canvas.current.requestRenderAll();
      } else if (dragging.current) {
        canvas.current.requestRenderAll();
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [isArtist, user.uid]
  );

  const onMouseOver = (opt) => {
    /* Mouse Over callback - turn on cursor tip */
    if (drawing.current) {
      tip.current.set({
        opacity: 1,
      });
      canvas.current.requestRenderAll();
    }
  };

  const onMouseOut = (opt) => {
    /* Mouse Out Callback - fire mouse up*/
    if (dragging.current) return;
    if (opt.target === null || opt.e !== undefined) {
      opt = opt.e;
    }
    fireMouseUp(opt);
  };

  const fireMouseUp = (evt) => {
    /* Fire Mouse Up - create and dispatch a mouseup event to prevent drawing outside
    the canvas.
    */
    canvas.current.fire("mouse:up", evt);
    let mouseEvent = new MouseEvent("mouseup", { ...evt, type: "mouseup" });
    document.dispatchEvent(mouseEvent);
    draggedOff.current = true;
    if (drawing.current === true) {
      tip.current.set({
        opacity: 0,
      });
      canvas.current.requestRenderAll();
    }
    if (view.current === "active") {
      resetUserMarker();
    }
  };

  const fireMouseReset = (evt) => {
    canvas.current.fire("mouse:up", evt);
    let mouseEvent = new MouseEvent("mouseup", { ...evt, type: "mouseup" });
    document.dispatchEvent(mouseEvent);
    let touchEvent = new TouchEvent("touchend", { ...evt, type: "touchend" });
    document.dispatchEvent(touchEvent);
    //canvas.current.fire("mouse:down", evt);
    //mouseEvent = new MouseEvent("mousedown", { ...evt, type: "mousedown" });
    //document.dispatchEvent(mouseEvent);
  };

  const fireTouchEnd = (evt) => {
    /* Fire Touch End - prevent drawing outside canvas with touch devices/events */
    canvas.current.fire("mouse:up", evt);
    if (window.TouchEvent) {
      let touchEvent = new TouchEvent("touchend", { ...evt, type: "touchend" });
      document.dispatchEvent(touchEvent);
      draggedOff.current = true;
      if (drawing.current) {
        tip.current.set({
          opacity: 0,
        });
        canvas.current.requestRenderAll();
      }
      if (view.current === "active") {
        resetUserMarker();
      }
    }
  };

  const centerOnMouse = (opt) => {
    /* Center On Mouse - When Mouse Scroll Zooming in from "Full" view - center the 
    active canvas around mouse zoom */
    const zoom = canvas.current.getZoom();
    const centerX = canvas.current.getWidth() / zoom;
    const centerY = canvas.current.getHeight() / zoom;
    canvas.current.setZoom(1);
    canvas.current.absolutePan({
      x: opt.absolutePointer.x - centerX / 2,
      y: opt.absolutePointer.y - centerY / 2,
    });
    canvas.current.setZoom(zoom);
    correctViewport(canvas.current, fullWidth, height, zoom);
    canvas.current.requestRenderAll();
  };

  const onDrawCallback = (data) => {
    /* Stroke  RTDB Callback */
    // Parse json path data
    let json = JSON.parse(data.path);
    // Create path with username attribute
    let path = new fabric.Path(json.path, pathProps(data.username));
    //if (data.username === username.current) {
    //  path.stroke = "black";
    //} else {
    //  path.stroke = "#a0a0a0";
    //}
    // Add to canvas
    canvas.current.add(path);
    if (drawing.current === false) {
      canvas.current.requestRenderAll();
    } else if (drawing.current === true && mouseIsDown.current === false) {
      canvas.current.requestRenderAll();
    }
    minimapIsStale.current = true;
  };

  const pathCreated = useCallback((e) => {
    /* Path Created Callback
    When path is created, we remove it, push it to DB and let the realtime database
    callback add it to the canvas. This way only written paths are displayed. */
    canvas.current.remove(e.path);
    let data = {
      path: JSON.stringify(e.path),
      username: username.current,
      timestamp: { ".sv": "timestamp" },
      status: "new",
    };
    if (connected.current) {
      var ref = db.ref(
        `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.current.id}/strokes`
      );
      ref.push(data, (error) => {
        if (error) {
          toast.error(t("explore_page.error_pushing_stroke"));
        }
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Resize Callbacks
  // We have 2 for the 2 view "full" and "active" which have
  // separate requirements for size, viewport, and zoom levels.
  // Use hooks to wrap the resize functions so we can remove them cleanly
  const fullResizeCallback = useCallback(() => {
    /* Resize Callback for Full View */
    onResizeFull(fullWidth, height, canvas.current, initialized, 96);
    setHeaderHeight(document.getElementById("headerWrap").offsetHeight);
    //setDebugCanvasSize();
    let cHeight = getContainerHeight();
    //setContainerHeight(cHeight);
    document.getElementById("canvasContainer").style.height = `${cHeight}px`;
  }, []);

  const activeResizeCallback = useCallback(() => {
    /* Resize Callback for Active Draw Mode */
    let canvasEl = document.querySelector("#liveCanvas");
    // Calculate Height and min zoom value
    let adjustValue = window.innerHeight <= 800 ? 0 : 84;
    let cHeight = getContainerHeight() - adjustValue; // - 84;
    drawHeight.current = cHeight;
    //setContainerHeight(cHeight);
    minZoom.current = drawHeight.current / height; // * 2;
    onResizeDraw(fullWidth, drawHeight.current, canvas, canvasEl, minZoom);
    setHeaderHeight(document.getElementById("headerWrap").offsetHeight);
    document.getElementById("canvasContainer").style.height = `${cHeight}px`;
    setDebugCanvasSize();
  }, []);

  // TODO: Remove debug stuff
  const setDebugCanvasSize = () => {
    /* Debug function to display canvas size */
    let cs = document.getElementById("canvasSize");
    if (cs) {
      cs.innerText = `Canvas Size: ${canvas.current.getWidth()}w ${canvas.current.getHeight()}h ${canvas.current
        .getZoom()
        .toFixed(2)}z`;
    }
  };

  // Util Functions
  const getContainerHeight = () => {
    /* Calculate the inner height available for canvas container */
    let windowHeight = window.innerHeight; //document.documentElement.clientHeight; //window.innerHeight;
    let navbar = document.querySelector("#headerWrap");
    let navHeight = navbar.offsetHeight;

    // On mobile we need to set a value due to the hidden nav bar
    if (navHeight === 0) {
      navHeight = 64;
    }
    let toolDrawerPadding = window.innerHeight <= 800 ? 24 : 64;
    let toolDrawerHeight =
      document.querySelector("#toolDrawer").offsetHeight + toolDrawerPadding;
    return windowHeight - navHeight - toolDrawerHeight;
  };

  // TODO: Remove debug stuff
  const toggleDebug = useCallback(() => {
    /* Toggle debug modal */
    setShowDebug(!showDebug);
  }, [showDebug]);

  const iconMode = useCallback(() => {
    /* Fab icon helper */
    switch (mode) {
      case "draw":
        return (
          <InlineIcon
            icon={editIcon}
            color={"black"}
            width={"1.5rem"}
          ></InlineIcon>
        );
      case "pan":
        return (
          <InlineIcon
            icon={handIcon}
            color={"black"}
            width={"1.5rem"}
          ></InlineIcon>
        );
      default:
        return "+";
    }
  }, [mode]);

  // Main Use Effect (one time load)
  useEffect(() => {
    load();
    lastInteraction.current = +new Date();
    // Set an interaction timer - push them off page if they are inactive for 10 minutes
    intervalId.current = setInterval(() => {
      let d = +new Date() - lastInteraction.current;
      if (d > 600000) {
        window.clearInterval(intervalId.current);
        history.push("/");
      }
    }, 60000);
    window.addEventListener("resize", fullResizeCallback);
    drawNew.current = false;
    return () => {
      // unmountTasks.current.forEach(t => t());
      // Remove this user's rtdb document
      if (canvasDoc.current) {
        db?.ref(
          `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.current.id}/users/${user.uid}`
        )?.remove();
      }

      // Remove listeners
      window.removeEventListener("blur", onBlur);

      Object.keys(users.current).forEach((u) => {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        users.current[u].ref.off("value");
      });
      if (view.current === "full") {
        window.removeEventListener("resize", fullResizeCallback);
      } else {
        window.removeEventListener("resize", activeResizeCallback);
      }
      if (canvas.current !== null) {
        canvas.current.clear();
        canvas.current.dispose();
      }
      if (listenerRef.current !== null) {
        listenerRef.current.off("child_added");
      }
      if (userListenerRef.current !== null) {
        userListenerRef.current.off("child_added");
        userListenerRef.current.off("child_removed");
      }
      if (systemListenerRef.current) {
        systemListenerRef.current();
      }
      if (connectedListenerRef.current) {
        connectedListenerRef.current.off("value");
      }

      // Remove Interval Function
      window.clearInterval(intervalId.current);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* Secondary use effect for keyup eventlistener in case artist status changes */
    document.addEventListener("keyup", hotKeys);
    return () => {
      document.removeEventListener("keyup", hotKeys);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArtist]);

  useEffect(() => {
    /* Final Use Effect for detecting no-canvas - to ensure error message doesn't display falsely */
    if (ready && canvasExists) {
      hydratePreviews(canvas.current, ["#minimap", "#quickView"]).then(() => {
        updateViewport(canvas.current, "#viewport", "#minimap", true);
      });
    }
  }, [ready, canvasExists]);

  useEffect(() => {
    if (loaded) {
      onLoadComplete();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return (
    <Container id="exploreContainer" bgColor={bgColor} {...{ headerHeight }}>
      {!ready && <Loading title="Loading" cover={true}></Loading>}
      {!canvasExists && (
        <p title="No Canvas Message">{t("explore_page.no_canvas")}</p>
      )}
      {showScratchpad && (
        <ModalContainer
          onClick={closeScratchpad}
          setDisplay={"flex"}
          offsetHeight={headerHeight}
          role="dialog"
          aria-label="scratchpad modal"
        >
          <Modal
            width={null}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Scratchpad
              onboarding={false}
              callback={closeScratchpad}
            ></Scratchpad>
          </Modal>
        </ModalContainer>
      )}
      {showTips && (
        <ModalContainer
          onClick={closeTips}
          setDisplay={"flex"}
          offsetHeight={headerHeight}
          title="Tips Modal"
        >
          <Modal
            width={"550px"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ToolsAndTips
              onboarding={false}
              callback={closeTips}
            ></ToolsAndTips>
          </Modal>
        </ModalContainer>
      )}
      <QuickModalContainer
        id="quickView"
        bgColor={"rgba(0, 0, 0, .8)"}
        onClick={closeQuickView}
        setDisplay={showQuickView ? "block" : "none"}
        offsetHeight={headerHeight}
        role="dialog"
        aria-label="quickview"
      >
        <img alt="Quickview" width="95%" src=""></img>
        <CloseButton
          callback={closeQuickView}
          bgColor={{ normal: "#9F9F9F", hover: netflixRed }}
          color={{ normal: "white", hover: "white" }}
          aria-label="close quick view"
        >
          {t("explore_page.close")}
        </CloseButton>
      </QuickModalContainer>
      {canvasExists && (
        <CanvasContainer id="canvasContainer" alt="Canvas Container">
          <CanvasGroup>
            <canvas id="liveCanvas" aria-label="canvas"></canvas>
          </CanvasGroup>
          <Timestamp id="timestamp" title="Timestamp">
            <Calendar size="0.75rem"></Calendar>
            <span title="Started On">
              {t("explore_page.started_on")}:{" "}
              {canvasDoc.current && relativeStart(canvasDoc.current.timestamp)}
            </span>
          </Timestamp>
        </CanvasContainer>
      )}
      <div>
        {showDebug && (
          <div
            style={{
              marginTop: "-4rem",
              backgroundColor: "white",
              position: "absolute",
              bottom: "15rem",
              left: "2rem",
              borderRadius: "1rem",
              padding: "1rem",
              textAlign: "left",
              boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
              overflowY: "scroll",
              maxHeight: "10rem",
            }}
          >
            <div id="self">
              You are {isArtist ? "An Artist" : "A Viewer"}: {username.current}
            </div>
            <div id="selfPosition"></div>
            <div id="users">
              Users connected :<ul id="userList"></ul>
            </div>
            <div id="renderTime">Render Time: ms</div>
            <div id="avgRender">Average Render: ms</div>
            <div id="canvasSize"></div>
          </div>
        )}
      </div>
      <ToolDrawer
        mode={mode}
        view={view.current}
        isArtist={isArtist}
        drawMode={drawMode}
        panMode={panMode}
        openQuickView={openQuickView}
        toggleDebug={toggleDebug}
        openScratchpad={openScratchpad}
        openTips={openTips}
      ></ToolDrawer>
      <div className="show-mobile">
        <Fab icon={iconMode()} event="click">
          <Action text="Draw" onClick={drawMode}>
            <InlineIcon
              icon={editIcon}
              color={"black"}
              width={"1.5rem"}
            ></InlineIcon>
          </Action>
          <Action text="Pan" onClick={panMode}>
            <InlineIcon
              icon={handIcon}
              color={"black"}
              width={"1.5rem"}
            ></InlineIcon>
          </Action>
          <Action text="Quick View" onClick={openQuickView}>
            <InlineIcon
              icon={eyeIcon}
              color={"black"}
              width="1.75rem"
            ></InlineIcon>
          </Action>
          <Action text={t("explore_page.scratchpad")} onClick={openScratchpad}>
            <InlineIcon
              icon={windowMaximize}
              color={"black"}
              width="1.5rem"
            ></InlineIcon>{" "}
          </Action>
          <Action
            text={t("explore_page.tools_and_tips")}
            style={{ color: "black", fontSize: "1.75rem" }}
            onClick={openTips}
          >
            ?
          </Action>
        </Fab>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  text-align: center;
  /*min-height: calc(100vh - 8.5rem);*/
  position: relative;
  background-color: ${(props) => props.bgColor};
  @media ${mobile} {
    padding-top: 4rem;
  }
  @media ${mobileLandscape} {
    padding-top: 0rem;
  }
`;

const CanvasContainer = styled.div`
  /*height: ${(props) => props.containerHeight}px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    padding-top: 2rem;
  }
  @media ${smooshedScreen} {
    &.active {
      padding-top: 0;
    }
  }
`;

const CanvasGroup = styled.div`
  position: relative;
  #liveCanvas {
    box-shadow: 0px 3px 6px #00000029;
  }
`;

const Timestamp = styled.div`
  float: right;
  font-family: "Nunito Sans", sans-serif;
  margin: 1rem 0;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  bottom: 0rem;
  right: 4rem;
  position: absolute;
  svg {
    margin-right: 0.2rem;
  }

  &.active {
    position: unset;
    margin: 1.2rem 4rem 0 0;
  }
  @media ${mobile} {
    font-size: 0.75rem;
    margin: 2rem 0;
    &.active {
      margin: 0.2rem 4rem !important;
      right: 2rem;
    }
  }

  @media ${smooshedScreen} {
    &.active {
      margin: 1rem 2rem 0;
    }
  }
  @media ${mobileLandscape} {
    &.active {
      display: none;
    }
  }
  @media ${tablet} {
    /*right: ${(props) => `${props.padding - 8}px`};*/
  }
`;

const ModalContainer = styled.div`
  position: absolute;
  top: -${(props) => props.offsetHeight}px;
  height: 100vh;
  z-index: 500;
  width: 100%;
  background-color: ${(props) =>
    props.bgColor == null ? "#221F1FCC" : props.bgColor};
  display: ${(props) =>
    props.setDisplay === null ? "flex" : props.setDisplay};
  justify-content: center;
  align-items: center;

  @media ${mobile} {
    top: 0;
  }
  @media ${mobileLandscape} {
    z-index: 10000;
  }
`;

const QuickModalContainer = styled(ModalContainer)`
  img,
  button {
    transform: translate(0, 33vh);
  }
`;

const Modal = styled.div`
  max-width: 1500px;
  width: ${(props) => (props.width ? props.width : "none")};
  padding: 0rem 1.5rem 1rem;
  background-color: #f2f0f0;
  border-radius: 0.5rem;
  @media ${mobileLandscape} {
    padding: 0;
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 0;
  }
`;

const CloseButton = styled(Button)`
  width: 193px;
  border-color: #9f9f9f;
  margin-top: 1rem;
  &:hover {
    border-color: ${netflixRed};
  }
`;

export { Explore };
