import { db, firestoreDb } from "../services/firebase";

const strokeListener = (
  canvasDoc,
  drawCallback,
  loadCallback,
  adminPage = false
) => {
  /* Setup Realtime Database Listeners */
  let strokeRef = db.ref(
    `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.id}/strokes`
  );
  if (
    !adminPage &&
    canvasDoc.placeholder_key &&
    canvasDoc.rendered_placeholder
  ) {
    strokeRef = strokeRef.orderByKey().startAfter(canvasDoc.placeholder_key);
  }
  strokeRef.on("child_added", (snapshot) => {
    const data = snapshot.val();
    if (data) {
      drawCallback(data);
    }
  });

  /* Listener fires once when all strokes are loaded */
  strokeRef.once("value", (snapshot) => {
    loadCallback();
  });

  return strokeRef;
};

const userListener = (canvasDoc, drawCallback, users, username, canvas) => {
  /* Setup Realtime Database Listener for User Location and Activity */
  let usersRef = db.ref(
    `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.id}/users`
  );
  usersRef.on("child_added", (snapshot) => {
    if (snapshot.key !== username) {
      let userRef = db.ref(
        `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.id}/users/${snapshot.key}`
      );
      if (users[snapshot.key] === undefined) {
        users[snapshot.key] = { ref: userRef };
      } else {
        users[snapshot.key]["ref"] = userRef;
      }
      userRef.on("value", (snapshot) => {
        let data = snapshot.val();
        if (data) {
          drawCallback(snapshot.key, snapshot.val());
        }
      });
    }
  });

  usersRef.on("child_removed", (snapshot) => {
    if (
      users[snapshot.key] !== undefined &&
      users[snapshot.key]["ref"] !== undefined
    ) {
      users[snapshot.key]["ref"].off("value");
      if (canvas.contextTop) {
        canvas?.remove(users[snapshot.key]["object"]);
      }
      delete users[snapshot.key];
    }
  });
  return usersRef;
};

const adminCanvasListener = (canvasDoc, callback, once = false) => {
  let canvasRef = db.ref(
    `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDoc.id}/strokes`
  );
  if (once === false) {
    canvasRef.on("value", (snapshot) => {
      let data = snapshot.val();
      callback(data, once);
    });
  } else {
    canvasRef.once("value", (snapshot) => {
      let data = snapshot.val();
      if (data === null) return;
      callback(data, once);
    });
  }
  return canvasRef;
};

const systemDocListener = (callback) => {
  //  Set up System listener
  const systemListener = firestoreDb
    .collection("system")
    .doc(process.env.REACT_APP_SYSTEM_DOC)
    .onSnapshot((doc) => {
      callback(doc.data());
    });
  return systemListener;
};

const connectedListener = (callback) => {
  const connectedRef = db.ref(".info/connected");
  connectedRef.on("value", (snapshot) => {
    callback(snapshot.val());
  });
  return connectedRef;
};

export {
  strokeListener,
  userListener,
  adminCanvasListener,
  systemDocListener,
  connectedListener,
};
