import { useState } from "react";
import styled from "styled-components";
import { DeleteButton } from "../components/styled-admin_tools";
import { colorGrayMedium, colorGrayWhite } from "../utils/constants";

const Item = ({ item, children, deletable, deleteFn }) => {
  const [deleting, setDeleting] = useState(false);

  const managedDelete = async (i) => {
    setDeleting(true);
    try {
      await deleteFn(i);
    } catch (e) {
      console.error(e);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <SingleContainer className="singleContainer">
      <ItemDetails blocked={deleting}>{children}</ItemDetails>
      {deletable && (
        <DeleteButton
          styled={{ width: "1.5rem", flexShrink: 0 }}
          onClick={() => (deleting ? null : managedDelete(item))}
        />
      )}
    </SingleContainer>
  );
};

const ListItemsWidget = ({
  items,
  deleteFn,
  renderFn,
  emptyMessage = "No items.",
  checkDeletable = () => true, // All items deletable by default
}) => {
  return (
    <GroupContainer>
      {items.length ? (
        items.map((i, idx) => (
          <Item
            key={idx}
            item={i}
            deleteFn={deleteFn}
            deletable={checkDeletable(i)}
          >
            {renderFn(i)}
          </Item>
        ))
      ) : (
        <strong>{emptyMessage}</strong>
      )}
    </GroupContainer>
  );
};

const GroupContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const SingleContainer = styled.li`
  display: flex;
  background: ${colorGrayWhite};
  padding: 0.75em 0.75em 0.75em 1.5em;
  border-top: 1px solid ${colorGrayMedium};
  border-right: 1px solid ${colorGrayMedium};
  border-left: 1px solid ${colorGrayMedium};
  align-items: center;

  &:first-of-type {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }
  &:last-of-type {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    border-bottom: 1px solid ${colorGrayMedium};
  }
`;

const ItemDetails = styled.div`
  flex-grow: 1;
  opacity: ${(props) => (props.blocked ? 0.5 : 1)};
`;

export default ListItemsWidget;
