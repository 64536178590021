import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { netflixRed, onGreen } from "../utils/constants";

const ToggleButton = ({ inState, idOff, idOn, callbackOff, callbackOn }) => {
  const { t } = useTranslation();
  
  const toggle = useCallback(
    (e) => {
      const val = e.target.dataset.state === "true";
      if (val) {
        document.getElementById(idOn)?.classList.remove("off");
        document.getElementById(idOff)?.classList.remove("off");
        document.getElementById(idOff)?.classList.add("on");
        document.getElementById(idOn)?.classList.add("on");
        callbackOn(val);
      } else {
        document.getElementById(idOn)?.classList.add("off");
        document.getElementById(idOff)?.classList.add("off");
        document.getElementById(idOn)?.classList.remove("on");
        document.getElementById(idOff)?.classList.remove("on");
        callbackOff(val);
      }
    },
    [callbackOn, callbackOff, idOn, idOff]
  );

  const mouseOver = useCallback(
    (e) => {
      if (e.target.id === idOn) {
        e.target.classList.remove("mouseOff");
        document.getElementById(idOff).classList.remove("mouseOff");
        e.target.classList.add("mouseOn");
        document.getElementById(idOff).classList.add("mouseOn");
      } else {
        e.target.classList.remove("mouseOn");
        document.getElementById(idOn).classList.remove("mouseOn");
        document.getElementById(idOn).classList.add("mouseOff");
        e.target.classList.add("mouseOff");
      }
    },
    [idOn, idOff]
  );

  const mouseOut = useCallback(
    (e) => {
      document.getElementById(idOff).classList.remove("mouseOff");
      document.getElementById(idOff).classList.remove("mouseOn");
      document.getElementById(idOff).classList.remove("mouseOnDown");
      document.getElementById(idOff).classList.remove("mouseOffDown");
      document.getElementById(idOn).classList.remove("mouseOff");
      document.getElementById(idOn).classList.remove("mouseOn");
      document.getElementById(idOn).classList.remove("mouseOnDown");
      document.getElementById(idOn).classList.remove("mouseOffDown");
    },
    [idOff, idOn]
  );

  const mouseDown = useCallback(
    (e) => {
      if (e.target.id === idOn) {
        e.target.classList.add("mouseOnDown");
        document.getElementById(idOff).classList.add("mouseOnDown");
      } else if (e.target.id === idOff) {
        e.target.classList.add("mouseOffDown");
        document.getElementById(idOn).classList.add("mouseOffDown");
      }
    },
    [idOff, idOn]
  );

  const mouseUp = useCallback(
    (e) => {
      if (e.target.id === idOn) {
        e.target.classList.remove("mouseOnDown");
        document.getElementById(idOff).classList.remove("mouseOnDown");
      } else if (e.target.id === idOff) {
        e.target.classList.remove("mouseOffDown");
        document.getElementById(idOn).classList.remove("mouseOffDown");
      }
    },
    [idOff, idOn]
  );

  useEffect(() => {
    if (inState) {
      document.getElementById(idOn)?.classList.remove("off");
      document.getElementById(idOff)?.classList.remove("off");
      document.getElementById(idOff)?.classList.add("on");
      document.getElementById(idOn)?.classList.add("on");
    } else {
      document.getElementById(idOn)?.classList.add("off");
      document.getElementById(idOff)?.classList.add("off");
      document.getElementById(idOn)?.classList.remove("on");
      document.getElementById(idOff)?.classList.remove("on");
    }
  }, [inState, idOff, idOn]);

  return (
    <Toggle>
      <ToggleOff
        id={idOff}
        onMouseEnter={mouseOver}
        onMouseLeave={mouseOut}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        onClick={toggle}
        data-state={false}
      >
        <span>{t("off")}</span>
      </ToggleOff>
      <ToggleOn
        id={idOn}
        onMouseEnter={mouseOver}
        onMouseLeave={mouseOut}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        onClick={toggle}
        data-state={true}
      >
        <span>{t("on")}</span>
      </ToggleOn>
    </Toggle>
  );
};

const ToggleHalf = styled.div`
  padding: 0.5rem 0;
  margin: 0 auto;
  text-align: center;
  font-family: NetflixSansBold;
  font-size: 0.6rem;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  span {
    pointer-events: none;
  }
  float: left;
  :hover {
    cursor: pointer;
  }
`;

const ToggleOff = styled(ToggleHalf)`
  z-index: 10;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  &.off{
    border-color: ${netflixRed};
    color: white;
    ::after{
      width: 100%;
      left: 0;
      background-color: ${netflixRed};
    }
  }
  &.on {
    color: ${onGreen};
    border-color: ${onGreen};
    ::after{
      width: 100%;
      background-color: ${onGreen};
      left: calc(100% + 1px);
    }
  }
  
  ::after {
    content: "";
    position: absolute;
    left: 100%;
    height: 100%;
    top: 0;
    width: 0%;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: -1;
  }
  &.mouseOn{
    color: ${onGreen};
    border-color: ${onGreen};
    ::after{
      width: 100%;
      background-color: ${onGreen};
      left: calc(100% + 1px);
    }
  }
  &.mouseOff{
    ::after{
      width: 100%;
      left: 0;
      background-color: ${netflixRed};
    }
    border-color: ${netflixRed};
    color: white;
  }
  &.mouseOnDown {
    border-color: #2D5F02;
    color: #2D5F02;
  }

  &.mouseOffDown{
    border-color: #AA2B29;
    background-color: #AA2B29 !important;
    color: white;
    ::after{

    background-color: #AA2B29 !important;
    }
  }
 }
`;

const ToggleOn = styled(ToggleHalf)`
  color: white;
  z-index: 10;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  &.off {
    color: ${netflixRed};
    border-color: ${netflixRed};
  }
  &.on{
    color: white;
    border-color: ${onGreen};
  }
  &.mouseOff{
    color: ${netflixRed};
    border-color: ${netflixRed};
  }
  &.mouseOn{
    color: white;
    border-color: ${onGreen};
  }
  &.mouseOnDown{
    background-color: #2D5F02;
    border-color: #2D5F02;
    color: white;
  }
  &.mouseOffDown{
    background-color: white;
    border-color: #AA2B29;
    color: #AA2B29;
  }
 }

`;

const Toggle = styled.div`
  display: inline-flex;
  margin: 0.6rem 0 0;
  width: 100%;
  max-width: 300px;
`;
export { ToggleButton };
