import React, { useEffect, useState, useCallback, useContext } from "react";
import styled from "styled-components";
import { Button } from "../components/button";
import { Input } from "../components/input";
import AddItemsWidget from "../components/add_items_widget";
import { SelectInput } from "../components/select";
import { useHistory } from "react-router";
import api from "../utils/api";
import { mobile } from "../styles/media";
import {
  colorGrayLight,
  colorGrayDark,
  netflixBlack,
  netflixRed,
  offWhite,
  canvasTitleMaxLength,
} from "../utils/constants";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth";
import { generateSearchData } from "../utils/generate_search_data";

const SetupCanvas = () => {
  const [events, setEvents] = useState([]);
  const [userList, setUserList] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [canvasType, setCanvasType] = useState("");
  const [canvasTitle, setCanvasTitle] = useState("");
  const [canvasDescription, setCanvasDescription] = useState("");
  const [event, setEvent] = useState("");
  const [addUsersValue, setAddUsersValue] = useState("");

  const canvasTypeOptions = [
    { value: "", text: t("setup_page.default_type_option") },
    { value: "global_mural", text: t("global_mural") },
    { value: "sketch_battle", text: t("sketch_battle") },
  ];

  const fetchEvents = async () => {
    // Get Event Templates from API
    let tokenId = await user.getIdToken();
    let res = await fetch("/api/event", {
      method: "GET",
      headers: {
        "X-Access-Token": tokenId,
      },
    });
    let json = await res.json();

    setEvents(json.items);
    return json.items;
  };

  const getFieldData = () => {
    const title = canvasTitle;
    const { title: searchTitle, terms: searchTerms } =
      generateSearchData(title);

    const description = canvasDescription;
    const type = canvasType;

    let data = {
      title: title,
      search_title: searchTitle,
      search_terms: searchTerms,
      description: description,
      type: type,
      users: userList,
      event: event ? event.id : null,
    };
    return data;
  };

  const clearInputs = () => {
    setEvent("");
    setCanvasTitle("");
    setCanvasDescription("");
    setCanvasType("");
  };

  const eventSelectChangeCallback = useCallback(
    (e) => {
      // Event select callback
      if (e.target.value === "") {
        // If no event selected - clear fields
        clearInputs();
        setUserList([]);
        return;
      }
      // Parse event data and populate fields
      let template = events.find((evt) => evt.id === e.target.value);
      setEvent(template);
      setCanvasTitle(template.name ? template.name : "");
      setCanvasDescription(template.description ? template.description : "");
      setCanvasType(template.type ? template.type : "");
      setUserList(template.users ? template.users : []);
    },
    [events]
  );

  const createConfirm = () => {
    if (
      window.confirm(
        "Please confirm the creation of a new canvas.\n\nPlease Note: Creating a new Canvas will immediately close the current one and when the robots finish, will require a paper change.\n\nIf you are setting up a canvas for later, you can use the 'Save as Template' button.\n\nClick OK to create your new canvas now."
      )
    ) {
      createCallback();
    }
  };

  const createCallback = async () => {
    // Callback for creating an event
    let data = getFieldData();
    if (data.type === "") {
      // If no canvas type - post warning and  return
      toast.warn(`${t("setup_page.canvas_type_warning")}`);
      return;
    }
    data.active = true;
    try {
      // Post canvas data to API
      let tokenId = await user.getIdToken();
      const response = await fetch("/api/canvas", {
        method: "POST",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": tokenId,
        },
        body: JSON.stringify(data),
      });
      if (response.status !== 200) {
        // If bad status post error
        toast.error(`${t("setup_page.canvas_create_failed")}`);
      } else {
        // On successful creation - take the user to the Admin page
        toast.success(t("setup_page.new_canvas_created"));
        history.push("/admin");
      }
    } catch (err) {
      toast.error(`${t("setup_page.canvas_create_failed")}`);
      console.log("Error creating canvas", err);
    }
  };

  const saveTemplate = async () => {
    // Save Template Callback
    let data = getFieldData();
    if (data.type === "") {
      // If canvas type missing - post warning
      toast.warn(`${t("setup_page.canvas_type_warning")}`);
      return;
    }
    let event_data = {
      type: data.type,
      users: data.users,
      title: data.title,
      name: data.title,
      description: data.description,
    };
    const idToken = await user.getIdToken();
    if (data.event === null) {
      // If this is a new template - post to api

      const [err, response] = await api("/api/event", {
        method: "POST",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": idToken,
        },
        body: JSON.stringify(event_data),
      });
      if (err) {
        toast.error(`${t("setup_page.event_creation_error")}`);
        return;
      }
      toast.success(t("setup_page.event_creation_success"));
      let event_json = await response.json();
      data.event = event_json.id;
    } else {
      // If this event already exists - PUT update to api
      const [err, response] = await api(`/api/event/${data.event}`, {
        method: "PUT",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": idToken,
        },
        body: JSON.stringify(event_data),
      });
      if (err) {
        toast.error(`${t("setup_page.event_update_error")}`);
        return;
      }
      toast.success(t("setup_page.event_update_success"));
      let event_json = await response.json();
      data.event = event_json.id;
    }
    // Reload events from api
    const latestEvents = await fetchEvents();
    setEvent(latestEvents.find((evt) => evt.id === data.event));
    return data;
  };

  const onSubmitUsers = (val) => {
    if (!val) {
      toast.warn(`${t("add_user_list.invalid_email")}`);
      return false;
    }
    const emails = val.replaceAll(" ", "").split(",");
    const badEmails = [];
    const valids = emails.filter((e) => {
      if (!/^[^@]+@\w+(\.\w+)+\w$/.test(e)) {
        toast.warn(`${e} - ${t("add_user_list.invalid_email")}`);
        badEmails.push(e);
        return false;
      } else if (userList.find((existing) => existing === e)) {
        toast.warn(`${e} - ${t("add_user_list.duplicate")}`);
        badEmails.push(e);
        return false;
      } else {
        return true;
      }
    });
    setUserList((current) => current.concat(valids));
    setAddUsersValue(badEmails.join(", "));
  };

  useEffect(() => {
    const fetchData = async () => {
      const idToken = await user.getIdToken();
      let res = await fetch("/api/event", {
        method: "GET",
        headers: {
          "X-Access-Token": idToken,
        },
      });
      let json = await res.json();
      setEvents(json.items);
    };
    fetchData();
  }, [user]);

  return (
    <div>
      <Container>
        <InnerContainer>
          <Section>
            <InlineSelects>
              <FillCnr>
                <Label>{t("setup_page.canvas_type_label")}</Label>
                <FillSelect
                  id={"canvasTypeSelect"}
                  options={canvasTypeOptions}
                  onChange={(e) => setCanvasType(e.target.value)}
                  value={canvasType}
                />
              </FillCnr>
              <FillCnr>
                <Label>{t("setup_page.event_template_label")}</Label>
                <FillSelect
                  id={"eventSelect"}
                  options={events.map((event) => {
                    return { value: event.id, text: event.name };
                  })}
                  onChange={eventSelectChangeCallback}
                  value={event.id}
                  defaultOption={{
                    value: "",
                    text: t("setup_page.event_default_option"),
                  }}
                />
              </FillCnr>
            </InlineSelects>
          </Section>
          <Section>
            <Input
              type="text"
              id="canvasTitle"
              value={canvasTitle}
              onChange={(e) =>
                e.target.value.length <= canvasTitleMaxLength
                  ? setCanvasTitle(e.target.value)
                  : null
              }
              label={t("setup_page.title")}
              style={{ margin: "0 auto", textAlign: "left" }}
            />
            <TitleCharCount>
              {canvasTitle.length}/{canvasTitleMaxLength}
            </TitleCharCount>
          </Section>
          <Section>
            <Input
              type="textarea"
              id="canvasDescription"
              value={canvasDescription}
              onChange={(e) => setCanvasDescription(e.target.value)}
              label={t("setup_page.description")}
              style={{
                height: "200px",
                margin: "0.5rem auto",
                textAlign: "left",
              }}
            />
          </Section>
          <Label>{t("add_user_list.label")}</Label>
          <AddItemsWidget
            list={userList}
            value={addUsersValue}
            setValue={setAddUsersValue}
            onClickAdd={onSubmitUsers}
            onClickDelete={(user) =>
              setUserList((current) => current.filter((u) => u !== user))
            }
            placeholder={t("add_user_list.placeholder")}
          />
          <ButtonGroup>
            <CreateButton
              callback={createConfirm}
              bgColor={{ normal: "#707070", hover: netflixRed }}
              color={{ normal: "white", hover: "white" }}
            >
              {t("setup_page.create_button")}
            </CreateButton>
            <SaveTemplateButton
              callback={saveTemplate}
              bgColor={{ normal: colorGrayLight, hover: "#707070" }}
              color={{ normal: "white", hover: "white" }}
            >
              {t(
                event
                  ? "setup_page.update_template_button"
                  : "setup_page.save_template_button"
              )}
            </SaveTemplateButton>
          </ButtonGroup>
        </InnerContainer>
      </Container>
    </div>
  );
};

const Container = styled.div`
  text-align: center;
  background-color: ${offWhite};
  padding: 6rem;
  @media ${mobile} {
    padding-top: 4rem !important;
  }
  /*label {
    font-family: NetflixSansBold;
    font-size: 1.2rem;
    color: ${netflixBlack};
    text-transform: uppercase;
    margin: 1rem 0;
  }
  input,
  textarea {
    border: 1px solid #cccccc;
    border-radius: 0.43rem;
    font-family: NetflixSansLight;
    font-size: 0.9rem;
  }*/
  @media ${mobile} {
    padding: 2rem;
  }
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  text-align: left;
  max-width: 850px;
`;

const InlineSelects = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1.5rem 3.125rem;
`;

const FillCnr = styled.div`
  flex-grow: 1;
  min-width: 15rem;
`;

const FillSelect = styled(SelectInput)`
  flex-grow: 1;
`;

const ButtonGroup = styled.div`
  margin-top: 1.5rem;
`;

const CreateButton = styled(Button)`
  width: 10rem;
  border-color: #707070;
  :hover {
    border-color: ${netflixRed};
  }
`;

const SaveTemplateButton = styled(CreateButton)`
  border-color: ${colorGrayLight};
  :hover {
    border-color: #707070;
  }
  margin-left: 0.5rem;
`;

const Label = styled.span`
  display: block;
  width: 100%;
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  color: ${netflixBlack};
  text-transform: uppercase;
  margin: 0 0 1rem;
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`;

const TitleCharCount = styled.span`
  position: absolute;
  right: 1rem;
  bottom: 0.55rem;
  color: ${colorGrayDark};
`;

export { SetupCanvas };
