import { maxWidth } from "./constants";

function onResizeFull(fullWidth, height, canvas, initRef, padding = 48) {
  if (canvas === null) {
    return;
  }
  const ratio = height / fullWidth;
  if (document.fullscreenElement) {
    fullWidth = window.innerWidth;
  }
  const w =
    (window.innerWidth < fullWidth ? window.innerWidth : fullWidth) - padding;
  const h = ratio * w;
  let scale;
  if (initRef.current) {
    scale = w / canvas.getWidth();
  } else {
    scale = w / fullWidth;
    initRef.current = true;
  }
  const zoom = canvas.getZoom() * scale;
  canvas.setDimensions({ width: w, height: h });
  canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
  canvas.calcOffset();
}

function onResizeDraw(fullWidth, height, canvas, canvasEl, minZoom) {
  const w =
    document.documentElement.clientWidth < fullWidth
      ? document.documentElement.clientWidth
      : fullWidth;
  const h =
    document.documentElement.clientHeight < height
      ? document.documentElement.clientHeight
      : height;
  canvasEl.width = w;
  canvasEl.height = h;
  let scale = height / canvas.current.getHeight();
  let zoom = canvas.current.getZoom() * scale;
  canvas.current.setDimensions({ width: w, height: h });
  minZoom.current = h / height;
  if (zoom >= minZoom.current) {
    if (zoom > 20) {
      zoom = 20;
    }
    canvas.current.setZoom(zoom);
  } else {
    canvas.current.setZoom(minZoom.current);
  }
  if (w / canvas.current.getZoom() > maxWidth) {
    let coords = canvas.current.vptCoords;
    minZoom.current = w / maxWidth;
    //canvas.current.setZoom(minZoom.current);
    canvas.current.zoomToPoint(
      {
        x: (coords.tr.x - coords.tl.x) / 2,
        y: (coords.br.y - coords.bl.y) / 2,
      },
      minZoom.current
    );
  }
  // Make sure Viewport is limited to canvas size
  let vpt = canvas.current.viewportTransform;
  if (vpt[4] >= 0) {
    vpt[4] = 0;
  } else if (
    vpt[4] <
    canvas.current.getWidth() - fullWidth * canvas.current.getZoom()
  ) {
    vpt[4] = canvas.current.getWidth() - fullWidth * canvas.current.getZoom();
  }
  if (vpt[5] >= 0) {
    vpt[5] = 0;
  } else if (
    vpt[5] <
    canvas.current.getHeight() - height * canvas.current.getZoom()
  ) {
    vpt[5] = canvas.current.getHeight() - height * canvas.current.getZoom();
  }
  canvas.current.setViewportTransform(vpt);
  canvas.current.calcOffset();
  canvas.current.requestRenderAll();
}

export { onResizeFull, onResizeDraw };
