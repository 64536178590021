import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth";
import api from "../utils/api";
import ListItemsWidget from "./list_items_widget";

const ActiveAdmins = ({ admins, hydrateAdmins }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const deleteAdmin = async (a) => {
    const idToken = await user.getIdToken();
    const [err, response] = await api(`/api/admin/${a.id}`, {
      method: "DELETE",
      headers: {
        "X-Access-Token": idToken,
      },
    });
    if (err !== null) {
      toast.error(t(`admin_page.admin_panel.error_deleting_admin`));
    }
    if (response.status === 200) {
      toast.success(t(`admin_page.admin_panel.admin_deleted`));
      await hydrateAdmins();
    }
  };

  const notUser = (a) => {
    return a.email.toLowerCase() !== user.email.toLowerCase();
  };

  return (
    <ListItemsWidget
      items={admins
        .concat([])
        .sort((a, b) => {
          if (!notUser(a)) {
            return 1;
          } else if (!notUser(b)) {
            return -1;
          } else {
            return a.email < b.email ? 1 : -1;
          }
        })
        .reverse()}
      renderFn={(admin) => admin.email}
      deleteFn={deleteAdmin}
      checkDeletable={notUser}
    />
  );
};

export default ActiveAdmins;
